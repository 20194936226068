import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { GlowTextShadow } from "./styled-components/text.styled";
export const twinkleAnimation = keyframes`
    0%{
        opacity: 0%;
        /* transform: scale(0); */
        filter: blur(10px);
    }
    100%{
        opacity: 70%;
        /* transform: scale(1.35); */
        filter: blur(0px);
    }
`;

const StyledFadeElement = styled(GlowTextShadow)`
    margin-top: 1rem;
    justify-self: end;
    transform: translateY(1rem);
    font-size: 1rem;
    font-style: italic;
    letter-spacing: 0.15ch;
    animation-iteration-count: infinite;
    animation-name: ${twinkleAnimation};
    animation-direction: alternate;
    animation-duration: 4s;
`;

function FadeElement(props) {
    const { list } = props;
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const iterationCount = React.useRef(0);
    // useEffect(() => {

    //     const timeout = setInterval(() => {
    //         // updateText();
    //     }, 4000);
    //     return () => {
    //         clearTimeout(timeout);
    //     };
    // }, [currentTextIndex, list.length]);
    function updateText(event) {
        // console.log(event);
        iterationCount.current = iterationCount.current + 1;
        if (iterationCount.current % 2 === 0) {
            if (currentTextIndex + 1 >= list.length) {
                setCurrentTextIndex(0);
            } else {
                setCurrentTextIndex((prev) => prev + 1);
            }
        }
    }
    return (
        <StyledFadeElement onAnimationIteration={(event) => updateText(event)}>
            {"+ " + list[currentTextIndex]}
        </StyledFadeElement>
    );
}

export default FadeElement;
