import React, { useCallback, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { WithPseudoElement, addPseudoElement } from "./styled-components/pseudo.styled";
import OrbitRing from "./OrbitRing";
import styled, { keyframes, css } from "styled-components";
import { SkillArea, Bubble } from "./Bubble";
import { helpers } from "./helpers/helpers";
import StarParticles from "./StarParticles";
import { device } from "./styled-components/devices";
import ParallaxElement from "./3DElement.jsx/ParallaxElement";
import HelpButton from "./HelpButton";
import { CustomTooltip } from "./Tooltip";
import { OverflowText, Portal } from "./OverflowText";
import GalaxyCenter from "./GalaxyCenter";
const screenSizes = {
    mobileX: {
        circleSize: 60,
        innerCircleSize: 18,
        offsetAdd: 12,
    },
    tablet: {
        circleSize: 40,
        innerCircleSize: 12,
        offsetAdd: 10,
    },
    laptop: {
        circleSize: 35,
        innerCircleSize: 8,
        offsetAdd: 15,
    },
    laptopL: {
        circleSize: 35,
        innerCircleSize: 8,
        offsetAdd: 10,
    },
    desktop: {
        circleSize: 28,
        innerCircleSize: 6,
        offsetAdd: -2,
    },
};

const rotate = keyframes`
    0%{
        transform: translate(-50%, -50%) rotateZ(0deg) translateZ(-30px);
    }
    100%{
        transform: translate(-50%, -50%) rotateZ(360deg) translateZ(-30px);
    }
`;
const rotateInner = keyframes`
    0%{
        transform: translate(-50%, -50%) rotateZ(0deg) translateZ(-20px);
    }
    100%{
        transform: translate(-50%, -50%) rotateZ(360deg) translateZ(-20px);
    }
`;
/* ----------------------------------------------
 * Generated by Animista on 2022-12-26 18:20:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
const pulsateBack = keyframes`

  0% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.9);
  }
`;
const StyledOuterTitle = styled.div`
    z-index: var(--z-top);
    width: 25vw;
    color: hsl(257deg 100% 74%); // var(--glassy-lilac-vivid);
    filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};

    @media ${device.tablet} {
        width: 20vh;
    }
    &.outer-title {
        p {
            display: none;
            line-height: unset;
            transform: translateY(100%);
            /* font-size: 0.75rem; */
            letter-spacing: 0.15ch;
            @media ${device.mobileL} {
                display: flex;
                font-size: 1rem;
            }

            font-weight: 600;
        }
    }
`;
const StyledCategoryItem = styled.li`
    color: hsl(257deg 100% 74%); // var(--glassy-lilac-vivid);
    filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};
    font-weight: 700 !important;
    text-align: left;
    letter-spacing: 0.05ch;
    background-color: transparent;
    transition: background-color 200ms ease-out, color 200ms ease-out;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    white-space: nowrap;
    &:hover,
    &:focus {
        color: white;
        background-color: hsl(257deg 100% 74%);
    }
    ${(props) =>
        props.hoveredCategory === props.ourCategory &&
        css`
            color: white;
            background-color: hsl(257deg 100% 74%);
        `};
`;
const MobileNav = styled.ul`
    pointer-events: auto;
    position: absolute;
    bottom: 2rem;
    max-height: 20rem;
    width: 100%;
    margin: unset;
    margin-block: unset;
    padding: unset;
    z-index: var(--z-top);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    li {
        cursor: pointer;
        pointer-events: auto;
        list-style-type: none;
        z-index: var(--z-top);
    }
`;
const StyledBubbleSection = styled.section`
    /* background-color: var(--color-primary-bg-600); */
    background-color: transparent;
    display: grid;
    pointer-events: none;
    position: relative;
    width: 100%;
    height: 100%;
    bottom: 0%;
    grid-template-rows: 50% 50%;

    .click-indicator {
        display: ${(props) => (props.showHelp ? "block" : "none")};
        width: 3rem;
        height: 3rem;
        z-index: var(--z-top);
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            filter: invert();
            opacity: 80%;
            animation: ${pulsateBack} 1.5s ease-in-out infinite both;
        }
    }

    &:after,
    &:before {
        opacity: 60%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--z-low);
    }
    ${(addPseudoElement("before"), { circle: true })};
    &:before {
        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 21%,
            var(--color-primary-bg) 22%,
            rgba(0, 0, 0, 0) 30%
        );
        display: none;
        z-index: var(--z-high) !important;
    }
    .mobile-display {
        grid-row: 1/2;
        opacity: 100%;
        width: 100%;
        z-index: var(--z-highest);
        min-height: 15vh;
        max-height: 20vh;
        display: flex;
        align-items: center;
        justify-items: flex-start;
        span {
            /* text-align: left; */
            padding-top: 10vmax;
            em {
                color: var(--clr-accent-cornflower);
            }
        }
        color: white;
        padding: 2vmax;
        margin-top: 10vmax;
        line-height: 1.6;
        text-align: center;
        font-size: 2vmax;
        background-color: var(--color-primary-bg-700-alpha);
        opacity: ${(props) => (props.hoveredCategory !== "all" ? "100%" : "0%")};
        transition: opacity 200ms ease-in-out;
        @media ${device.laptop} {
            font-size: 1.25rem;
            padding: 4rem;
            padding-top: 6rem;
        }
    }

    .rotation-div {
        ${addPseudoElement("none", { centered: true, circle: true })};
        width: ${`${screenSizes.mobileX.circleSize + 50}vw`};
        height: ${`${screenSizes.mobileX.circleSize + 50}vw`};
        /* &:not(.inner) {
            display: none;
        } */
        @media ${device.tablet} {
            width: ${`${screenSizes.tablet.circleSize + 50}vw`};
            height: ${`${screenSizes.tablet.circleSize + 50}vw`};
            &:not(.inner) {
                display: block;
            }
        }
        @media ${device.laptop} {
            width: ${`${screenSizes.laptop.circleSize + 40}vw`};
            height: ${`${screenSizes.laptop.circleSize + 40}vw`};
        }

        @media ${device.laptopL} {
            width: ${`${screenSizes.laptopL.circleSize + 40}vw`};
            height: ${`${screenSizes.laptopL.circleSize + 40}vw`};
        }
        @media ${device.desktop}, (min-width: 1824px) {
            width: ${`${screenSizes.desktop.circleSize + 40}vw`};
            height: ${`${screenSizes.desktop.circleSize + 40}vw`};
        }

        aspect-ratio: 1/1;
        z-index: var(--z-lowest);
        /* background-color: #91abff; */
        background-image: radial-gradient(circle at 50%, #534ce4 30%, #5184eb 40%, #a51eff);
        /* mix-blend-mode: hard-light; */
        /* background-size: 50% 50%; */
        background-size: contain;
        /* background-image: url(/lotus-phases-cropped.webp), linear-gradient(-45deg, #2353ff, var(--glassy-lilac-bright)); */
        mask-image: url(/OrbitIllustration.webp);
        mask-size: 90% 90%;
        mask-repeat: no-repeat;
        mask-position: center;
        background-position: center;
        border: none;
        outline: none;
        /* border-radius: 50%; */
        /* clip-path: circle(50% at 50% 50%); */
        opacity: ${(props) => (props.hoveredCategory !== "all" ? "20%" : "50%")};
        transition: opacity 200ms ease-in-out();
        ${css`
            @media (prefers-reduced-motion: no-preference) {
                animation-duration: 108s;
                animation-name: ${rotate};
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        `};
        &.inner {
            mask-image: none;
            /* background-image: url(/OrbitIllustration-Inner.webp); */
            background-image: url(/GalaxySwirl.webp);
            background-size: 25% 25%;
            background-repeat: no-repeat;
            /* background-size: 85% 85%; */
            z-index: var(--z-lowest);
            opacity: 100%;
            @media (prefers-reduced-motion: no-preference) {
                animation-name: ${rotateInner};
            }
            /* filter: brightness(70%); */
            filter: hue-rotate(15deg);
            /* transform: translate(-50%, -50%) translateZ(-21px); */
        }
    }

    .solar-system__wrapper {
        transform-origin: center;
        pointer-events: auto;
        position: absolute;
        top: 50%;
        left: 50%;

        width: ${`${screenSizes.mobileX.circleSize}vw`};
        height: ${`${screenSizes.mobileX.circleSize}vw`};
        transform: translate(-50%, -50%);
        @media ${device.tablet}, (orientation: landscape) {
            /* right: unset; */
            /* left: 10%; */
            width: ${`${screenSizes.tablet.circleSize}vw`};
            height: ${`${screenSizes.tablet.circleSize}vw`};
        }
        @media ${device.laptop} {
            width: ${`${screenSizes.laptop.circleSize}vw`};
            height: ${`${screenSizes.laptop.circleSize}vw`};
        }

        @media ${device.laptopL} {
            width: ${`${screenSizes.laptopL.circleSize}vw`};
            height: ${`${screenSizes.laptopL.circleSize}vw`};
        }
        @media ${device.desktop}, (min-width: 1824px) {
            width: ${`${screenSizes.desktop.circleSize}vw`};
            height: ${`${screenSizes.desktop.circleSize}vw`};
        }
    }

    .solar-system__wrapper {
        z-index: var(--z-high);
    }

    @media ${device.tablet}, (orientation: landscape) {
        bottom: unset;
        height: 100%;
        top: 0%;
        left: 0%;
    }
    z-index: 1;

    /* z-index: 200; */
    /* transform: translate(-50%, -50%); */
`;

function GalaxySection(props) {
    const [clickedCategory, setClickedCategory] = useState("all");
    const [showHelp, setShowHelp] = useState(false);
    const [hoveredCategory, setHoveredCategory] = useState("all");
    const isLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isMobileS = useMediaQuery({ query: "(max-width: 425px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
    const isLargeLaptop = useMediaQuery({ query: "(min-width: 1440px)" });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

    const randomSizeOffset = [3.5, 5, 6, 5.5, 6]; // offset of outer rings from inner circle

    function setClicked(event, clicked) {
        const clickedElement = event.currentTarget;
        const category = clickedElement?.dataset.category;
        if (clicked) setClickedCategory(category);
        else setClickedCategory("all");
    }
    function setHovered(event, hovering = false) {
        const hoveredElement = event.currentTarget;
        const category = hoveredElement?.dataset.category;
        if (hovering) setHoveredCategory(category);
        else setHoveredCategory("all");
    }

    function generateDummyElements(index, array, value) {
        const newArray = [];
        for (let i = 0; i < Object.keys(array).length; i++) {
            if (i === index) {
                newArray[i] = value;
            } else {
                newArray[i] = " ";
            }
        }

        return newArray;
    }
    let iterator = 0;
    let innerIterator = 0;
    const categoryElements = Object.keys(props.categories).map((cat, index) => {
        const { img } = props.categories[cat];
        const skillCategoryValues = props.categories[cat];
        const dummyElements = generateDummyElements(index, props.categories, cat);
        let sizeObject = screenSizes.mobileX;
        let key = "mobileX";
        if (isMobile) {
            sizeObject = screenSizes.mobileX;
            key = "mobileX";
        } else if (isTabletOrMobile && !isLaptop) {
            sizeObject = screenSizes.tablet;
            key = "tablet";
        } else if (isLaptop && !isLargeLaptop) {
            sizeObject = screenSizes.laptop;
            key = "laptop";
        } else if (isLargeLaptop && !isBigScreen) {
            sizeObject = screenSizes.laptopL;
            key = "laptopL";
        } else if (isBigScreen) {
            sizeObject = screenSizes.desktop;
            key = "big screen";
        }
        let circleSize = sizeObject.circleSize;
        let itemSize = sizeObject.innerCircleSize;
        let extraOffset = isBigScreen ? 0 : 4;
        let offsetAdd = isPortrait ? sizeObject.offsetAdd : extraOffset;

        const zeroOffset = index === 0 ? 1 : 0;
        // const rotationValue = isMobile ? skillCategoryValues.rotationValue : index * 20;
        let offsetNumber = 360 / Object.keys(props.categories).length;

        function returnRotationValue() {
            let defaultRotation = (index + 1) * 15;
            let { rotationValue, rotationValuePortrait } = skillCategoryValues;
            if (rotationValue) {
                if (rotationValuePortrait) {
                    if (rotationValuePortrait > 0) {
                        return rotationValuePortrait;
                    } else {
                        return defaultRotation;
                    }
                } else {
                    return rotationValue;
                }
            }
            return defaultRotation;
        }
        let rotationValue = returnRotationValue();
        const hueRotateValue = 310 + (index + 1) * 20;

        const categoryValues = skillCategoryValues.names.map((el, i) => {
            const childDummyElements = generateDummyElements(i, skillCategoryValues.names, el);
            const childRotationValue = skillCategoryValues.childRotationValues
                ? skillCategoryValues.childRotationValues[el] || (i + 1) * 15
                : (i + 1) * 15; //rotationValue + i//i * 20
            const innerMoons = childDummyElements.map((innerEl, innerI) => {
                innerIterator += 1;
                return (
                    <OverflowText
                        cat={cat}
                        className="inner-moon"
                        key={innerI + "innerMoon"}
                        interactive={i === innerI ? true : false}
                        style={{ transform: `rotateZ(-${childRotationValue}deg)` }}
                        text={el}
                        hoveredCategory={hoveredCategory}
                        clickedCategory={clickedCategory}
                        startRotation={`-${i * 20}deg`}
                        endRotation={`-${i * 20 - 1}deg`}
                        hueRotate={hueRotateValue}
                    >
                        {[
                            <div>
                                {i === innerI ? (
                                    <>
                                        {/* <p>{innerEl}</p> */}
                                        <img src={`/symbols/Symbol (${innerIterator}).webp`} alt="" />
                                    </>
                                ) : (
                                    <img src={`/symbols/Symbol (${innerIterator}).webp`} alt="" />
                                )}
                                <div></div>
                            </div>,
                        ]}
                    </OverflowText>
                );
            });
            const zeroOffsetInner = i === 0 ? 1 : 0;
            return (
                <OrbitRing
                    className="inner-orbit-ring"
                    childClassName="moon inner-moon"
                    key={i + "innerSystem"}
                    itemSize={3}
                    circleSize={itemSize + (i + zeroOffsetInner) * (randomSizeOffset[i] + 3)}
                    unit="vw"
                    isInnerMoon={true}
                    index={i}
                    startRotation={`-${i * 20}deg`}
                    endRotation={`-${i * 20 - 1}deg`}
                    style={{ transform: `translate(-50%, -50%) rotateZ(${childRotationValue}deg)` }}
                    data-category={el}
                >
                    {innerMoons}
                </OrbitRing>
            );
        });
        const satellites = dummyElements.map((el, i) => {
            iterator += 1;
            return (
                <div key={i + "OuterMoon"} data-interactive={i === index ? true : false} data-category={el}>
                    {i === index ? (
                        <>
                            <StyledOuterTitle
                                className="outer-title"
                                style={{
                                    transform: `rotateZ(-${rotationValue}deg`,
                                }}
                                hueRotate={310 + (index + 1) * 20}
                            >
                                <p>{el}</p>
                            </StyledOuterTitle>
                            <div className="click-indicator">
                                {index === 0 && (
                                    <img className="" src="/icons/press-button.png" alt="button press prompt" />
                                )}
                            </div>
                            <ParallaxElement
                                background="/background-textures/galaxy4.jpg"
                                style={{ transform: `rotateZ(-${rotationValue}deg)` }}
                                rotationValue={-rotationValue}
                                hueRotate={310 + (index + 1) * 20}
                                parallax={true}
                                size={itemSize}
                            >
                                <p>{el}</p>
                                <img src={img} alt="" />
                                <div></div>
                            </ParallaxElement>
                            <div className="pulse-div"></div>
                            <div className="pulse-div"></div>
                            <div className="swirl-div"></div>
                        </>
                    ) : (
                        <img src={`/symbols/Symbol (${iterator}).webp`} alt="" />
                    )}
                    {i === index && categoryValues}
                </div>
            );
        });

        // offsetAdd = isMobile && index === 0 ? offsetAdd + 10 : offsetAdd;

        return (
            <OrbitRing
                childClassName="moon"
                category={cat}
                itemSize={itemSize}
                circleSize={circleSize + (index + zeroOffset) * (randomSizeOffset[index] + offsetAdd)}
                style={{ transform: `translate(-50%, -50%) rotateZ(${rotationValue}deg)` }}
                key={cat + "OrbitRing"}
                unit="vw"
                index={index}
                setHovered={setHovered}
                setClicked={setClicked}
                clicked={clickedCategory}
                hoveredCategory={hoveredCategory}
                data-category={cat}
            >
                {satellites}
            </OrbitRing>
        );
    });
    const categoryValues = props.categories[hoveredCategory];
    const categoryText = categoryValues?.text || " ";
    const navTitles = Object.keys(props.categories).map((el, index) => (
        <StyledCategoryItem
            hoveredCategory={hoveredCategory}
            ourCategory={el}
            key={encodeURI(el) + "Element"}
            data-category={el}
            tabIndex={-1}
            onFocus={(event) => setHovered(event, true)}
            onBlur={(event) => setHovered(event, false)}
            hueRotate={310 + (index + 1) * 20}
        >
            {el}
        </StyledCategoryItem>
    ));
    return (
        <StyledBubbleSection hoveredCategory={hoveredCategory} showHelp={showHelp}>
            {isMobileS && <MobileNav className="mobile-nav">{navTitles}</MobileNav>}
            <HelpButton
                setShowHelp={(boolean) => setShowHelp(boolean)}
                text="Click upon the planets to show or hide more information."
            ></HelpButton>
            <div className="solar-system__wrapper">{categoryElements}</div>
            <GalaxyCenter
                categoryValues={categoryValues}
                categoryText={categoryText}
                isMobile={isMobile}
                hoveredCategory={hoveredCategory}
            ></GalaxyCenter>

            {isMobile && (
                <p className="mobile-display">
                    <span>{categoryText}</span>
                </p>
            )}
            <div className="rotation-div"></div>
        </StyledBubbleSection>
    );
}

export default GalaxySection;
