import React from "react";
import styled from "styled-components";
import { device } from "./styled-components/devices";

const StyledShadowText = styled.h1`
    display: grid;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    font-size: clamp(1.25rem, 1.6rem + 1.9vw, 4rem);

    @media ${device.tablet} {
        /* color: #beb7ff; */
        /* font-size: min(7vmax, 100px); */

        filter: drop-shadow(2px 6px 2px hsla(240, 60%, 38%, 0.643));
    }
    span {
        grid-row: 1/2;
        grid-column: 1/2;
        height: fit-content;
        width: inherit;
        transform: inherit;
        top: inherit;
        left: inherit;
        font-family: inherit;
        font-size: inherit;
        &.main-text {
            color: hsl(240deg 100% 79%);
            background-blend-mode: color-dodge;
            /* mix-blend-mode: color; */
        }

        &.is-shadow {
            grid-row: 1/2;
            grid-column: 1/2;

            /* text-shadow: -1px 11px 8.896px #03001e54, 0px -2px 1px #a9b1ff; //3D-ish */
            /* text-shadow: 0 13.36px 8.896px #b6b8ff, 0 -2px 1px #9aa3ff; */
            /* text-shadow: 3px 3px 0px #b6b8ff, 9px 8px 0px rgba(21, 16, 41, 0.15); */
            z-index: -1;
        }
        &.img-text {
            background-size: cover, 100% auto, 100% 100%;
        }
    }
`;

function ShadowText({ text, classList = " " }) {
    return (
        <StyledShadowText>
            <span className={`title heavy main-text ${classList}`}>{text}</span>
            <span className="title heavy long-shadow is-shadow">{text}</span>
        </StyledShadowText>
    );
}

export default ShadowText;
