import React from "react";
import PropTypes from "prop-types";

function GradientMap(props) {
    return (
        <div>
            <svg>
                <filter
                    id="filter"
                    x="-10%"
                    y="-10%"
                    width="120%"
                    height="120%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0
1 0 0 0 0
1 0 0 0 0
0 0 0 1 0"
                        in="SourceGraphic"
                        result="colormatrix"
                    />
                    <feComponentTransfer in="colormatrix" result="componentTransfer">
                        <feFuncR type="table" tableValues="0.05 0.09 0.25 0.6" />
                        <feFuncG type="table" tableValues="0.05 0.13 0.27 0.58" />
                        <feFuncB type="table" tableValues="0.21 0.36 0.62 0.82" />
                        <feFuncA type="table" tableValues="0 1" />
                    </feComponentTransfer>
                    <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend" />
                </filter>
                <filter
                    id="filter-a"
                    x="-10%"
                    y="-10%"
                    width="120%"
                    height="120%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0
            1 0 0 0 0
            1 0 0 0 0
            0 0 0 1 0"
                        in="SourceGraphic"
                        result="colormatrix"
                    />
                    <feComponentTransfer in="colormatrix" result="componentTransfer">
                        <feFuncR type="table" tableValues="0.05 0.09 0.25 0.6" />
                        <feFuncG type="table" tableValues="0.05 0.13 0.27 0.58" />
                        <feFuncB type="table" tableValues="0.21 0.36 0.62 0.82" />
                        <feFuncA type="table" tableValues="0 1" />
                    </feComponentTransfer>
                    <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend" />
                </filter>

                <filter
                    id="filter-b"
                    x="-10%"
                    y="-10%"
                    width="120%"
                    height="120%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feColorMatrix
                        type="matrix"
                        values="0 1 0 0 0
            0 1 0 0 0
            0 1 0 0 0
            0 0 0 1 0"
                        in="SourceGraphic"
                        result="colormatrix"
                    />
                    <feComponentTransfer in="colormatrix" result="componentTransfer">
                        <feFuncR type="table" tableValues="0.04 0.59 1" />
                        <feFuncG type="table" tableValues="0 0.39 1" />
                        <feFuncB type="table" tableValues="0.52 0.97 0.89" />
                        <feFuncA type="table" tableValues="0 0.5" />
                    </feComponentTransfer>
                    <feBlend mode="normal" in="componentTransfer" in2="SourceGraphic" result="blend" />
                </filter>
            </svg>
        </div>
    );
}

GradientMap.propTypes = {
    id: PropTypes.string,
};

export default GradientMap;
