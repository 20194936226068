import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledLoader = styled.section`
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url(/LotusLogo.webp), linear-gradient(45deg, var(--color-space-gradient));
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: var(--z-top);
    backdrop-filter: blur(12px);
`;

function Loader() {
    //put image here, use effect to target window and load

    return <StyledLoader></StyledLoader>;
}

export default Loader;
