import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)`
    & .MuiTooltip-tooltip {
        /* background: var(--glassy-lilac-alpha-5) !important; */
        background-color: var(--glassy-purple) !important;
        backdrop-filter: blur(5px);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 8px;
        border: 1px solid var(--glassy-lilac-alpha-5);
        /* color: var(--color-primary-bg-700); */
    }
`;
const StyledPopover = styled(({ className, ...props }) => <Popover {...props} className={className} />)`
    .MuiPopover-paper {
        background-color: var(--glassly-lilac-alpha-5);
        color: white;
        padding: 0.5em 1em;
        backdrop-filter: blur(5px);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 8px;
        border: 1px solid var(--glassy-lilac-alpha-5);
    }
`;

export function CustomTooltip(props) {
    return (
        <StyledTooltip className="help-popover" placement={props.placement} title={props.title}>
            {props.children}
        </StyledTooltip>
    );
}
export function CustomPopover(props) {
    return (
        <StyledPopover
            className="popover"
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            id={props.id}
            anchorOrigin={{ ...props.anchorOrigin }}
            transformOrigin={{ ...props.transformOrigin }}
        >
            {props.children}
        </StyledPopover>
    );
}
