import React from "react";
import { device } from "../styled-components/devices";
import styled, { css, keyframes } from "styled-components";
import { StyledLens } from "../styled-components/bubbles-and-lenses.styled";
import { addPseudoElement } from "../styled-components/pseudo.styled";
import { useMediaQuery } from "react-responsive";
const pulse = keyframes`
20%{
    opacity: 0.2;
}
 100%{
    transform: scale(2.5);
    opacity: 0;
 }
`;

const StyledParallaxEl = styled(StyledLens)`
    position: absolute;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    overflow: visible;
    .outside-title {
        position: absolute;
        bottom: -2rem;
    }
    transform-style: preserve-3d;
    ${addPseudoElement("after")};
    &:after {
        /* background-blend-mode: lighten; */
    }

    .moon-dot {
        width: 3vmax;
        height: 3vmax;
        border-radius: 50%;
        background-color: hsl(257deg 100% 68%); // var(--glassy-lilac-vivid);
        filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};
        ${addPseudoElement("after", { circle: true, centered: true })}
        ${addPseudoElement("before", { circle: true, centered: true })}
        &:after {
            width: 3vmax;
            height: 3vmax;
            background-color: inherit;
            filter: ${(props) => `hue-rotate(${props.hueRotate}deg) blur(8px)`};
        }
        &:before {
            background-color: transparent;
            border: 2px solid hsl(257deg 100% 68%);
            opacity: 20%;
            filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};
            width: ${(props) => props.size + "vw" || "10vw"};
            height: ${(props) => props.size + "vw" || "10vw"};
            @media ${device.tablet}, (orientation: landscape) {
                width: ${(props) => props.size + "vw" || "7vw"};
                height: ${(props) => props.size + "vw" || "7vw"};
            }
        }
        &:hover {
            .moon-dot {
                &:before {
                    animation-name: ${pulse};
                    animation-duration: 300ms;
                }
            }
        }
    }
    .parallax-effect {
        pointer-events: auto;
        transform: ${(props) => (props.parallax ? "perspective(5000px)" : "perspective(0px)")};
        transform-style: ${(props) => (props.parallax ? "preserve-3d" : "flat")};
        ${(props) =>
            !props.parallax &&
            css`
                filter: brightness(100%);
            `};
        display: grid;
        place-content: center;

        width: ${(props) => props.size + "vw" || "10vw"};
        height: ${(props) => props.size + "vw" || "10vw"};
        @media ${device.tablet}, (orientation: landscape) {
            width: ${(props) => props.size + "vw" || "7vw"};
            height: ${(props) => props.size + "vw" || "7vw"};
        }
        /* width: 7rem; */
        /* height: 7rem; */
        position: relative;
        border-radius: 50%;
        background-size: 200%;
        .foreground,
        .background,
        .charm {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: unset;
        }
        .foreground {
            transform: translateZ(35px);
            font-weight: 500;
            display: grid;
            place-content: center;
            margin: unset;
            padding: unset;

            font-family: "Raleway", sans-serif;
            letter-spacing: 0.15ch;
            font-size: 2.25vmin; //clamp(0.5rem, 2vmin, 2vmin);
            @media ${device.tablet} {
                /* font-size: 1.75vmin; //clamp(0.5rem, 2vmin, 2vmin); */
            }
            /* color: var(--color-primary-text-light); */
            /* color: var(--color-primary-text-light); */
            color: var(--glassy-lilac-lighter);
            padding: 2vw;
            max-width: ${(props) => props.size + "vw"};
            display: none;
        }
        .background {
            transform: translateZ(-10px) scale(1.2);
            /* filter: hue-rotate(240deg); */
            filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};
            background-image: ${(props) => `url(${props.background})`};
            /* hue-rotate(320deg); */
            /* background-image: ${(props) => props.backgo}; */
            /* background-image: url(/background-textures/Starfield1.webp) !important; */
            /* background-size: 50%; */
            /* background-repeat: repeat; */
        }
        .charm {
            top: 50%;
            /* filter: hue-rotate(40deg); */
            filter: blur(1px);
            left: 50%;
            transform: translateX(-50%) translateY(-50%) scale(0.75) translateZ(15px);
            /* transform: translateZ(25px) scale(0.5); */
            width: 50%;
            height: auto;
            opacity: 60%;
        }
    }
    /* width: 100%; */
    /* height: 100%; */
`;
function ParallaxElement(props) {
    // const childComponents = props.children.map((el, index) => {
    //     let className;

    //     if (el.type === "p" || el.type === "h1") {
    //         className = "foreground";
    //     } else if (el.type === "img") {
    //         className = "charm";
    //     } else {
    //         className = "background";
    //         // style.backgroundImage = `url(${props.background}`;
    //     }
    //     return React.cloneElement(el, {
    //         className: className,
    //         // style: style,
    //         key: className + index,
    //     });
    // });

    return (
        <StyledParallaxEl
            data-interactive={props.interactive}
            style={props.style}
            hueRotate={props.hueRotate}
            background={props.background}
            size={props.size}
            parallax={props.parallax || true}
        >
            <div className="moon-dot"></div>

            {/* } }*/}
        </StyledParallaxEl>
    );
}

export default ParallaxElement;
