import React from "react";
import { ContentDiv } from "./LandingSection";
import styled, { keyframes } from "styled-components";
import { ParallaxGroup, ParallaxLayer } from "./ParallaxGroup";
import { device } from "./styled-components/devices";
import { addPseudoElement } from "./styled-components/pseudo.styled";
const panBackgroundImage = keyframes`
  0% {
    /* background-position: 0 center; */
    transform: translateX(0);
  }
  100% {
    /* background-position: -900px center; */
    transform: translateX(-540px);
  }
`;

const StyledBottomSection = styled(ParallaxGroup)`
    /* z-index: -4; */
    .star-jar {
        z-index: -5;
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
        ${addPseudoElement("after", { centered: true })};
        &:after {
        }
        ${addPseudoElement("before")};
        &:before {
            width: 3240px;
            height: 3240px;
            /* height: 100%; */
            background-repeat: repeat;
            background-image: url("/background-textures/stars-sun-and-moon-seamless-pattern-9216112.png");
            background-size: 540px;
            animation: 19s ${panBackgroundImage} linear infinite;
            mask-image: url("/masks/StarJar-Mask.webp");
            mask-size: 100%;
            mask-position: center;
            clip-path: inset 200px;
        }
    }
    .bg {
        opacity: ${(props) => (props.isVisible ? "100%" : "0%")};
        transition: opacity 100ms ease-in;
    }
    .silhouette {
        pointer-events: none;
        &.back,
        &.deep {
            /* display: none; */
        }
        &.flat {
            /* z-index: 00; */
        }

        img {
            pointer-events: none;
            object-fit: contain;
            width: 100%;
            height: auto;
        }
        @media ${device.tablet} {
            display: block;
            &.back {
                filter: blur(2px);
            }
            &.deep {
                filter: blur(4px);
            }
            img {
                pointer-events: none;
                object-fit: cover;
            }
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        height: 100%;
        padding: unset;
        padding-top: 5rem;

        .container {
            width: fit-content;
            height: fit-content;

            padding: clamp(1rem, 1rem + 2vw, 2rem);
            padding-block: ${(props) => (props.reverse ? "3rem" : "5rem")};
            @media ${device.tablet} {
                padding-block: ${(props) => (props.reverse ? "3rem" : "5rem")};
                /* padding: 1rem; */
            }
            background-color: hsla(275, 100%, 25%, 0.518);
            border-radius: 10px;
            /* background-color: var(--); */
            /* backdrop-filter: blur(12px); */
        }
    }
    .bg {
        background-color: hsla(275, 100%, 25%, 0.518);
    }
    min-height: var(--landing-section-height);
    /* background: transparent; */
    /* background: var(--color-primary-bg); */
    /* outline: 3px solid var(--color-primary-bg); */
    display: grid;
    place-content: center;
    padding-block: 4vh;
    justify-items: center;
    position: relative;

    h3 {
        font-size: 2rem;

        margin-bottom: 2.5rem;
        /* margin-bottom: 1rem; */
        align-self: ${(props) => (props.reverse && props.reverse === true ? "flex-end" : "auto")};
        text-align: ${(props) => (props.reverse && props.reverse === true ? "right" : "left")};
        /* align-self: flex-end; */
        letter-spacing: 0.35rem;
        text-transform: uppercase;
        color: var(--color-primary-text);
        @media ${device.tablet} {
            font-size: 2.5rem;
            align-self: auto;
            text-align: left;
        }
    }
    p {
        /* font-size: 1rem; */
        font-weight: 300;
        /* line-height: 1.6; */
        @media ${device.tablet} {
            font-size: 1.25rem;
            min-width: 35ch;
            max-width: 65ch;
            text-align: left;
            line-height: 1.8;
            &:not(+ p) {
                margin-top: 3rem;
            }
        }
        text-align: ${(props) => (props.reverse && props.reverse === true ? "right" : "left")};
    }
`;
const BottomSection = (props) => {
    return (
        <StyledBottomSection className="parallax__group" isVisible={props.isVisible}>
            <ParallaxLayer layer="base" className="silhouette flat">
                <img src="/CloudsFlipped.webp" alt="" loading="lazy" />
            </ParallaxLayer>
            {/* <ParallaxLayer layer="base2" className="silhouette back">
                <img src="/Dangles.webp" alt="" loading="lazy" />
            </ParallaxLayer> */}
            {/* <ParallaxLayer layer="base3" className="silhouette deep">
                <img src="/Dangles-BG.webp" alt="" loading="lazy" />
            </ParallaxLayer> */}
            {/* Star Jar */}
            {/* <ParallaxLayer layer="base" className="base star-jar">
                <img src="/StarJar.webp" alt="" loading="lazy" />
            </ParallaxLayer> */}
            <ParallaxLayer layer="base" style={{ zIndex: 10 }}>
                <ContentDiv className="content">
                    <div className="container">
                        <h3 className="subtitle">Education</h3>
                        <p>
                            Pursuing my lifelong passion in writing, I attended <em>Emory University</em> where I
                            majored in <em>Creative Writing</em>. There I received a <em>Bachelors of the Arts</em>, and
                            graduated <em>Summa Cum Laude</em>, before my desire to <u>expand my creative skill set</u>{" "}
                            beyond the written word pushed me toward the world of
                            <u> software development.</u>
                        </p>
                        <p>
                            After a few years spent self-teaching and developing personal projects, I continued
                            my education at <em>Midlands Technical College</em>, seeking to build a stronger{" "}
                            <u>technical foundation</u>. In August of 2023, I received an <em>Associates of Science in Computer Technology - Web Development</em>, 
                            graduating <em>Summa Cum Laude</em> for maintaining a perfect GPA.
                        </p>
                    </div>
                </ContentDiv>
            </ParallaxLayer>
            {/* <ParallaxLayer layer="deepest" className="bg"></ParallaxLayer> */}
        </StyledBottomSection>
    );
};

export default BottomSection;
