import React, { useState } from "react";
import styled from "styled-components";
import { device } from "./styled-components/devices";
import { addPseudoElement } from "./styled-components/pseudo.styled";
import Tilt from "react-parallax-tilt";
import ShadowText from "./ShadowText";

const screenSizes = {
    mobile: {
        circleSize: 70,
        innerCircleSize: 18,
        offsetAdd: 15,
    },
    mobileX: {
        circleSize: 60,
        innerCircleSize: 18,
        offsetAdd: 15,
    },
    tablet: {
        circleSize: 40,
        innerCircleSize: 12,
        offsetAdd: 10,
    },
    laptop: {
        circleSize: 35,
        innerCircleSize: 8,
        offsetAdd: 15,
    },
    laptopL: {
        circleSize: 35,
        innerCircleSize: 8,
        offsetAdd: 10,
    },
    desktop: {
        circleSize: 28,
        innerCircleSize: 6,
        offsetAdd: -2,
    },
};
const StyledLens = styled.section`
    position: absolute;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    overflow: visible;

    transform-style: preserve-3d;
`;
const StyledGalaxyCenter = styled.section`
    transform-origin: center;
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;

    width: ${`${screenSizes.mobile.circleSize}vw`};
    height: ${`${screenSizes.mobile.circleSize}vw`};
    transform: translate(-50%, -50%);
    @media ${device.mobileL} {
        width: ${`${screenSizes.mobileX.circleSize}vw`};
        height: ${`${screenSizes.mobileX.circleSize}vw`};
    }
    @media ${device.tablet}, (orientation: landscape) {
        /* right: unset; */
        /* left: 10%; */
        width: ${`${screenSizes.tablet.circleSize}vw`};
        height: ${`${screenSizes.tablet.circleSize}vw`};
    }
    @media ${device.laptop} {
        width: ${`${screenSizes.laptop.circleSize}vw`};
        height: ${`${screenSizes.laptop.circleSize}vw`};
    }

    @media ${device.laptopL} {
        width: ${`${screenSizes.laptopL.circleSize}vw`};
        height: ${`${screenSizes.laptopL.circleSize}vw`};
    }
    @media ${device.desktop}, (min-width: 1824px) {
        width: ${`${screenSizes.desktop.circleSize}vw`};
        height: ${`${screenSizes.desktop.circleSize}vw`};
    }
    perspective: 1000px;
    padding: unset;
    display: grid;
    place-content: center;
    background: radial-gradient(circle at 50%, transparent 60%, hsla(252, 90%, 56%, 0.214));
    background-size: 100%;
    border: 1px solid white;
    border-color: var(--color-primary-text);
    color: var(--color-secondary-text);

    font-weight: bold;
    border-radius: 50%;
    display: grid;
    place-content: center;
    transition: opacity 500ms ease-in-out;
    overflow: hidden;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(0.8);
    z-index: var(--z-highest);
    @media ${device.laptop} and (orientation: portrait) {
        transform: translate(-50%, -50%) scale(1);
    }
    ${addPseudoElement("after")};
    &:after {
        /* opacity: ${(props) => (props.hoveredCategory !== "all" ? "60%" : "0%")}; */
        content: "";
        border-radius: 50%;
        background-size: cover;
        background-position: center;

        background-image: url(/background-textures/sparkling-swirl-blur.webp);
        mask-image: radial-gradient(
            circle at 50%,
            transparent 15%,
            transparent 16%,
            #0000004a 17%,
            black 30%,
            black 60%,
            #00000081 70%,
            transparent
        );
        background-size: cover;

        mask-size: cover;
        mask-repeat: no-repeat;
        mask-position: center;
        box-shadow: 0px 0px 6px 4px hsla(245, 61%, 20%, 0.96) inset;
        border: 2px solid var(--color-primary-text);
        transition: opacity 400ms ease-in-out, transform 00ms ease-out;
        z-index: var(--z-low);
        opacity: 100%;

        transform: ${(props) => (props.hoveredCategory !== "all" ? "translateZ(-25px)" : "translateZ(-20px)")};
    }
    &:before {
        backdrop-filter: blur(4px);
        /* display: none; */
        opacity: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-size: 90% auto;
        background-position: center;
        border-radius: 50%;
        background-color: transparent;
        z-index: var(--z-low);
        /* opacity: 20%; */
        transform: ${(props) => (props.hoveredCategory !== "all" ? "translateZ(-35px)" : "translateZ(-25px)")};
        transition: transform 300ms ease-out;
    }

    p,
    h1 {
        opacity: ${(props) => (props.hoveredCategory !== "all" ? "100%" : "0%")};
        grid-row: 1/1;
        grid-column: 1/1;
        display: grid;
        grid-auto-columns: 100%;
        place-content: center;
        /* overflow: hidden; */
        z-index: var(--z-highest);
        padding: unset;
        margin: unset;
        aspect-ratio: 1/1;
        border-radius: 50%;
        transition: opacity 400ms ease-in-out;

        /* transform: translate(-50%, -50%); */
        width: ${`${screenSizes.mobileX.circleSize}vw`};
        height: ${`${screenSizes.mobileX.circleSize}vw`};

        @media ${device.tablet} {
            width: ${`${screenSizes.tablet.circleSize}vw`};
            height: ${`${screenSizes.tablet.circleSize}vw`};
        }
        @media ${device.laptop} {
            width: ${`${screenSizes.laptop.circleSize}vw`};
            height: ${`${screenSizes.laptop.circleSize}vw`};
        }
        @media ${device.laptopL} {
            width: ${`${screenSizes.laptopL.circleSize}vw`};
            height: ${`${screenSizes.laptopL.circleSize}vw`};
        }
        @media ${device.desktop}, (min-width: 1824px) {
            width: ${`${screenSizes.desktop.circleSize}vw`};
            height: ${`${screenSizes.desktop.circleSize}vw`};
        }
        span:not(.main-text):not(.is-shadow) {
            color: white;
            line-height: 1.5;
            top: 0;
            left: 0;
            width: 100%;
            backdrop-filter: blur(12px);
            padding: 1rem;
            text-align: center;
            background-image: linear-gradient(45deg, var(--color-glassy-purple), var(--color-primary-bg-700-alpha));
            transform: ${(props) => (props.hoveredCategory !== "all" ? "translateZ(-5px)" : "translateZ(-10px)")};
            transition: transform 400ms ease-in-out;
            em {
                color: #83ffff;
                font-weight: 500;
                background: linear-gradient(45deg, #83ffff, #b29dff);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    p {
        height: fit-content;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: max(1rem, 1.25vmax);
        /* display: block; */
        em {
            display: inline-flex !important;
        }
    }
    h1.title {
        color: rgb(175, 158, 255);
        --color-1: rgb(175, 158, 255);
        --color-2: rgb(158, 177, 255);
        display: grid;

        font-family: "Cinzel Decorative", cursive;
        font-size: 2rem;
        font-size: clamp(2rem, 1.4285714285714284rem + 2.857142857142857vw, 5rem);
        opacity: ${(props) => (props.hoveredCategory === "all" ? "100%" : "0%")};
        &.gradient {
            background: linear-gradient(45deg, var(--color-1), var(--color-2));

            background-size: contain !important;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    img {
        ${addPseudoElement("none", { centered: true, circle: true })};
    }
    .glow-ring {
        ${addPseudoElement("none", { centered: true, circle: true })};
        width: 100%;
        aspect-ratio: 1/1;
        padding: unset;
        /* object-fit: contain; */
        opacity: 100%;
        z-index: var(--z-highest);
        --purple: #6660ff;
        --mint: #84c3ff;
        border: 4px solid var(--purple); //var(--glassy-lilac-alpha-3);
        mix-blend-mode: color-dodge;
        filter: blur(6px);
    }
    img.overlay {
        position: absolute;
        object-fit: cover;
        ${addPseudoElement("none", { centered: true, circle: true })};
        width: 30%;
        height: 30%;
        border-radius: 50%;
        z-index: var(--z-highest);
    }
    img.shine {
        ${addPseudoElement("none", { centered: true, circle: true })};
        mix-blend-mode: lighten;
        opacity: 50%;
        transform: "translate(-50%, -50%) translateZ(-25px)";
        padding: 2px;
    }
    img.galaxy-center {
        display: none;
        ${addPseudoElement("none", { centered: true, circle: true })};
        width: 70%;
        height: 70%;
        top: 65%;
        left: 65%;
        transform: translate(-50%, -50%) rotateZ(30deg) translateZ(0px);
    }
    img.black-hole {
        display: none;
        ${addPseudoElement("none", { centered: true, circle: true })};
        width: 100%;
        height: 100%;
        padding: unset;
        object-fit: contain;
        filter: drop-shadow(0px 5px 2px #0e0e35) brightness(1%);
        opacity: 67%;

        z-index: var(--z-high);
    }

    img.galaxy-glow {
        width: 35%;
        height: 35%;
        z-index: var(--z-low);
        opacity: 30%;
        transform: ${(props) =>
            props.hoveredCategory !== "all"
                ? "translate(-50%, -50%) translateZ(-30px)"
                : "translate(-50%, -50%) translateZ(-25px)"};
    }
    img.vortex {
        width: 40%;
        height: 40%;
        z-index: var(--z-lowest);
        opacity: 30%;
        /* filter: hue-rotate(100deg); */
        filter: blur(2px);
        transform: ${(props) =>
            props.hoveredCategory !== "all"
                ? "translate(-50%, -50%) translateZ(-30px)"
                : "translate(-50%, -50%) translateZ(-25px)"};
    }
`;

function GalaxyCenter({ hoveredCategory, categoryValues, categoryText, isMobile }) {
    return (
        <StyledGalaxyCenter className="text-bubble-bg" hoveredCategory={hoveredCategory}>
            <Tilt
                className="parallax-effect"
                tiltMaxAngleX={15}
                tiltMaxAngleY={15}
                perspective={1000}
                scale={1.1}
                transitionSpeed={2000}
                gyroscope={true}
            >
                <StyledLens>
                    <img className="overlay" src="/water-lily-star-moon-cropped.webp" alt="" />
                </StyledLens>
            </Tilt>
            <img className="black-hole" src="/celestial-symbol-overlay.webp" alt="decorative illustration of swirl" />
            <img className="shine" src="/FX/GlassOverlay.webp" alt="shining glass overlay" />
            <div className="glow-ring"></div>
            <p>
                <span>{isMobile ? categoryValues?.names.join(", ") : categoryText}</span>
            </p>
            <h1 className="title heavy gradient">My Skills</h1>
            {/* <ShadowText text="My Skills"></ShadowText> */}
            <div className="rotation-div inner"></div>
        </StyledGalaxyCenter>
    );
}

export default GalaxyCenter;
