import React, { useState } from "react";
import PreviewIcon from "@mui/icons-material/Preview";
import GitHubIcon from "@mui/icons-material/GitHub";
import LaunchIcon from "@mui/icons-material/Launch";
import styled, { css, keyframes } from "styled-components";
import { device } from "./styled-components/devices";
import { LensButton, BlurButton, ImageButton, CustomIconButton } from "./styled-components/Buttons.Styled";
import { addPseudoElement } from "./styled-components/pseudo.styled";
import { CustomTooltip } from "./Tooltip";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ImageVideoDisplay from "./ImageVideoDisplay";
import { StyledLink } from "./styled-components/links.styled";

const SquarePopoverSection = styled.section`
    aspect-ratio: 1/1;
    max-height: 100% !important;
    height: 100%;
    width: 100%;
    pointer-events: auto;
    color: white;
    border: 1px solid var(--color-primary-text);
    z-index: 900;
    backdrop-filter: blur(14px);
    box-shadow: 0px 0px 5px #252544;

    z-index: var(--z-highest);
    &:after,
    &:before {
        border-radius: inherit;
        overflow: hidden;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        z-index: -10;
    }
    img,
    video {
        max-width: 100%;
        position: absolute;
    }
    @media (orientation: portrait) {
        width: ${(props) => (props.active ? "100%" : "unset")};
    }
    @media (orientation: landscape) {
        @media ${device.tablet} {
            width: unset;
        }
    }
`;

const LensImage = styled(ImageButton)`
    ${props => props.isActive && "transform: scale(1.05)"};
`

const StyledContentSection = styled(SquarePopoverSection)`
    display: grid;
    grid-template-rows: 15% 75% 10%;
    @media ${device.mobileX} {
        grid-template-rows: 10% 80% 10%;
    }
    grid-template-columns: 100%;
    align-items: start;
    justify-content: start;

    position: relative;
    overflow: visible;
    aspect-ratio: 1/1;

    opacity: 100%;
    transition: transform 200ms ease-in;
    background-color: var(--color-primary-bg-700-alpha);
    z-index: var(--z-highest);
    border-radius: 18px;
    transform: ${(props) => (!props.active ? "translate(150%, 50%)" : "translate(0%)")};
    transition-delay: ${(props) => (props.active ? "400ms" : "0ms")};
    backdrop-filter: unset;

    pointer-events: ${(props) => (props.active ? "auto" : "none")};
    .link-section {
        border-top: 1px solid var(--color-primary-text);
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to right, transparent, var(--color-primary-text), transparent) 1;
        z-index: 1000;
        grid-row: 3/4;
        height: 100%;

        box-shadow: 0px -12px 10px hsla(249, 40%, 10%, 0.25);
        ul {
            height: 100%;
            display: flex;
            margin: unset;
            padding-left: unset;
            padding-block: 0.25rem;
            padding-inline: 1rem;
            justify-content: flex-end;
            gap: 0.5rem;
            align-items: center;
            li {
                /* padding: 0.5rem; */
                padding: unset;
                height: 95%;
                aspect-ratio: 1/1;
                list-style-type: none;
                border: unset;

                a {
                    border-radius: 50%;
                    color: var(--glassy-lilac-bright);
                    &:hover,
                    &:focus {
                        color: black; //var(--glassy-lilac-vivid);
                        background-color: unset;
                        border: unset;
                    }
                    @media ${device.mobileL} {
                        background-color: var(--glassy-lilac-bright);
                        color: var(--color-primary-bg-700);
                        &:hover,
                        &:focus {
                            border: unset;
                            background-color: var(--glassy-lilac-vivid);
                        }
                    }

                    padding: unset;
                    height: 95%;
                    aspect-ratio: 1/1;
                    justify-content: center;
                    align-items: center;
                    width: inherit;
                    height: inherit;
                    aspect-ratio: 1/1;
                    font-size: 1.25rem;
                }
            }
        }
    }
    .text-section {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        grid-row: 2/3;
        grid-column: 1/2;
        justify-self: center;
        /* align-self: center; */
        align-self: start;
        height: 100%;
        max-height: 100%;
        padding-inline: 5%;
        /* padding-block: 5%; */

        text-align: left;
        line-height: 1.6;

        z-index: var(--z-high);
        overflow-y: scroll;
        p {
            font-size: 1rem;
        }
        h3 {
            font-size: 2vmax;
            text-align: center;
            text-transform: capitalize;
            letter-spacing: initial;
        }

        a {
            color: #9f76ca;
            font-weight: 500;
            display: inline;
        }

        @media ${device.tablet} {
            padding-inline: 2rem;
        }

        @media ${device.laptop} {
            padding-inline: 10%;
        }
        @media ${device.laptop}, (orientation: landscape) {
            height: 100%;
            max-height: 100%;
        }
    }

    .tab-section {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: start;
        grid-row: 1/2;
        height: 100%;
        grid-column: 1/2;
        pointer-events: none;
        z-index: var(--z-highest);
        overflow-x: scroll;

        gap: 0.15rem;
        box-shadow: 0px 12px 10px hsla(249, 40%, 10%, 0.25);
        @media ${device.laptop} {
            gap: 0.5rem;
        }

        border-bottom: 1px solid var(--color-primary-text);
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to right, transparent, var(--color-primary-text), transparent) 1;
        .tab {
            font-size: 0.75rem;
            @media ${device.mobileL} {
                font-size: 1.05em;
            }
            padding-block: 0.75em;
            pointer-events: auto;
            display: grid;
            place-content: center;
            height: 100%;
            background-color: unset;
            backdrop-filter: unset;
            border: unset;
            width: 6rem;
            @media ${device.tablet} {
                width: 8rem;
            }

            &[data-index="${(props) => props.currentTab}"] {
                color: var(--color-primary-text);
            }
        }
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: unset;
        opacity: 100%;
        z-index: var(--z-low);
        content: "";
        backdrop-filter: blur(12px);
    }

    @media (orientation: landscape) {
        grid-row: 1/2;
        grid-column: 2/3;
        height: unset;
        @media ${device.tablet} {
            border-radius: 18px;
        }
        @media ${device.laptop} {
            transform: ${(props) =>
                !props.active ? "scale(0.85) translate(100%, 200%)" : "scale(0.85) translate(-5%, 0%)"};
        }
    }

    @media (orientation: portrait) {
        grid-row: 2/3;
        grid-column: 1/2;
        border-top-left-radius: unset;
        border-top-right-radius: unset;

        height: 100%;
    }
`;
const StyledDisplaySection = styled(SquarePopoverSection)`
        backdrop-filter: unset;
        pointer-events: auto;
        transform-origin: top left;
        transform-origin: center;
        transition: transform 200ms ease-out, opacity 200ms ease-out;
        transition-delay: ${(props) => (props.active ? "400ms" : "0ms")};
        perspective: 5000px;
        backdrop-filter: unset;
        align-self: start;

        opacity: ${(props) => (!props.active ? "0%" : "100%")};
        pointer-events: none;
        transform: ${(props) => (!props.active ? "scale(0.65) translate(-20%, 40%)" : "scale(1) translate(0%)")};
        border-radius: 18px;

        z-index: var(--z-high);

        aspect-ratio: ${(props) => (props.active ? "unset" : "1/1")};

        @media (orientation: landscape) {
            justify-self: end;
            grid-row: 1/2;
            grid-column: 1/2;
            @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
                transform: ${(props) =>
                    !props.active ? "scale(0.65) translate(-40%, 40%)" : "scale(1) translate(0%)"};
            }
            width: ${(props) => !props.active && "unset"};

            @media ${device.tablet} {
                transform: ${(props) =>
                    !props.active ? "scale(0.85) translate(-45%, 30%)" : "scale(1) translate(0%, -5%)"};
                border-radius: 18px;
                aspect-ratio: ${(props) => (props.active ? "1/1" : "1/1")};
                .overflow-hidden__wrapper {
                    border-radius: 18px;
                }
            }
            @media ${device.laptop} {
                transform: ${(props) =>
                    !props.active ? "scale(0.65) translate(-10%, 50%)" : "scale(0.85) translate(20%, -10%)"};
            }
            @media (min-width: 1100px) {
                transform: ${(props) =>
                    !props.active ? "scale(0.65) translate(-20%, 50%)" : "scale(0.85) translate(20%, -10%)"};
            }
            @media (min-width: 1366px) {
                transform: ${(props) =>
                    !props.active ? "scale(0.65) translate(-10%, 50%)" : "scale(0.85) translate(20%, -10%)"};
            }
        }
        @media (orientation: portrait) {
            grid-row: 1/2;
            grid-column: 1/2;
            height: 100%;
            border-bottom-right-radius: unset;
            border-bottom-left-radius: unset;

            justify-self: center;
            transform: ${(props) => (!props.active ? "translate(0%, 170%)" : "translate(0%)")};
            aspect-ratio: ${(props) => (!props.active ? "1/1" : "unset")};
            button {
                display: ${(props) => (!props.active ? "none" : "flex")};
            }
            @media ${device.mobileX} {
                width: ${(props) => !props.active && "unset"};
                grid-row: ${(props) => (!props.active ? "2/3" : "1/2")};
                transform: ${(props) => (!props.active ? "translate(0%, 50%)" : "translate(0%)")};
            }
        }

        .overflow-hidden__wrapper {
            display: grid;
            grid-template-rows: 10% 70% 20%;
            grid-template-columns: 10% 90%;
            grid-auto-columns: 0;

            position: relative;
            border-radius: inherit;
            width: 100%;
            height: 100%;

            overflow: hidden;
            pointer-events: ${(props) => (props.active ? "auto" : "none")};
            border-radius: inherit;


            @media ${device.laptop} {
                grid-template-columns: 100%;
                grid-template-rows: 80% 10% 10%;
            }

            ${addPseudoElement("after")};
            ${addPseudoElement("before")};
            &:before, &:after {
                /* display: none; */
                z-index: 1;
                background-image: linear-gradient(
                    to bottom,
                    var(--color-primary-bg-700) 5%,
                    transparent 20%,
                    transparent 50%,
                    var(--color-primary-bg-700-alpha-7) 75%,
                    var(--color-primary-bg-700) 81%,
                    var(--color-primary-bg) 100%
                );
                opacity: 90%;
                background-size: 100% 100%;
                pointer-events: none;
                border-radius: inherit;
            }
            &:after{
                 background-image: linear-gradient(
                    to right,
                    var(--color-primary-bg-700) 5%,
                    transparent 20%,
                    transparent 50%,
                    var(--color-primary-bg-700-alpha-7) 75%,
                    var(--color-primary-bg-700) 81%,
                    var(--color-primary-bg) 100%
                );
                ${props => props.objectFit === "contain" && "display: none"};
            }
            h1{

                font-family: "Montserrat", sans-serif;
            }
        }





        h1,
        button.gallery-button,
        .back-button,
        .caption {
            position: static;
            margin: unset;
            @media ${device.laptop} {
                padding-block: 1rem;
            }
            width: 100%;
            z-index: var(--z-highest);
            color: var(--color-primary-text);
        }
        .caption {
            height: fit-content;
            padding-block: 0.75rem;
            padding-inline: 5%;

            text-align: left;
            color: var(--color-secondary-text);
            font-size: 1rem;

            grid-row: 2/3;
            grid-column: 1/3;

            @media ${device.laptop} {
                font-size: 1.15rem;
                grid-column: 1/2;
                grid-row: 2/3;
            }
            cursor: auto;
            background-image: linear-gradient(to right, transparent 2%,
                var(--color-primary-bg-700-alpha) 5%,
                var(--color-primary-bg-700-alpha) 90%,
                transparent);

            opacity: 20%;
            transition: opacity 200ms ease-in-out;
            &:hover{
                opacity: 100%;
            }


        }
        button.gallery-button,
        button.back-button {
            justify-self: end;
            align-self: end;
            grid-row: 1/3;
            grid-column: 1/2;
            left: unset;
            width: fit-content;
            height: fit-content;
            padding: 2vmax;
            z-index: 2;
            color: var(--color-secondary-text);
            opacity: 100%;
            transition: color 200ms ease-in-out;
            &:hover {
                color: var(--glassy-lilac-bright);
                transition: color 200ms ease-in-out;
            }
        }
        button.back-button {
            grid-column: 2/3;
            justify-self: end;
            align-self: start;
        }
        button.gallery-button{
            grid-row: 3/4;
            justify-self: start;

            z-index: var(--z-highest);
        }

        h1{
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            justify-self: center;
            grid-column: 1/3;
            grid-row: 3/4;
            height: fit-content;
    font-family: "Montserrat", sans-serif;
            font-weight: 200;
            font-size: 1.5rem;
            @media ${device.mobileL}{
                font-size: 2rem;
            }
            white-space: wrap;
            height: 100%;
            border-bottom: 1px solid var(--color-primary-text);
            border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to right, transparent, var(--color-primary-text), transparent) 1;
            @media ${device.laptop} {
                display: none;
            }
            @media ${device.laptop} {
                font-size: 2.5rem;
            }
        }
    }
    .lens-div__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1vmin;

        width: 100%;
        grid-column: 1/2;
        grid-row: 1/3;

        @media ${device.laptop} {
            grid-row: 3/4;
            flex-direction: row;
        }

        pointer-events: none;

        z-index: var(--z-highest);
    }
    .lens-image {
        position: relative;
        display: none;
        @media ${device.tablet} {
            display: initial;
        }
        cursor: pointer;

        > * {
            pointer-events: none;
        }
        /* ${props => props.isActive && "border: 2px solid red"}; */
        &:hover {
            transform: scale(1.25);
        }
        img,
        video {
            position: absolute;

            width: 100%;
            height: 100%;
            max-width: 200%;

            aspect-ratio: 1/1;
            object-fit: cover;

            border-radius: 6px;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            z-index: -1;
        }
`;
const StyledBubbleImageSection = styled.section`
    position: absolute;

    max-width: 1440px;
    margin: auto;

    top: 0;
    left: 0;

    grid-row: 1/5;
    grid-column: 1/3;
    @media ${device.tablet} {
        grid-column: 1/3;
    }

    pointer-events: none;
    z-index: 200;
    width: 100vw;
    height: 100%;

    &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        opacity: ${(props) => (props.active ? "100%" : "0%")};
        backdrop-filter: blur(20px);
        transition: opacity 200ms ease-in-out;
    }
    .outer-wrapper {
        width: 100vw;
        height: 100vh;
        position: absolute;
        display: grid;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;

        z-index: var(--z-highest);

        @media (orientation: landscape) {
            grid-template-columns: minmax(0, 50%) minmax(0, 50%);
            grid-template-rows: 100%;
        }
        @media (orientation: portrait) {
            grid-template-rows: 40% 60%;
            grid-template-columns: 100%;
            @media ${device.mobileX} {
                width: 90vw;
            }

            @media ${device.tablet} {
                width: 80vw;
            }
        }
        @media ${device.tablet} {
            height: 90vh;
        }
    }
    @media ${device.laptopL} {
        left: 50%;
        transform: translateX(-50%);
    }
`;

function BubbleImageSection(props) {
    const [currentTab, setCurrentTab] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);

    function getCurrentTabContent() {
        return props.project.bodyCopy[currentTab].content;
    }
    /**
     * Get the URL data to display on the tab.
     * @returns an Object with a url
     */
    function getURLData() {
        const pageUrl = props.project.bodyCopy[currentTab].pageUrl;
        return {
            ...(props.project.repo && {
                repoUrl: {
                    name: "Repository Url",
                    link: props.project.repo,
                    icon: <GitHubIcon fontSize="small"></GitHubIcon>,
                },
            }),
            ...(props.project.url && {
                siteUrl: {
                    name: "Live Site Url",
                    link: props.project.url,
                    icon: <LaunchIcon fontSize="small"></LaunchIcon>,
                },
            }),
            ...(pageUrl && {
                pageUrl: {
                    name: "Page URL",
                    link: pageUrl,
                    icon: <PreviewIcon></PreviewIcon>,
                },
            }),
        };
    }
    function getDisplayImage() {
        let imageSet = getImageSet();
        let imageValue = imageSet[currentSlide].source;
        return `/project demo images/${imageValue}`;
    }
    function getDisplayCaption() {
        let imageSet = getImageSet();
        let caption = imageSet[currentSlide].caption;
        return caption || "";
    }

    function getObjectFit(){
        let imageSet = getImageSet()
        let objectFit = imageSet[currentSlide].objectFit 
        return objectFit
    }


    function getImageSet() {
        let imageKey = Object.keys(props.project.media)[currentTab];
        let imageSet = props.project.media[imageKey];
        return imageSet;
    }
    function getSources() {
        let imageSet = getImageSet();
        return Object.values(imageSet).map((el) => el.source);
    }

    function switchSlides(index) {
        setCurrentSlide(index);
    }
    function switchTabs(index) {
        // const id = clickedTabId;
        setCurrentTab(index);
        // setCurrentTab(tabs.find((tab) => tab.id === id));
        // setSlide(id);
    }

    const categoryElements = getSources().map((_img, index) => {
        let img = `/project demo images/${_img}`;
        return (
            <LensImage
                className="lens-image"
                isActive={currentSlide == index}
                onClick={(event) => switchSlides(index)}
                key={index + "bubbleImage"}
                // setHovered={setHovered}
                // unsetHovered={unsetHovered}

                data-img={`${img}`}
                animationduration={8 + Math.random() + "s"}
                animationdelay={1 + Math.random() * 10 + "s"}
                // onClick={()=switchSlides(index)}
            >
                {img.includes(".mp4") || img.includes(".mov") ? (
                    <video className="project-image" src={`${img}`}>
                        {" "}
                    </video>
                ) : (
                    <img className="project-image" src={`${img}`} alt={img} />
                )}
            </LensImage>
        );
    });

    const tabElements = props.project.bodyCopy.map((section, index) => {
        return (
            <LensButton
                className="tab"
                data-id={section.id}
                key={section.id}
                data-index={index}
                onClick={(event) => switchTabs(index)}
            >
                {section.name}
            </LensButton>
        );
    });

    const linkElements = Object.values(getURLData()).map((option, index) => (
        <CustomTooltip className="tooltip" placement="top" title={option.name} key={index + "customTooltipLinkElement"}>
            <BlurButton className="link-lens" key={index + "LinkButton"} as="li">
                <StyledLink href={option.link} target="_blank" rel="noopener noreferrer">
                    {option.icon && option.icon}
                </StyledLink>
            </BlurButton>
        </CustomTooltip>
    ));

    return (
        <StyledBubbleImageSection active={props.active} hoveringImage={props.hoveringImage} currentTab={currentTab} objectFit={getObjectFit()}>
            <div className="outer-wrapper">
                <StyledDisplaySection className="display-section" active={props.active} currentTab={currentTab} objectFit={getObjectFit()}>

                    <>
                        <div className="overflow-hidden__wrapper">
                            <h1>{props.project.name}</h1>
                            <ImageVideoDisplay
                                source={getDisplayImage()}
                                objectFit={getObjectFit()}
                                shouldPause={props.galleryData.active}
                            ></ImageVideoDisplay>
                            <div className="fade-overlay"></div>
                            {getDisplayCaption() && <p className="caption">{getDisplayCaption()}</p>}
                            <CustomTooltip className="tooltip" placement="top" title="Open in gallery">
                                <CustomIconButton
                                    className="gallery-button"
                                    onClick={() => {
                                        props.setGalleryData("active", true);
                                    }}
                                >
                                    <OpenInFullIcon fontSize="small"></OpenInFullIcon>
                                </CustomIconButton>
                            </CustomTooltip>
                            <CustomTooltip className="tooltip" placement="bottom" title="Back">
                                <CustomIconButton
                                    className="back-button"
                                    onClick={() => {
                                        props.setActive(false);
                                    }}
                                >
                                    <CloseRoundedIcon fontSize="small"></CloseRoundedIcon>
                                </CustomIconButton>
                            </CustomTooltip>

                            <div className="lens-div__wrapper">{categoryElements}</div>
                        </div>
                    </>
                </StyledDisplaySection>
                <StyledContentSection className="content-section" active={props.active} currentTab={currentTab}>
                    <div className="tab-section">{tabElements}</div>
                    <div className="link-section">
                        <ul>{linkElements}</ul>
                    </div>
                    <div className="text-section">{getCurrentTabContent()}</div>
                </StyledContentSection>
            </div>

            {/* <div className="text-content">{props.project.media}</div> */}
        </StyledBubbleImageSection>
    );
}

export default BubbleImageSection;
