import React, { useState, useRef } from "react";
import Slideshow from "./Slideshow";
import styled, { css, keyframes } from "styled-components";
import { projectData } from "./ProjectData";
import { device } from "./styled-components/devices";
import { addPseudoElement } from "./styled-components/pseudo.styled";
/* ----------------------------------------------
 * Generated by Animista on 2022-12-18 12:28:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
const fadeInRight = keyframes`
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;

const StyledPreviewStrip = styled.section`
    grid-column: 1;
    aspect-ratio: 1/1;
    align-self: center;
    @media ${device.tablet} {
        height: 100%;
        aspect-ratio: unset;
        align-self: unset;
    }

    grid-row: 2/4;
    /* position: absolute;
    width: 100%;
    height: 75%;
    top: 50%;
    transform: translateY(-50%); */
    display: grid;
    justify-items: center;
    align-items: center;
    /* grid-template-columns: repeat(3, 100%); */
    grid-template-columns: minmax(0, 100%);

    @media ${device.mobileX} {
        top: 0;
        transform: translateY(0%);
        padding-inline: 25%;
    }

    /* transform: ${(props) => props.transformAmount}; */
    transition: transform 400ms ease-in-out;
    ${"" /* width: 300%; */}
    .demo-image {
        --overlay-color: hsl(245, 100%, 61%);
        grid-column: 1/2;
        width: 100%;
        /* max-width: 90%; */
        height: 90%;
        max-height: 45vh;
        @media (orientation: landscape) {
            max-height: 75vh;
            @media ${device.mobileL} {
                padding-block: 1.5rem;
            }
            @media ${device.laptop} {
                padding-block: 3.5rem;
            }
        }
        object-fit: contain;
        ${"" /* width: 100%; */}
        grid-row: 1/2;
        opacity: 0%;
        aspect-ratio: 3/4;
        transition: opacity 100ms ease-in-out;
        /* background-color: cornflowerblue; */
        /* background-color: var(--overlay-color); */
        display: grid;
        grid-template-columns: minmax(0, 100%);
        @media ${device.tablet} {
            grid-template-rows: 50% 1fr;
        }
        place-content: center;
        opacity: 0%;
        transition: opacity 100ms ease-in-out;
        &:nth-child(${(props) => props.currentIndex + 1}) {
            opacity: 100%;
            transition: opacity 100ms ease-in-out;
            -webkit-animation: ${fadeInRight} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: ${fadeInRight} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }

        ${addPseudoElement("after", { centered: true })};
        &:after {
            display: none;
            overflow: hidden;
            max-width: 90%;
            max-height: 100%;
            max-height: 75vh;
            width: 50%;
            height: 50%;
            transition: all ease-in-out 0.5s;
            background-color: var(--overlay-color);
            mix-blend-mode: lighten;
            z-index: -1 !important;
        }
    }
    .slideshow {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        grid-row: 1/3;

        @media ${device.tablet} {
            grid-row: 1/2;
        }
        aspect-ratio: 3/4;
    }

    .overlay-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .overlay {
        /* background-color: var(--color-primary-bg); */
        /* background-color: transparent; */
        display: none;
        grid-template-columns: repeat(3, 100%);
        grid-row: 1/2;
        &:first-child {
            grid-column: 1/2;
        }
        &:last-child {
            grid-column: 3/4;
        }
    }
`;

const StyledDemoImage = styled.div`
    grid-column: 1/2;
    max-width: 90%;
    max-height: 100%;
    height: 50%;
    max-height: 75vh;
    object-fit: contain;
    grid-row: 1/2;
    opacity: 0%;
    aspect-ratio: 3/4;
    transition: opacity 100ms ease-in-out;
    /* background-color: cornflowerblue; */
    /* background-color: var(--overlay-color); */
    display: grid;
    place-content: center;
    opacity: 0%;
    transition: opacity 100ms ease-in-out;
    img {
        filter: ${(props) => `url(#${props.filter})`};
    }
`;

function PreviewStrip(props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    // const filterSlots = ["filter-a", "filter-b", "filter-a"];
    const filterSlots = ["filter-b", "filter-b", "filter-b"];
    function calculatePosition() {
        const ourIndex = projectData.indexOf(projectData.find((project) => project.id === props.currentProject.id));
        return ourIndex;
        // setCurrentIndex(ourIndex);
    }
    const imageElements = projectData.map((el, index) => (
        <StyledDemoImage className="demo-image" key={index + "slideImage"} filter={filterSlots[index]}>
            <Slideshow images={el.bubbleImages} active={props.active}></Slideshow>
            {/* <img data-index={index} src={`/project demo images/${el.thumbnailImage}`} alt=""></img> */}
            {/* {el.thumbnailImage.includes(".mp4") || el.displayImage.includes(".mov") ? (
                <video autoPlay data-index={index} src={`/project demo images/${el.thumbnailImage}`}></video>
            ) : (
                <img data-index={index} src={`/project demo images/${el.thumbnailImage}`} alt=""></img>
            )} */}
        </StyledDemoImage>
    ));
    return (
        <StyledPreviewStrip
            className="project-preview-frame"
            transformAmount={`translateX(${-calculatePosition() * 100}%)`}
            currentIndex={calculatePosition()}
        >
            {imageElements}
        </StyledPreviewStrip>
    );
}

export default PreviewStrip;
