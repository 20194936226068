import styled from "styled-components";

export const StyledLink = styled.a`
    &:link {
        color: var(--glassy-lilac-light);
    }
    &:visited {
        color: var(--glassy-lilac-bright);
    }
    &:hover {
        color: var(--color-primary-text);
    }
    &:focus {
        color: var(--color-primary-text);
    }
    &:active {
        font-weight: bold;
        color: var(--color-primary-text);
    }
`;
