import React, { useState, useRef } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PreviewIcon from "@mui/icons-material/Preview";
import GitHubIcon from "@mui/icons-material/GitHub";
import LaunchIcon from "@mui/icons-material/Launch";
import { StyledLink } from "./styled-components/links.styled";
import styled, { css } from "styled-components";
import { BlurButton, CustomIconButton } from "./styled-components/Buttons.Styled";
import { device } from "./styled-components/devices";
import { addPseudoElement } from "./styled-components/pseudo.styled";
import { CustomTooltip } from "./Tooltip";
import ImageVideoDisplay from "./ImageVideoDisplay";
import { useMediaQuery } from "react-responsive";

function ProjectInfoDisplay(props) {
    const displayRef = useRef(null);

    const isSmallMobile = useMediaQuery({ query: "(max-width: 320px)" });
    let processPrefix = "See";
    if (isSmallMobile) processPrefix = "";
    const options = [
        {
            buttonText: props.active ? "Close" : `${processPrefix} My Process`,
            source: "/PeekProcess.webp",
            alt: "Monoline Hands overlay of jewels",
            icon: <PreviewIcon></PreviewIcon>,
            position: "bottom-right",
            onClick: () => {
                props.setActive(!props.active);
            },
        },
        {
            icon: <GitHubIcon fontSize="small"></GitHubIcon>,
            buttonText: "View My Code",
            source: "/ViewMyCode.webp",
            alt: "monoline hand illustration holding up crystal",
            isLink: true,
            position: "left",
            url: props.project.repo,
            onClick: () => {},
        },
        {
            buttonText: props.project.hasLiveSite ? "Browse Live Site" : "See More Info",
            source: "/ViewTheLiveSite.webp",
            alt: "monoline hands illustration holding heart",
            isLink: true,
            icon: <LaunchIcon fontSize="small"></LaunchIcon>,
            position: "right",
            url: props.project.url,

            onClick: () => {},
        },
    ];
    const [currentOption, setCurrentOption] = useState(0);
    const [displayVisible, setDisplayVisible] = useState(true);

    const buttonElements = options.map((option, index) => (
        <CustomTooltip
            className="tooltip"
            placement="top"
            title={option.buttonText}
            key={index + "customTooltipOptionLens"}
        >
            <BlurButton
                className="option-lens"
                key={index + "OptionButton"}
                onClick={option.onClick}
                as={option.isLink ? "div" : "button"}
                data-position={option.position}
                data-is-link={option.isLink ? "true" : "false"}
            >
                {!option.isLink ? (
                    <span>{option.buttonText}</span>
                ) : (
                    <StyledLink href={option.url} target="_blank" rel="noopener noreferrer">
                        {option.icon && option.icon}
                    </StyledLink>
                )}
            </BlurButton>
        </CustomTooltip>
    ));
    const imageElements = options.map((option, index) => (
        <img data-index={index + 1} key={index + "OptionImage"} src={option.source} alt={option.alt}></img>
    ));

    return (
        <StyledOptionsSection active={props.active} displayVisible={displayVisible}>
            <div className="preview-img">
                <CustomTooltip className="tooltip" placement="top" title="open in gallery">
                    <CustomIconButton
                        className="gallery-button"
                        onClick={() => {
                            props.setGalleryData("active", true);
                        }}
                    >
                        <OpenInFullIcon fontSize="small"></OpenInFullIcon>
                    </CustomIconButton>
                </CustomTooltip>
                <div className="inner-wrapper">{buttonElements}</div>
                <ImageVideoDisplay
                    source={`/project demo images/${props.project.displayImage}`}
                    override={true}
                    shouldPause={props.active || props.displayVisible || props.galleryData.active}
                ></ImageVideoDisplay>
                {/* {props.project.displayImage.includes(".mp4") || props.project.displayImage.includes(".mov") ? (
                    <video
                    ref={video}
                        controls
                        loop
                        autoPlay
                        muted
                        src={`/project demo images/${props.project.displayImage}`}
                        poster={`/project demo images/${props.project.thumbnailImage}`}
                    ></video>
                ) : (
                    <img src={`/project demo images/${props.project.displayImage}`} alt=""></img>
                )} */}
                {/* <img src={"/project demo images/" + props.project.displayImage} alt="" /> */}
            </div>
            <StyledDisplay
                className="display"
                active={props.active}
                visible={displayVisible}
                index={currentOption}
                ref={displayRef}
            >
                {imageElements}
            </StyledDisplay>
            <div className="button-wrapper">
                {!displayVisible ? (
                    <BlurButton className="view-more" onClick={() => setDisplayVisible(true)}>
                        {/* <KeyboardArrowUp></KeyboardArrowUp> */}
                        View More
                    </BlurButton>
                ) : (
                    <BlurButton
                        className="view-more"
                        onClick={props.active ? () => props.setActive(!props.active) : () => setDisplayVisible(false)}
                    >
                        {!props.active ? (
                            <KeyboardArrowDownIcon fontSize="small"></KeyboardArrowDownIcon>
                        ) : (
                            <CloseRoundedIcon fontSize="small"></CloseRoundedIcon>
                        )}
                    </BlurButton>
                )}
                <h1>{props.project.name}</h1>
                <div className="banner"></div>
            </div>
        </StyledOptionsSection>
    );
}

const StyledOptionsSection = styled.section`
    z-index: var(--z-highest);
    display: grid;
    grid-template-rows: 10% 70% 10% 10%;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;

    ${addPseudoElement("none", { centered: true })};
    pointer-events: none;
    max-height: 100%;
    .preview-img {
        z-index: var(--z-high);
        grid-row: 2/3;
        grid-column: 1/2;
        min-width: 220px;
        @media (orientation: portrait) {
            width: 60%;
        }
        @media (orientation: landscape) {
            width: 30%;
        }
        @media (min-height: 900px) {
            width: 70%;
            @media ${device.mobileM} {
                width: 60%;
            }
            @media ${device.mobileL} {
                width: 50%;
            }
            @media ${device.mobileX} {
                width: 40%;
            }
            @media ${device.tablet} {
                width: 40%;
            }
            @media ${device.laptop} {
                width: 30%;
            }
        }
        max-width: 367px;
        backdrop-filter: blur(4px);
        border: 1px solid var(--glassy-lilac-alpha-2);

        border-bottom: 2px solid var(--color-primary-text);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        aspect-ratio: 1/1;
        align-self: end;
        height: auto;
        display: grid;
        border-radius: 15px;
        grid-template-rows: 80% 20%;
        grid-template-columns: 100%;
        transform-origin: bottom center;
        button {
            font-size: 1.25rem;
        }
        .gallery-button {
            border-radius: inherit;
            position: absolute;
            top: 0vh;
            left: 0vh;
            padding: 1vh;
            z-index: 2;
            color: white;
            opacity: 100%;
            ${(props) =>
                props.displayVisible
                    ? css`
                          opacity: 100%;
                      `
                    : css`
                          opacity: 0%;
                      `};
            transition: opacity 200ms ease-in 100ms;
        }
        ${addPseudoElement("after")};
        &:after {
            z-index: 1;
            background-image: linear-gradient(-135deg, var(--color-primary-bg-700-alpha) 2.25rem, transparent 0%);
            border-radius: inherit;
            background-size: 100% 100%;
            pointer-events: none;
            ${(props) =>
                props.displayVisible
                    ? css`
                          opacity: 100%;
                      `
                    : css`
                          opacity: 0%;
                      `};
            transition: opacity 200ms ease-in 100ms;
        }
        ${addPseudoElement("before")};
        &:before {
            z-index: 1;
            background-image: linear-gradient(
                to bottom,
                var(--color-primary-bg-700) 5%,
                transparent 20%,
                transparent 50%,
                var(--color-primary-bg-700) 81%,
                var(--color-primary-bg) 100%
            );
            background-size: 100% 100%;
            pointer-events: none;
            border-radius: inherit;
            ${(props) =>
                props.displayVisible
                    ? css`
                          opacity: 100%;
                      `
                    : css`
                          opacity: 0%;
                      `};
            transition: opacity 200ms ease-in 100ms;
        }
        .inner-wrapper {
            grid-row: 2/3;
            grid-column: 1/2;
            display: flex;
            padding: 0.25rem 0.5rem;
            align-items: center;
            /* grid-auto-rows: 25%; */
            /* grid-auto-columns: 25%; */
            z-index: var(--z-highest);
            width: 100%;
            justify-content: space-between;
            /* backdrop-filter: blur(12px); */

            gap: 0.25rem;

            .option-lens {
                display: flex;
                align-items: center;
                justify-items: space-between;
                letter-spacing: 0.15ch;
                border: unset;
                height: fit-content;
                border-radius: unset;
                box-shadow: unset;
                padding: unset;
                pointer-events: auto;
                font-weight: 400;
                font-size: 0.85rem;
                backdrop-filter: unset;
                &[data-is-link="true"] {
                    /* background-color: var(--color-primary-bg-700-alpha); */
                    letter-spacing: 0.15ch;
                    height: fit-content;
                    border-radius: 50%;
                }
                ${(props) =>
                    props.displayVisible
                        ? css`
                              pointer-events: auto;
                              opacity: 100%;
                          `
                        : css`
                              pointer-events: none;
                              opacity: 0%;
                          `};

                width: fit-content;

                span,
                a {
                    padding: 0.25em;
                    @media ${device.tablet} {
                        padding: 0.75em;
                    }
                    display: inline-flex;
                    align-items: center;
                    justify-items: space-evenly;
                    gap: 0.5em;
                    height: 100%;
                    width: 100%;
                    &:hover {
                        border: none;
                    }
                }

                span {
                    color: var(--glassy-lilac-light);
                    font-size: 1rem;
                    font-weight: 400;
                    &:hover {
                        color: var(--color-primary-text);
                    }
                }
            }
            button,
            div {
                width: fit-content;
                height: fit-content;
                &:nth-child(2) {
                    margin-left: auto;
                }
            }
        }
        transform-origin: center;

        ${(props) =>
            props.displayVisible && !props.active
                ? css`
                      transition: transform 200ms ease-out;
                      transform: translateY(5%) scaleY(100%);
                      @media (min-height: 500px) {
                          transform: translateY(10%) scaleY(100%);
                      }
                      @media (min-height: 900px) {
                          transform: translateY(15%) scaleY(100%);
                      }

                      @media (min-height: 1024px) {
                          transform: translateY(10%) scaleY(100%);
                      }
                      /* transform: translateY(3vh) scaleY(100%);
                      @media (orientation: portrait) {
                          transform: translateY(2vh) scaleY(100%);
                          @media ${device.mobileX} {
                              transform: translateY(30%) scaleY(100%);
                          }
                      }
                      @media ${device.laptop} {
                          transform: translateY(4vh) scaleY(100%);
                      } */
                  `
                : css`
                      transition: transform 200ms ease-in 300ms;
                      transform: translateY(2vh) scaleY(0%);
                      @media ${device.laptop} {
                          transform: translateY(2vh) scaleY(0%);
                      }
                  `};
        .media-wrapper {
            pointer-events: auto;
            grid-row: 1/3;
            grid-column: 1/2;
            aspect-ratio: 1/1;
            border-radius: 15px;
            .overlay {
                .play-pause-icon {
                    color: var(--color-secondary-text) !important;
                }
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 100%;
            border-radius: inherit;
            z-index: var(--z-lowest);
            ${(props) =>
                props.displayVisible
                    ? css`
                          clip-path: inset(0% round 0%);

                          transition: clip-path 200ms ease-out 200ms;
                      `
                    : css`
                          clip-path: inset(50% round 50%);

                          transition: clip-path 300ms ease-in;
                      `};
        }
    }

    .button-wrapper {
        max-width: 900px;
        overflow: visible;
        position: relative;

        display: grid;

        grid-template-columns: 1fr 90% 1fr;
        @media ${device.mobileX} {
            grid-template-columns: 1fr 70% 1fr;
        }

        @media ${device.tablet} {
            grid-template-columns: 1fr 60% 1fr;
        }

        grid-template-rows: repeat(2, 1fr);
        z-index: var(--z-highest);
        grid-row: 3/5;
        grid-column: 1/2;
        pointer-events: auto;
        justify-items: center;
        align-items: center;
        width: 90%;
        @media ${device.laptop} {
            width: 90%;
        }
        overflow: visible;
        ${addPseudoElement("before")};
        &:before {
            /* transform: skew(25deg); */
            mask-image: linear-gradient(
                90deg,
                rgba(24, 20, 46, 0) 0%,
                rgba(24, 20, 46, 0.99) 8%,
                rgba(24, 20, 46, 0.92) 12%,
                rgba(24, 20, 46, 1) 34%,
                rgba(24, 20, 46, 1) 47%,
                rgba(24, 20, 46, 1) 68%,
                rgba(24, 20, 46, 0.91) 77%,
                rgba(24, 20, 46, 0.97) 90%,
                rgba(24, 20, 46, 0) 100%
            );
            z-index: -2;

            background-color: var(--color-primary-bg-700-alpha);
            border-top: 1px solid var(--color-primary-text);
            border-bottom: 1px solid var(--color-primary-text);
            ${(props) =>
                props.active &&
                css`
                    display: none;
                    @media ${device.laptop} {
                        display: initial;
                    }
                `};
        }
        ${(props) =>
            props.active &&
            css`
                display: none;
                @media ${device.laptop} {
                    display: grid;
                }
            `};

        .view-more {
            position: absolute;
            top: 0;
            left: 50%;
            grid-row: 1/2;
            grid-column: 2/3;
            z-index: var(--z-high);
            transform: translate(-50%, -60%);
            padding: 0.25em 0.75em;
            @media ${device.mobileL} {
                transform: translate(-50%, -50%);
                padding: 0.5em 1em;
            }
            font-weight: 600;

            ${(props) =>
                props.active &&
                css`
                    display: none;
                    /* transform: translateY(100%); */
                    @media ${device.laptop} {
                        display: initial;
                    }
                `};

            background-color: var(--color-primary-bg-700-alpha);
            color: var(--glassy-lilac-light);
            &:hover {
                color: var(--color-primary-text);
            }
        }
        .banner {
            background-color: var(--color-primary-bg-700-alpha);
            transform: scaleY(1.1);
            transform-origin: center;
            overflow: visible;
            grid-row: 1/3;
            grid-column: 2/3;
            place-content: center;
            backdrop-filter: blur(8px);
            /* font-size: 3rem; */
            width: 100%;
            height: 100%;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border-top: 1px solid var(--color-primary-text);
            border-bottom: 1px solid var(--color-primary-text);

            border-top: 1px solid var(--color-primary-text);
            border-bottom: 1px solid var(--color-primary-text);
            pointer-events: none;
            z-index: -1;
        }

        h1 {
            /* font-family: "Big Shoulders Display", cursive; */
            /* font-family: "Big Shoulders Inline Display", cursive; */
            /* font-family: "Dancing Script", cursive; */
            /* font-family: "Lobster Two", cursive; */
            font-family: "Cinzel Decorative", cursive;
            /* font-weight: 400; */
            font-weight: bold;
            /* letter-spacing: 0.45ch; */
            white-space: nowrap;
            transform-origin: center;
            overflow: hidden;
            grid-row: 1/3;
            grid-column: 2/3;
            place-content: center;
            width: 100%;
            font-size: 4vmax;
            padding: 2vh 1rem;
            pointer-events: auto;
            @media ${device.laptop} {
                font-size: min(5vmax, 40px);
            }
            ${(props) =>
                props.active &&
                css`
                    display: none;
                    @media ${device.laptop} {
                        display: grid;
                    }
                `};
        }
    }
`;
const StyledDisplay = styled.section`
    grid-row: 1/3;
    grid-column: 1/2;
    /* position: absolute; */
    width: 100%;
    height: 100%;
    transition: opacity 400ms ease-in-out 400ms;
    z-index: var(--z-top);
    pointer-events: none;
    opacity: ${(props) => (props.visible && !props.active ? "100%" : "0%")};
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 100%;
        max-width: 100%;
        opacity: 0%;
        transition: opacity 400ms ease-in-out 400ms;
        padding: 2rem;
        z-index: var(--z-highest);
        @media ${device.tablet} {
            padding: 5rem;
        }
        &[data-index="${(props) => props.index}"] {
            opacity: 100%;
            transition: opacity 400ms ease-in-out 400ms;
        }
    }
`;
export default ProjectInfoDisplay;
