import React from "react";
import { device } from "./styled-components/devices";
import styled, { css, keyframes } from "styled-components";
import { BlurButton } from "./styled-components/Buttons.Styled";
import { ParallaxLayer, ParallaxGroup } from "./ParallaxGroup";
import { addPseudoElement } from "./styled-components/pseudo.styled";

const panBackgroundImage = keyframes`
  0% {
    /* background-position: 0 center; */
    transform: translateX(0);
  }
  100% {
    /* background-position: -900px center; */
    transform: translateX(-540px);
  }
`;

const StyledMargin = styled.section`
    &:first-child {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    &:last-child {
        grid-column: 3/4;
        grid-row: 1/2;
    }
`;

export const SectionClipped = styled.section``;

const StyledWrapper = styled(ParallaxLayer)`
    --lens-y: ${(props) => (!props.reverse ? "85%" : "70%")};
    --lens-x: 50%;
    @media ${device.tablet} {
        --lens-y: 60%;
        --lens-x: 80%;
    }
    ${(props) =>
        props.reverse &&
        css`
            --landing-section-height: var(--landing-section-height-alt);
        `};
    display: grid;
    grid-template-columns: 1fr minmax(0, 1024px) 1fr;
    @media ${device.desktop} {
        grid-template-columns: 1fr minmax(0, 1440px) 1fr;
    }
    /* min-height: 100vh; */
    min-height: var(--landing-section-height);
    isolation: isolate;

    ${(props) =>
        !props.reverse &&
        props.includeSlice &&
        css`
            ${addPseudoElement("before")};
            &:before {
                background-color: var(--color-primary-bg);

                /* clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0 0); */
                /* clip-path: polygon(50% 100%, 0 0, 100% 0); */
                /* clip-path: polygon(50% 0, 0 100%, 100% 100%); */
                clip-path: polygon(0% 0%, 100% 0, 60% 100%, 50% 85%, 40% 100%);
                height: 2rem;
                top: 100%;
                z-index: 0;
            }
        `};
    z-index: ${(props) => props.reverse && "-1"};

    @media ${device.tablet} {
        /* height: 90vh; */
        height: 100vh;
    }
    ${StyledMargin} {
        background-color: var(--color-primary-bg);
        ${(props) => props.reverse && "background-color: hsla(275, 100%, 25%, 0.518);"}
    }
`;
/* ---------------------------- Styled Components --------------------------- */
// #region Styled Components
const StyledSection = styled.section`
    padding-block: ${(props) => (props.reverse ? "5rem" : "5rem")};
    @media ${device.tablet} {
        padding-block: unset;
    }
    grid-column: 2/3;
    grid-row: 1/2;
    scroll-snap-align: start;
    position: relative;
    color: var(--color-secondary-text);
    display: grid;
    /* min-height: 100vh; */
    min-height: var(--landing-section-height);
    grid-template-rows: 1fr 1fr;
    --circle-inner: 50%;
    --circle-inner-border: 80px;
    --circle-outer-border: 81px;
    --circle-outer: 84%;
    @media ${device.tablet} {
        --circle-inner: 50%;
        --circle-inner-border: 70px;
        --circle-outer-border: 71px;
        --circle-outer: 54%;
    }
    @media ${device.laptop} {
        --circle-inner: 90%;
        --circle-inner-border: 80px;
        --circle-outer-border: 81px;
        --circle-outer: 94%;
    }

    @media ${device.laptopL} {
        --circle-inner: 80%;
        --circle-inner-border: 70px;
        --circle-outer-border: 71px;
        --circle-outer: 74%;
    }
    @media ${device.tablet} {
        grid-template-rows: 1fr;
        height: 100vh;
    }

    @media ${device.mobileL} {
        gap: 1rem;
    }

    @media ${device.tablet} {
        gap: 1.5rem;
        padding-inline: clamp(1rem, 1rem + 2vw, 2rem);
        padding-block: 4vh;
        grid-template-columns: minmax(0, 60%) minmax(0, 1fr);
        ${(props) =>
            props.reverse &&
            css`
                /* grid-template-columns: minmax(300px, 30%) minmax(0, 1fr); */
                /* grid-template-columns: minmax(300px, 40%) minmax(0, 1fr); */
                grid-template-columns: minmax(250px, 25%) minmax(0, 1fr);
            `}

        grid-template-rows: 100vh;
        /* grid-template-rows: 90vh; */
        height: 100%;
    }
    @media ${device.laptop} {
        ${(props) =>
            props.reverse &&
            css`
                grid-template-columns: minmax(300px, 40%) minmax(0, 1fr);
            `}
    }

    align-items: center;

    align-content: center;
    overflow: visible;
    background-color: transparent;
    background: none;
    .text-wrapper {
        /* float: left; */
        display: block;
        width: 100%;

        .circle {
            display: none;
            --normal-shape: circle(100px at 72% 54.1%);
            --reverse-shape: circle(125px at 25% 20%);
            @media ${device.tablet} {
                --normal-shape: circle(100px at 74% 64.5%);
                --reverse-shape: circle(129px at 11px 188px);
            }
            float: ${({ reverse }) => (reverse ? "left" : "right")};
            shape-outside: ${({ reverse }) => (reverse ? "var(--reverse-shape)" : "var(--normal-shape)")};
            width: 200px;
            height: ${({ reverse }) => (reverse ? "300px" : "250px")};
        }
        @media ${device.tablet} {
            .circle {
                display: block;
                shape-outside: ${({ reverse }) =>
                    reverse ? "circle(160px at -148px 83px)" : "circle(132px at 125.00% 83.00%)"};
            }
        }
        @media ${device.laptop} {
            .circle {
                display: none;
            }
        }
    }
    .lens-div {
        //shared styles between lens and its frame

        &,
        + .lens-frame {
            position: absolute;
            --width: ${(props) => props.width || "150px"};
            --height: ${(props) => props.height || "150px"};
            --top: ${(props) => props.top || "var(--lens-y)"};
            --width-reverse: "250px";
            width: ${({ reverse }) => (!reverse ? "var(--width)" : "250px")};
            height: ${({ reverse }) => (!reverse ? "var(--height)" : "250px")};
            --left-percentage: var(--circle-inner);
            /* --left-percentage: ${(props) =>
                props.reverse && props.reverse === true ? "var(--circle-inner)" : "20%"} */
            /* --left-percentage: ${(props) => (props.reverse && props.reverse === true ? "50%" : "50%")} */
            position: absolute;
            overflow: hidden;
            background-repeat: repeat;
            opacity: 60%;
            z-index: 400;
            transform-origin: center;
            transform: translate(-50%, -50%) scale(1);
            /* left: ${({ reverse }) => (!reverse ? "var(--circle-inner)" : "10%")}; */
            left: 50%; //${({ reverse }) => (!reverse ? "var(--circle-inner)" : "10%")};
            top: ${({ reverse }) => (!reverse ? "var(--top)" : "var(--lens-y)")};
            width: ${({ reverse }) => (!reverse ? "var(--width)" : "250px")};
            height: ${({ reverse }) => (!reverse ? "var(--height)" : "250px")};
            @media ${device.mobileM} {
                /* left: ${({ reverse }) => (!reverse ? "var(--circle-inner)" : "20%")}; */
                left: 50%;
            }
            @media ${device.tablet} {
                width: ${({ reverse }) => (!reverse ? "var(--width)" : "300px")};
                height: ${({ reverse }) => (!reverse ? "var(--height)" : "300px")};
                left: ${({ reverse }) => (!reverse ? "var(--lens-x)" : "20%")};
                top: ${({ reverse }) => (!reverse ? "var(--top)" : "50%")};
            }
            @media ${device.laptopL} {
                left: ${({ reverse }) => (!reverse ? "var(--lens-x)" : "20%")};
                /* top: ${({ reverse }) => (!reverse ? "60%" : "50%")}; */
            }
            &:not(.lens-frame) {
                ${({ reverse }) =>
                    !reverse &&
                    css`
                        width: 150px;
                        height: 150px;
                        top: var(--lens-y);
                    `}
            }
        }
        //styles specific to lens only
        border: ${({ reverse }) => (reverse ? "7px double var(--color-primary-text-light)" : "none")};
        box-shadow: ${({ reverse }) => (reverse ? "inset 0 0 10px #1c00227c" : "none")};
        border-radius: 50%;
        display: grid;
        place-items: center;
        place-content: center;
        &::after {
            pointer-events: none;
            content: "";
            position: absolute;
            ${({ reverse }) =>
                reverse
                    ? css`
                          width: 3240px;
                          height: 3240px;
                          /* height: 100%; */
                          background-repeat: repeat;
                          background-image: url("/background-textures/stars-sun-and-moon-seamless-pattern-9216112.png");
                          background-size: 540px;
                          animation: 19s ${panBackgroundImage} linear infinite;
                      `
                    : css`
                          clip-path: circle(100%);
                          /* background-image: url("/illustrations/constellation-28.webp");
                          z-index: 900;
                          background-size: 100%; */
                      `}
        }
        &::before {
            pointer-events: none;
            content: "";
            position: absolute;
            width: 3240px;
            height: 100%;
            background-repeat: repeat;
            background-image: url("/background-textures/cosmos-star-in-the-night.png");
            background-size: 540px;
            animation: 15s ${panBackgroundImage} linear infinite;
        }
        img {
            width: 50%;
            height: auto;
        }
        &:hover {
            /* transform: translate(-50%, -50%) scale(1.5); */
            /* background-position: bottom right; */
        }
        //styles specific to frame only
        + .lens-frame {
            transform: ${(props) => {
                if (!props.reverse) return "translate(-50%, -32%) scale(2.15, 2.49)";
                return "translate(-50%, -50%) scale(1.85, 1.85)";
            }};
            /* display: none; */
            @media ${device.tablet} {
                display: initial;
            }
            z-index: 990;
        }
    }

    ${(props) =>
        !props.reverse
            ? css`
                  &::before {
                      content: "";
                      top: 0;
                      left: 0;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background-repeat: repeat;
                      background-size: 100%;
                      background-color: var(--color-primary-bg);
                      /* background-image: radial-gradient(
                          100px circle at var(--lens-x) var(--lens-y),
                          var(--color-secondary-bg) var(--circle-inner),
                          var(--color-secondary-bg) var(--circle-outer)
                      ); */
                      z-index: -1;
                      mask-image: radial-gradient(
                          100px circle at var(--lens-x) var(--lens-y),
                          transparent var(--circle-inner),
                          transparent var(--circle-inner-border),
                          black var(--circle-outer-border),
                          black var(--circle-inner)
                      );
                      backdrop-filter: blur(12px);
                  }
                  &::after {
                      pointer-events: none;
                      content: "";
                      top: 0;
                      left: 0;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      background-repeat: no-repeat;
                      background-size: 100%;
                      background-position: var(--lens-x) var(--lens-y);

                      background-image: radial-gradient(100px circle at var(--lens-x) var(--lens-y));

                      mask-image: radial-gradient(
                          100px circle at var(--lens-x) var(--lens-y),
                          black var(--circle-inner),
                          black var(--circle-inner-border),
                          transparent var(--circle-outer-border),
                          transparent var(--circle-inner)
                      );
                      backdrop-filter: blur(2px);
                  }
              `
            : css`
                  &:after {
                      pointer-events: none;
                      content: "";
                      top: 100%;
                      width: 50%;
                      height: calc(100% + 8rem);
                      left: 0;
                      z-index: 900;
                      isolation: isolate;
                      background-image: url("silhouettes/hanging-moon-1.png");
                      background-size: 70%;
                      background-position-y: -60%;
                      background-repeat: no-repeat;
                  }
              `}
    ${(props) => props.reverse && "background-color: hsla(275, 100%, 25%, 0.518);"}
`;
export const ContentDiv = styled.div`
    grid-column: ${(props) => (props.reverse && props.reverse === true ? "2/3" : "1/2")};
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    padding-inline: clamp(1.5rem, 1.5rem + 2vw, 2rem);
    z-index: 900;
    @media ${device.tablet} {
        z-index: initial;
        background-color: transparent;
        border: none;
        backdrop-filter: unset;
        padding: 1rem 0.5rem;
        > h3 {
            font-size: 2rem;
        }
    }
    > h3 {
        font-size: 2rem;
        /* margin-bottom: 1rem; */
        margin-bottom: 2.5rem;
        align-self: ${(props) => (props.reverse && props.reverse === true ? "flex-end" : "auto")};

        /* align-self: flex-end; */
        letter-spacing: 0.35rem;
        text-transform: uppercase;
        color: var(--color-primary-text);
        @media ${device.tablet} {
            font-size: 2.5rem;
            align-self: auto;
            text-align: left;
        }
    }
    .text-content {
        p {
            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                /* Retina-specific stuff here */

                /* font-size: 0.8rem; */
            }
            font-weight: 300;
            /* font-size: 1rem; */
            /* line-height: 1.6; */
            @media ${device.tablet} {
                font-size: 1.25rem;
                min-width: 35ch;
                max-width: 65ch;
                text-align: left;
                line-height: 1.8;
                &:not(+ p) {
                    margin-top: 3rem;
                }
            }
            @media ${device.tablet} {
                text-align: ${(props) => (props.reverse && props.reverse === true ? "right" : "left")};
            }
        }
    }
    > button {
        max-width: fit-content;
    }
`;
ContentDiv.displayName = "ContentDiv";

// #endregion
function LandingSection(props) {
    return (
        <StyledWrapper layer="base" reverse={props.reverse} includeSlice={props.includeSlice}>
            <StyledMargin></StyledMargin>
            <StyledSection
                className="content-section"
                reverse={props.reverse}
                width={props.frameWidth}
                height={props.frameHeight}
                top={props.frameTop}
            >
                <div className="lens-div"></div>
                {!props.reverse && (
                    <img
                        className="lens-frame"
                        src={!props.reverse && props.frame} //: `/frames/constellation-28.webp`}
                        alt="moon phase symbol"
                    ></img>
                )}
                <ContentDiv className="content" reverse={props.reverse}>
                    <h3 className="subtitle">{props.sectionTitle}</h3>
                    <div className="text-wrapper">
                        <div className="circle"></div>
                        <div className="text-content">{props.sectionContent}</div>
                    </div>
                    {props.buttonText && (
                        <BlurButton>
                            <span>{props.buttonText} </span>
                        </BlurButton>
                    )}
                </ContentDiv>
            </StyledSection>

            <StyledMargin></StyledMargin>
        </StyledWrapper>
    );
}
export default LandingSection;
