import React, { useState } from "react";
import styled from "styled-components";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { device } from "./styled-components/devices";
import { DotButton, CustomIconButton, ImageButton } from "./styled-components/Buttons.Styled";

const GalleryContainer = styled.section`
    position: fixed;
    z-index: var(--z-top);
    top: 0;
    /* left: 50%; */
    width: 100vw;
    height: 100vh;
    /* transform: translateX(-50%); */
    background-color: var(--glassy-purple);
    backdrop-filter: blur(12px);
    display: grid;
    place-content: center;
    grid-template-rows: 40% 10%;
    @media ${device.laptop}, (orientation: landscape) {
        grid-template-rows: minmax(0, 90%) 1fr;
    }
    ${CustomIconButton} {
        position: absolute;
        top: 5%;
        right: 5%;
        z-index: var(--z-highest);
        color: white !important;
    }
    .slide-area {
        display: grid;
        grid-template-rows: minmax(0, 90%) minmax(0, 1fr);
        justify-content: center;
        justify-items: center;
        align-items: center;
        position: relative;
        height: 100%;
        grid-row: 1/2;
        grid-column: 1/2;
        background-color: var(--color-primary-bg-700-alpha);
        border-radius: 15px;

        img,
        video {
            align-self: stretch;
            justify-self: stretch;
            grid-row: 1/2;
            grid-column: 1/2;
            width: 80vw;
            height: 100%;
            max-width: 100%;
            border-radius: 12px;
            object-fit: contain;
            @media ${device.tablet} {
                /* padding: 2rem 5rem; */
            }
            @media ${device.laptop} {
                max-width: 100%;
            }
        }
    }

    .caption {
        color: var(--color-secondary-text);
        grid-row: 2/3;
        grid-column: 1/2;
        align-self: center;
        justify-self: center;
        padding: 1vmin;
        font-size: 1.25vmax;
        line-height: 1.8;
        margin: unset;
        /* max-width: 70ch; */
    }
    /* display: grid;
    grid-template-rows: minmax(0, 1fr) 2%;
    grid-column: 1/2;
    gap: 1rem;

    @media ${device.tablet} {
        padding: clamp(1rem, 1rem + 1vh, 2rem);
    } */
    .button-area {
        grid-row: 2/3;
        grid-column: 1/2;
        /* @media ${device.tablet} and (orientation: landscape) {
            grid-row: 2/3;
        } */
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        button {
            background-color: var(--color-secondary-text);
            /* border: unset; */
            opacity: 50%;
            transform: scale(0.75);
            transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
            &.active {
                opacity: 100%;
                transform: scale(1);
                transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
            }
            &:hover {
                transform: scale(0.9);
                opacity: 80%;
                transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
            }
        }
    }
`;

GalleryContainer.displayName = "GalleryContainer";

function Gallery(props) {
    const [currentSlide, setCurrentSlide] = useState(0);
    function mapMedia(mediaPaths, autoplay) {
        return mediaPaths.map((source, index) => returnImage(source, index, autoplay));
    }
    function returnImage(mediaObject, index, shouldAutoplay, subfolder = "") {
        if (!mediaObject) {
            mediaObject = { source: "default-image.jpg" };
        }
        const { source, isVideo, caption } = mediaObject;

        const sourceIsVid = source.includes(".mp4") || source.includes(".mov");
        const image =
            isVideo || sourceIsVid ? (
                <video
                    src={`/project demo images/${subfolder && subfolder}${source}`}
                    key={Math.random() + index}
                    controls={true}
                    autoPlay={shouldAutoplay}
                    muted={true}
                    loop={true}
                ></video>
            ) : (
                <img
                    src={`/project demo images/${subfolder && subfolder}${source}`}
                    alt=""
                    key={Math.random() + index}
                />
            );
        const wrapper = (
            <>
                {image}
                {caption && <p className="caption">{caption}</p>}
            </>
        );
        return wrapper;
    }
    const ourChildren = mapMedia(props.children, true);
    function updateSlide(value) {
        setCurrentSlide(value);
    }
    const navDots = props.children.map((el, index) => {
        const { source } = el;
        const img = `/project demo images/${source}`;
        return (
            <ImageButton
                className={currentSlide === index ? "active" : ""}
                key={index + "GalleryDot"}
                onClick={() => updateSlide(index)}
            >
                {img.includes(".mp4") || img.includes(".mov") ? (
                    <video className="project-image" src={`${img}`}>
                        {" "}
                    </video>
                ) : (
                    <img className="project-image" src={`${img}`} alt={img} />
                )}
            </ImageButton>
        );
    });
    return (
        <GalleryContainer className="gallery">
            <CustomIconButton onClick={() => props._setGalleryData("active", false)}>
                <CloseRoundedIcon></CloseRoundedIcon>
            </CustomIconButton>
            <div className="slide-area">{ourChildren[currentSlide]}</div>
            <div className="button-area">{navDots}</div>
        </GalleryContainer>
    );
}

export default Gallery;
