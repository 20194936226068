import React, { useState, useEffect, useRef } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { helpers } from "./helpers/helpers";
import styled, { css } from "styled-components";
import { addPseudoElement } from "./styled-components/pseudo.styled";
import { device } from "./styled-components/devices";

const StyledPlayPauseOverlay = styled.div`
    display: grid;
    place-content: center;

    pointer-events: none;
    position: absolute;
    ${(addPseudoElement("none"), { centered: true })};
    width: 100%;
    height: 100%;
    z-index: var(--z-highest);
    .play-pause-icon {
        opacity: ${(props) => (props.paused ? "100%" : "0%")};
        transition: opacity 200ms ease-in-out;
        transition-delay: 0ms;
    }
`;
const StyledImageVideoDisplay = styled.div`
    pointer-events: auto;
    grid-column: 1/3;
    grid-row: 1/3;
    @media ${device.laptop} {
        grid-row: 1/2;
        grid-column: 1/2;
    }

    transition: transform 200ms ease-in-out, opacity 500ms ease-in-out;
    &:hover,
    &:focus {
        & ~ p.caption {
            opacity: 100%;
        }
        .overlay {
            .play-pause-icon {
                opacity: 80%;
                transition: opacity 200ms ease-in-out;

                transition-delay: 400ms;
            }
        }
    }
    cursor: inherit;
    pointer-events: inherit;
    ${(props) =>
        !props.override &&
        css`
            img,
            video {
                ${addPseudoElement("none")}
                width: 100%;
                height: 100%;
                object-fit: ${props.objectFit == "contain" ? "contain" : "cover"};
                object-position: ${props.objectFit == "contain" ? "center" : "top"};
                z-index: var(--z-lowest);
                mask-image: url(/Masks/MaskSquare.webp);
                mask-position: center;
                mask-size: 100% 100%;
            }
        `};
`;
function ImageVideoDisplay(props) {
    const { source, style, override, objectFit } = props;
    const isVideo = source.includes(".mp4") || source.includes(".mov");
    const [pauseState, setPauseState] = useState({
        manualPaused: false,
        autoPaused: false,
    });
    const video = useRef(null);
    useEffect(() => {
        setPauseState((prev) => {
            return {
                ...prev,
                autoPaused: props.shouldPause,
            };
        });
    }, [props]);
    useEffect(() => {
        const vidElement = video.current;
        if (!vidElement || !vidElement.tagName || vidElement.tagName !== "VIDEO") {
            return;
        }
        if (pauseState.autoPaused || pauseState.manualPaused) {
            vidElement.pause();
        } else {
            vidElement.play();
        }
    }, [pauseState]);

    function toggleVideoPlayState() {
        setPauseState((prev) => {
            return {
                ...prev,
                manualPaused: !prev.manualPaused,
            };
        });
    }
    return (
        <StyledImageVideoDisplay
            className="media-wrapper"
            style={{ ...style }}
            override={override}
            objectFit={objectFit}
            onClick={() => {
                toggleVideoPlayState();
            }}

        >
            {source.includes(".mp4") || source.includes(".mov") || source.includes(".webm") ? (
                <video className="project-image" ref={video} src={source} autoPlay={true} muted={true} loop={true}>
                    {" "}
                </video>
            ) : (
                <img className="project-image" src={source} alt={source} />
            )}
            {isVideo && (
                <StyledPlayPauseOverlay className="overlay" paused={pauseState.autoPaused || pauseState.manualPaused}>
                    {pauseState.autoPaused || pauseState.manualPaused ? (
                        <PlayArrowIcon className="play-pause-icon"></PlayArrowIcon>
                    ) : (
                        <PauseIcon className="play-pause-icon"></PauseIcon>
                    )}
                </StyledPlayPauseOverlay>
            )}
        </StyledImageVideoDisplay>
    );
}

export default ImageVideoDisplay;
