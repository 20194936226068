import React, { useEffect, useState, useMemo } from "react";
import styled, { css, keyframes } from "styled-components";
import { helpers } from "./helpers/helpers";
import { device } from "./styled-components/devices";
import { addPseudoElement } from "./styled-components/pseudo.styled";
const rotate = (props) => keyframes`
0%{

    transform: translate(-50%, -50%) rotateZ(${props.startRotation}) !important;
}
    100% {
    transform: translate(-50%, -50%) rotateZ(${props.endRotation}) !important;
    }

`;

const fadeIn = keyframes`
    0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

`;
const rotateIn = (props) => keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) rotate(${props.startRotation});
    opacity: 1;
  }
`;
const pulse = keyframes`
20%{
    opacity: 0.2;
}
 100%{
    transform: scale(2.5);
    opacity: 0;
 }
`;
function rotateHue(props) {
    const { index } = props;
    let string = "";
    string += `font
            img{
            filter: hue-rotate(${(index + 10) * 10 + 300}deg);
            }
    `;
    return string;
}
function returnIfHovered(props) {
    const { isInnerMoon, hoveredCategory, hovered, ourCategory } = props;
    if (!isInnerMoon) {
        if (hoveredCategory !== "all") {
            if (hovered || hoveredCategory === ourCategory) {
                return "100%";
            } else {
                return "1%";
            }
        } else {
            return "100%";
        }
    }
    return "0%";
}
const StyledOrbitRing = styled.div`
    border: 3px dotted var(--color-primary-text-alpha-2);
    &:nth-child(even) {
        border-style: solid;
        border-width: 1px;
    }
    color: white;
    z-index: var(--z-top);
    top: 50%;
    pointer-events: none;
    left: 50%;
    opacity: ${(props) => returnIfHovered(props)};
    transition: opacity 400ms ease-in-out;
    &:focus,
    &:focus-within {
        outline: unset;
        /* border: unset; */
    }
    /* opacity: ${(props) => (props.isInnerMoon ? "0%" : "100%")}; */
    .orbit-ring {
        z-index: var(--z-top);
        /* border: 2px dotted var(--color-primary-text-alpha-3); */
        opacity: ${(props) => (props.hovered ? "100%" : "0%")};
        ${(props) =>
            props.hovered &&
            css`
                animation: ${fadeIn} 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                @media (prefers-reduced-motion: no-preference) {
                    animation-delay: 500ms;
                    animation: ${rotateIn} 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                }
            `};
        transition: opacity 400ms ease-in-out;
    }
    .swirl-div,
    .pulse-div {
        position: absolute;
        width: ${(props) => `${props.itemSize}vw`};
        height: ${(props) => `${props.itemSize}vw`};
        @media ${device.tablet}, (orientation: landscape) {
            width: ${(props) => `${props.itemSize}vw`};
            height: ${(props) => `${props.itemSize}vw`};
        }
        top: 0;
        left: 0;
        z-index: var(--z-high);
        border-radius: 50%;
    }
    .swirl-div {
        background-image: url(/background-textures/holo-bg.webp);
        background-size: 100%;
        padding: 1rem;
        clip-path: inset(2px round 80px);
        opacity: 50%;
        display: none;
        /* @media ${device.tablet} { */
        /* display: initial; */
        /* } */
    }
    .pulse-div {
        background: #03001e; /* fallback for old browsers */
        background: radial-gradient(circle at 50%, #fdeff9, #8f38ec, #3f03c0, #03001e); /* Chrome 10-25, Safari 5.1-6 */

        opacity: 0;

        ${(props) =>
            props.hovered &&
            css`
                animation: ${pulse} 1s ease-out 1;
                &div:nth-child(2) {
                    animation: ${pulse} 2s ease-out 1;
                }
            `}
    }

    .moon {
        pointer-events: auto;
        background-size: cover;
        border-radius: 50%;
        place-content: center;
        display: grid;

        &[data-interactive="true"] {
            /* @media ${device.tablet} { */
            /* border: 1px solid var(--color-primary-text); */
            /* } */
            cursor: pointer;
            z-index: var(--z-top);
        }
        &[data-interactive="false"] {
            /* border: 1px solid var(--glassy-lilac-alpha-2); */
            /* border: none; */
            opacity: 60%;
            /* backdrop-filter: blur(14px); */
            /* backdrop-filter: opacity(50%); */
            background-color: var(--glassy-lilac-alpha-2);
            z-index: var(--z-high);
            filter: hue-rotate(12deg);

            /* ${(props) => rotateHue(props)}; */
        }
        &.inner-moon {
            pointer-events: none;
            /* background-color: var(--color-primary-text-dark); */

            &[data-interactive="true"] {
                background-color: none;
                border: none;
                font-size: 1vmax;
                position: relative;

                filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};
                p {
                    ${addPseudoElement("none", { centered: true })};
                    z-index: var(--z-highest);
                    display: none;
                    @media ${device.tablet} {
                        display: grid;
                    }
                    place-content: center;
                    text-align: center;
                    margin: unset;
                    margin-block: unset;
                    margin-top: unset;
                    margin-bottom: unset;
                    font-weight: 400;

                    font-family: "Raleway", sans-serif;
                    color: var(--glassy-lilac-brighter);
                }
                img {
                    ${addPseudoElement("none", { centered: true }, { transform: "translate(-50%, -50%) scale(2.5)" })};

                    z-index: var(--z-high);
                    /* filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`}; */
                    filter: ${(props) => `hue-rotate(22deg)`};
                }
            }
            &[data-interactive="false"] {
                backdrop-filter: unset;
                display: none;
                border: none;
                background: none;

                filter: hue-rotate(12deg);
            }
        }
    }
`;
function OrbitRing(props) {
    const [hovered, setHovered] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [inPosition, setInPosition] = useState(false);
    const [randomNumbers, setRandomNumbers] = useState(null);

    useEffect(() => {
        setRandomNumbers(helpers.shuffleArray([0.5, 0.25, 0.65, 0.5, 0.25, 0.65, 0.5, 0.25, 0.35]));
    }, []);

    useEffect(() => {
        if (props.hoveredCategory === props.category) {
            setHovered(true);
        } else {
            setHovered(false);
        }
    }, [props.hoveredCategory, props.category]);
    useEffect(() => {
        if (!hovered) {
            setInPosition(false);
        }
    }, [hovered]);

    function _setHovered(bool, event) {
        setHovered(bool);
        props.setHovered(event, bool);
    }

    const u = props.unit;

    const childStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: `${props.itemSize}${u}`,
        height: `${props.itemSize}${u}`,
        margin: `-${props.itemSize / 2}${u}`,
        transformOrigin: "center",
    };
    const parentStyle = {
        position: "absolute",
        width: `${props.circleSize}${u}`,
        aspectRatio: "1/1",
        // height: `${props.circleSize}${u}`,
        padding: 0,
        borderRadius: "50%",
        bottom: 0,
        // transform: "translate()"
        transformOrigin: "center",
        ...props.style,
        // ...{props.style || {}},
    };

    const itemCount = props.children.length;
    let rot = 0;
    // let sizeIndex = 0;
    let angle = 360 / itemCount;
    function _setInPosition(e, isInPosition) {
        // e.stopPropagation();
        if (props.isInnerMoon && e.currentTarget && e.currentTarget.classList.contains("inner-orbit-ring")) {
            if (e.target.classList.contains("inner-orbit-ring")) {
                setInPosition(isInPosition);
            }
        }
    }

    let placedChildren = randomNumbers
        ? props.children.map((el, index) => {
              const circleSize = props.circleSize || 50;
              let add = "";
              const randomSize = randomNumbers[index];
              const isInteractive = index === props.index;
              if (!isInteractive) {
                  add = `scale(${randomSize})`;
                  if (props.isInnerMoon) {
                      add = `scale(${randomSize + 0.2})`;
                  }
              }
              let value = ` rotate(${rot * 1}deg) translate(${circleSize / 2}${u}) ${add} rotate(${rot * -1}deg)`;
              rot = rot + angle;
              let bgColor = "var(--glassy-lilac-alpha-0)";
              let listeners = {};
              if (isInteractive && !props.isInnerMoon) {
                  listeners = {
                      onFocus: (event) => {
                          _setHovered(true, event);
                      },
                      onBlur: (event) => {
                          _setHovered(false, event);
                      },
                      //   onClick: (event) => {
                      //       _setClicked(event);
                      //   },
                  };
              }
              let animationHandler = {};
              if (isInteractive && props.isInnerMoon) {
                  animationHandler = {
                      inposition: inPosition,
                  };
              }
              return React.cloneElement(el, {
                  className: props.childClassName,
                  style: { ...childStyle, transform: value, backgroundColor: bgColor },
                  ...listeners,
                  ...animationHandler,
                  tabIndex: -1,
              });
          })
        : [];

    return (
        <StyledOrbitRing
            className={`orbit-ring ${props.className || " "}`}
            style={parentStyle}
            isInnerMoon={props.isInnerMoon}
            hoveredCategory={props.hoveredCategory}
            ourCategory={props.category}
            hovered={hovered}
            startRotation={props.startRotation || ""}
            endRotation={props.endRotation || ""}
            index={props.index}
            itemSize={props.itemSize}
            onAnimationStart={(e) => _setInPosition(e, false)}
            onAnimationEnd={(e) => _setInPosition(e, true)}
        >
            {randomNumbers && placedChildren}
        </StyledOrbitRing>
    );
}

export default OrbitRing;
