import styled, { css } from "styled-components";
import { device } from "./styled-components/devices";

const zValues = {
    base: {
        value: 0,
        z: 4,
    },

    base1: {
        value: -20,
        z: 4,
    },

    base2: {
        value: -60,
        z: 4,
    },

    base3: {
        value: -160,
        z: 4,
    },
    fore: {
        value: 90,
        z: 5,
    },
    front: {
        value: 180,
        z: 6,
    },
    back: {
        value: -300,
        z: 3,
    },
    deep: {
        value: -600,
        z: 2,
    },
    deepest: {
        value: -900,
        z: 1,
    },
};
function returnLayerValues(layer = "", isWebkit = false) {
    const { value, z } = zValues[layer];
    //FIXME - not working on some Safari browsers, fix later
    // const scale = !isWebkit ? calculateScale(value) : 1;
    const scale = calculateScale(value);
    return `transform: translateZ(${value}px) scale(${scale});
            z-index: ${z}
    `;
}
// The scale factor can be calculated with 1 + (translateZ * -1) / perspective. For example, if our viewport perspective is set to 1px and we translate an element -2px along the Z axis the correction scale factor would be 3:
function calculateScale(transformZValue, perspectiveValue = 300) {
    return 1 + (transformZValue * -1) / perspectiveValue;
}
export const ParallaxGroup = styled.section`
    scroll-snap-align: top;
    z-index: ${(props) => props.z};
    overflow: visible;
    position: relative;
    ${(props) =>
        props.reverse &&
        css`
            --landing-section-height: var(--landing-section-height-alt);
        `};
    height: var(--landing-section-height);
    @media ${device.tablet} {
        height: 100vh;
    }
    transform-style: preserve-3d;
    ${(props) => props.parallaxDebugOn && `transform: translate3d(700px, 0, -800px) rotateY(30deg);`}
    height: ${(props) => props.pHeight === "full" && "100vh"};
`;
export const ParallaxLayer = styled.div`
    transform-origin-x: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    --translate-z: 0;
    ${(props) => returnLayerValues(props.layer, props.isWebkit)};
`;
export const ParallaxSection = styled.section`
    perspective-origin-x: 100%;
    --perspective: 300px;
    perspective: var(--perspective);
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;

`;

ParallaxGroup.displayName = "ParallaxGroup";
ParallaxSection.displayName = "ParallaxSection";
ParallaxLayer.displayName = "ParallaxLayer";
