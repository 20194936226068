import React, { useState } from "react";
import styled from "styled-components";
import { BlurButton } from "./styled-components/Buttons.Styled";
import { Container } from "./styled-components/layout.styled";
import { device } from "./styled-components/devices";

const StyledModal = styled(Container)`
    position: fixed;
    width: 100%;
    height: 100%;
    /* height: 100vh; */
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    @media ${device.tablet} {
        padding: 3rem;
    }
    --bg-translucent: hsla(240, 39%, 15%, 90%);
    background: linear-gradient(-45deg, var(--color-glassy-purple), var(--bg-translucent));
    /* backdrop-filter: blur(5px); */
    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    .modal__content {
        max-width: 800px;
        /* max-height: 100vh; */
        /* height: 75vh; */
        height: 100vh;
        padding: unset;
        @media (orientation: landscape) {
            padding: unset;
            width: 100%;
        }
        @media ${device.tablet} {
            width: 95%;
            height: 80vh;
            max-width: 1200px;
        }
    }
`;
export default function Modal(props) {
    const { modalHandler } = props;

    return (
        <StyledModal full={true}>
            <Container className="modal__content" full={true}>
                {props.children}
                <BlurButton className="close-button" onClick={(event) => modalHandler.setModalVisible(false, event)}>
                    Close
                </BlurButton>
            </Container>
        </StyledModal>
    );
}
