import React from "react";
import FadeElement from "./FadeElement";
import { useMediaQuery } from "react-responsive";
import styled, { keyframes } from "styled-components";
import { addPseudoElement } from "./styled-components/pseudo.styled";
import { device } from "./styled-components/devices";
import { helpers } from "./helpers/helpers";
import { ParallaxGroup, ParallaxLayer } from "./ParallaxGroup";
import { LensButton } from "./styled-components/Buttons.Styled";
import { ArrowDownwardRounded } from "@mui/icons-material";
import { GlowText, GlowTextShadow, DecorativeHeader } from "./styled-components/text.styled";

/* ---------------------------- Styled Components --------------------------- */
export const twinkleAnimation = keyframes`
    0%{
        opacity: 0%;
        /* transform: scale(0); */
        filter: blur(10px);
    }
    100%{
        opacity: 100%;
        /* transform: scale(1.35); */
        filter: blur(0px);
    }
`;

function calculateOffset(offset, mod = 1, mobile = false) {
    if (offset) {
        if (mobile) {
            offset = offset / 2 + helpers.randomBetween(1, 4);
        }
        let string = `translateX(${mod * (20 + offset)}%)`;
        return string;
    } else {
        return `
            translateX(${mod * 20}%)
        `;
    }
}
export const StyledTextPopout = styled(GlowTextShadow).attrs({
    style: ({ animationdelay, animationduration }) => ({
        animationDelay: animationdelay,
        animationDuration: animationduration,
    }),
})`
    opacity: 0%;
    margin: unset;
    &:nth-child(odd) {
        transform: ${(props) => calculateOffset(props.offset, 1, true)};
    }
    &:nth-child(even) {
        transform: ${(props) => calculateOffset(props.offset, -1, true)};
    }

    @media ${device.tablet} {
        &:nth-child(even) {
            transform: ${(props) => calculateOffset(props.offset, -1)};
        }
        &:nth-child(odd) {
            transform: ${(props) => calculateOffset(props.offset)};
        }
    }

    white-space: nowrap;
    animation-iteration-count: infinite;
    animation-name: ${twinkleAnimation};
    animation-direction: alternate;
    z-index: var(--z-low);
    font-size: 1.5vmax;
`;

const StyledHero = styled(ParallaxGroup)`
    pointer-events: none;
    --content-size: 50%;
    font-family: "AdineKirnberg-Script";
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* overflow: hidden; */
    background-size: cover;
    scroll-behavior: smooth;
    background-position: center;
    width: 100%;
    @media ${device.tablet} {
        justify-content: center;
        height: 100vh;
    }
    z-index: 1;

    .image-container {
        display: grid;
        place-items: center;
        border-radius: 50%;
        ${addPseudoElement("after", { circle: true, centered: true })};
        &:after {
            background-color: var(--color-glassy-purple);
            backdrop-filter: blur(2px);
            width: 50%;
            height: auto;
            z-index: 1;
        }
        img {
            width: 50%;
            height: auto;
            z-index: -1;
            opacity: 30%;
            transform: translateY(-15%);
            /* object-fit: 100% auto; */
        }
    }
    .text-container {
        display: grid;
        grid-template-rows: minmax(0, 1fr) minmax(0, 40%) minmax(0, 1fr);
        grid-template-columns: 1fr;
        align-items: stretch;
        justify-items: stretch;
        transition: opacity 200ms ease-out;

        max-height: 80%;

        @media ${device.tablet} {
            /* opacity: ${(props) => (props.isVisible ? "100%" : "0%")}; */
            height: 100%;
            grid-template-columns: 20% minmax(0, 60%) 20%;
            max-height: 60%;
            /* grid-template-columns: 25% 50% 25%; */
            grid-template-rows: minmax(0, 1fr);
        }
        .left-or-top {
            grid-row: 1/2;
            grid-column: 1/2;
            padding-left: 2rem;
        }
        .center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            grid-row: 2/3;
            grid-column: 1/2;
            h1 {
                position: relative;
            }

            > h1,
            > h2 {
                /* text-shadow: 0px 11px 10px #00000073; */
                margin-bottom: 1rem;
            }
            > * + * {
                margin-bottom: var(--flow-space, 1rem);
            }
            .title {
                font-size: 3.45rem;
                @media ${device.mobileL} {
                    font-size: 4.5rem;
                }
                @media ${device.tablet} {
                    font-size: max(5rem, 5vmax);
                }
                /* font-size: clamp(4rem, 2.4rem + 8vw, 12rem); */
                font-family: "Cinzel Decorative", cursive !important;
                font-style: italic;
                font-weight: 700;
                filter: hue-rotate(343deg);
                /* letter-spacing: 0.05ch; */
            }
            .header {
                margin-bottom: 2rem;
            }
            .subtitle {
                /* font-size: clamp(98px, 2rem - 1vw, 288px); */
                /* font-size: clamp(30px, 1.5rem - 1vw, 72px); */
                font-family: "Montserrat", sans-serif;
                font-weight: 200;
                font-style: italic;
                font-size: 1.45rem;
                @media ${device.mobileL} {
                    /* font-size: min(2.5vmax, 2rem); */
                    font-size: 1.75rem;
                }
                letter-spacing: 0.15ch;
            }
        }
        .right-or-bottom {
            /* grid-row: 1/2; */
            grid-row: 3/4;
            grid-column: 1/2;
        }
        .left-or-top,
        .right-or-bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .center,
        .left-or-top,
        .right-or-bottom {
            max-width: 100vw;
        }
        @media (max-height: 500px) and (max-width: 320px) {
            grid-template-rows: 100%; //minmax(0, 70%) minmax(0, 1fr) minmax(0, 1fr);
            .center {
                grid-row: 1/2;
                justify-content: start;
            }
            .left-or-top {
                display: none;
                grid-row: 2/3;
            }
            .right-or-bottom {
                display: none;
                grid-row: 3/4;
            }
        }

        @media ${device.tablet} {
            height: 100%;
            grid-template-columns: 20% minmax(0, 60%) 20%;

            max-height: 60%;
            /* grid-template-columns: 25% 50% 25%; */
            grid-template-rows: minmax(0, 1fr);
            .left-or-top {
                grid-column: 1/2;
                grid-row: 1/2;
                padding-left: 2rem;
            }
            .center {
                grid-column: 2/3;
                grid-row: 1/2;
            }
            .right-or-bottom {
                position: relative;
                grid-column: 3/4;
                grid-row: 1/2;
                padding-right: 2rem;
            }
            .left-or-top,
            .right-or-bottom {
                padding-bottom: 30px;
            }
        }
    }

    .title {
        background: linear-gradient(45deg, #647cff, #9a62c2);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: var(--z-higher);
    }

    .hero-content {
        display: grid;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70%;
        > h1,
        > h2 {
            margin-bottom: 1rem;
        }
        h1 {
            font-family: "Cinzel Decorative", cursive !important;
            /* font-family: "Cinzel Decorative", cursive; */
        }
        > * + * {
            margin-bottom: var(--flow-space, 1rem);
        }
        .title {
            font-size: 4rem;
            font-size: clamp(4rem, 2.4rem + 8vw, 12rem);
            backdrop-filter: blur(12px);
        }
        .subtitle {
            /* font-size: clamp(98px, 2rem - 1vw, 288px); */
            /* font-size: clamp(30px, 1.5rem - 1vw, 72px); */
            font-size: 2.5vmax;
        }
    }
    .button-container {
        pointer-events: none;
        .scroll-button {
            bottom: 10%;
            @media ${device.tablet} {
                bottom: 10%;
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            pointer-events: auto;
            width: clamp(5rem, calc(5rem + 4vw), 6rem);
            height: clamp(5rem, calc(5rem + 4vw), 6rem);
            @media ${device.tablet} {
                @supports (clamp()) {
                    width: clamp(6rem, calc(6rem + 2vw), 7rem);
                    height: clamp(6rem, calc(6rem + 2vw), 7rem);
                }
            }

            /* height: 10vw; */
            pointer-events: auto;
            color: var(--color-primary-text);
            position: absolute;
            left: 50%;
            transform: translateX(-50%) scale(1);
            transition: transform 200ms ease-in;
            z-index: 1;
            &:hover {
                transform: translateX(-50%) scale(1.25);
                color: var(--glassy-lilac-bright);
                border-color: var(--glassy-lilac-bright);
                /* color: var(--color-primary-bg); */
                &:after {
                    opacity: 20%;

                    border-color: 1px solid var(--glassy-lilac-bright);
                }
                &:before {
                    opacity: 50%;
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
            ${addPseudoElement("before", { centered: true })};
            &:before {
                background-image: url(/illustrations/CelestialButtonFrame.webp),
                    radial-gradient(circle at 50%, #5a78ff, #d880ab6a);
                /* mix-blend-mode: color-dodge; */
                border-color: 1px solid var(--glassy-lilac-bright);

                box-shadow: 0px 0px 40px 15px #5a78ff;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%) scale(0.5);
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                transform-origin: center;
                transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
                z-index: -1;
                opacity: 10%;
            }
            ${addPseudoElement("after")};
            &:after {
                background-image: radial-gradient(
                    circle at 50%,
                    var(--glassy-lilac-bright),
                    var(--glassy-lilac-bright)
                );
                background-size: cover;

                background-position: center;
                background-image: none;
                opacity: 90%;
                transition: opacity 200ms ease-in-out;

                border: inherit;
                filter: blur(2px);
                border-radius: inherit;
                z-index: -2;
            }

            svg {
                position: absolute;
                width: 50%;
                height: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1);
                transition: transform 200ms ease-out;
            }
            span {
                /* height: 0%; */
                transform: scale(0);
                transform-origin: center;
                transition: transform 200ms ease-out;
            }
            &:hover {
                span {
                    transform: scale(1);
                    /* height: 40%; */
                    /* height: 3rem; */
                }
                svg {
                    transform: translate(-50%, 50%) scale(0.75);
                    color: var(--glassy-lilac-bright);
                    /* transform: translateY(100%); */
                }
            }
        }
    }
    .cloud-wrapper {
        display: grid;
        height: 100vh;
        width: 100vw;
        pointer-events: none;
    }
    .cloud-container {
        grid-column: 1/2;
        grid-row: 1/2;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: url("/illustrations/cloud-night-sky.webp");
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center bottom;
        border-bottom: 1px solid var(--color-primary-bg);

        bottom: 100%;
        height: 100%;
        left: 0;
        width: 100%;
        &:not(.bg, .fg) {
            z-index: 1;
        }

        &.bg {
            background-image: url("/illustrations/cloud-bg.webp");
            z-index: -1;
            ${"" /* background-position: center bottom -30%; */}
            ${"" /* transform: translateZ(-300px) translateY(-10%) scale(2); */}

        transition: opacity 200ms ease-out;
            @media ${device.tablet} {
                opacity: ${(props) => (props.isVisible ? "100%" : "0%")};
            }
        }
        &.fg {
            z-index: 2;
            background-image: url("/illustrations/cloud-fg.webp");
            ${"" /* background-image: none; */}

            ${"" /* background-position: center bottom; */}
       ${"" /* z-index: 100; */}
        }
    }

    /*Vignette effect*/
    &:before {
        position: absolute;
        /* content: ""; */
        top: 0;
        left: 0;
        background-image: url("/background-textures/VingetteAlone.webp");
        opacity: 69%;
        /* opacity: 9%; */
        /* backdrop-filter: blur(2px); */
        width: 100%;
        height: 100%;
        z-index: -2;
    }

    h3 {
        font-weight: lighter;
    }
`;
StyledHero.displayName = "StyledHeroSection";
const DecorDiv = styled.div`
    filter: blur(2px);
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
DecorDiv.displayName = "DecorDiv";
// #endregion

const HeroSection = React.forwardRef((props, ref) => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const isPhone = useMediaQuery({ query: "(max-width: 375px)" });

    let popoutText = [
        "Writer",
        "Gamer",
        "Dreamer",
        "Hobbyist Game Designer",
        "Worldbuilder",
        "Problem Solver",
        "Game Master",
        "Storyteller",
    ];
    const offsets = {
        Writer: -10,
        Dreamer: 25,
        "Hobbyist Game Designer": -10,
        "Game Master": !isMobile ? -10 : 10,
        "Problem Solver": !isMobile ? 25 : 10,
        Storyteller: !isMobile ? 0 : 10,
    };
    //20, 95
    let positions = [
        {
            //writer
            top: 50,
            left: 70,
        },
        {
            //gamer
            top: 30,
            left: 85,
        },
        {
            //dreamer
            top: 10,
            left: 15,
        },
        {
            //game-master
            top: 40,
            left: 25,
        },
        {
            // split
            //worldbuilder
            top: 5,
            left: 55,
        },
        {
            //problem solver
            top: 25,
            left: 24,
        },
        {
            //hobbyist game designer
            top: 20,
            left: 75,
        },
        {
            top: 60,
            left: 26,
        },
    ];

    const popoutElements = popoutText.map((el, index) => (
        <StyledTextPopout
            key={index + "popoutText"}
            className="popout constellation"
            offset={offsets[el] ? offsets[el] : ""}
            style={{
                top: positions[index].top + (isMobile ? 35 : 0) + "%",
                left: positions[index].left + "%",
                animationDuration: 4 + Math.random() + "s",
                animationDelay: 1 + Math.random() + "s",
            }}
        >
            {el}
        </StyledTextPopout>
    ));
    const { firstHalf, secondHalf } = helpers.halfArray(popoutElements);
    const title = "I'm Maya";

    return (
        <StyledHero
            z="1"
            pHeight="full"
            className="parallax__group--full"
            isVisible={props.isVisible}
            id="hero-section"
        >
            <ParallaxLayer layer="back" className="text-container" isWebkit={props.isWebkit}>
                <div className="left-or-top">{isPhone ? <></> : firstHalf}</div>
                <div className="center">
                    {/* <h2 className="subtitle">{prefix}</h2> */}
                    <DecorativeHeader className="header" headerImg="frames/headers/slice3">
                        <GlowText className="title" data-key="title" ref={ref} content={title} blurValue={3}>
                            {title}
                        </GlowText>
                    </DecorativeHeader>
                    {/* <h1 className="title" data-key="title" ref={ref}>
                    </h1> */}

                    <h2 className="subtitle">A web developer.</h2>
                </div>
                <div className="right-or-bottom">
                    {isPhone ? <FadeElement list={popoutText}></FadeElement> : secondHalf}
                </div>
            </ParallaxLayer>
            {/* <div className="parallax__layer parallax__layer--deep image-container">
                <img src="/GalaxySwirl.webp" alt="" />
            </div> */}
            {/* <ParallaxLayer layer="base3" className="cloud-container bg "></ParallaxLayer> */}
            <ParallaxLayer layer="base1" isWebkit={props.isWebkit} className="cloud-container"></ParallaxLayer>
            <ParallaxLayer layer="base1" isWebkit={props.isWebkit} className="button-container">
                <LensButton className="scroll-button" onClick={props.handleScroll}>
                    <span>Dive Into My World</span>
                    <ArrowDownwardRounded></ArrowDownwardRounded>
                </LensButton>
            </ParallaxLayer>
            <ParallaxLayer layer="base" isWebkit={props.isWebkit} className="cloud-container fg "></ParallaxLayer>

            {props.children}
        </StyledHero>
    );
});

export default HeroSection;
