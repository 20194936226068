import { debounce } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useMediaQuery } from "react-responsive";
import { CSSTransition } from "react-transition-group";
import styled, { css, keyframes } from "styled-components";

/**
 *
 * https://blog.logrocket.com/learn-react-portals-example/
 * https://codesandbox.io/s/github/supromikali/react-portals-example?file=/src/TooltipPopover.js
 */

export function Portal(props) {
    const mount = document.getElementById("portal-root");
    const el = document.createElement("div");

    useEffect(() => {
        mount.appendChild(el);
        return () => mount.removeChild(el);
    }, [el, mount]);

    return createPortal(props.children, el);
}

const StyledOverflowText = styled.div`
    img {
        filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`} !important;
    }
    p {
        .fade-enter {
            opacity: 0;
            &.fade-enter-active {
                opacity: 1;
                transition: opacity 3s;
            }
        }
        .fade-enter-done {
        }
        .fade-exit {
            opacity: 1;
            &.fade-exit-active {
                opacity: 0;
                transition: opacity 3s;
            }
        }
        .fade-exit-done {
            opacity: 0%;
        }
    }
`;
const fadeIn = keyframes`
    0%{
        opacity: 0%;
    }
    100%{
        opacity: 100%;
    }
`;
const fadeOut = keyframes`
    0%{
        opacity: 100%;
    }
    100%{
        opacity: 0%;
    }

`;
const StyledTooltipPopover = styled.div`
    p {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: var(--color-secondary-text-light);
        opacity: 0%;
        ${(props) =>
            props.active
                ? css`
                      animation-name: ${fadeIn};
                      animation-duration: 150ms;
                      /* animation-delay: 3000ms; */
                      animation-fill-mode: both;
                  `
                : css`
                      animation-name: ${fadeOut};
                      animation-duration: 3000ms;
                  `};
    }
`;

export function OverflowText(props) {
    const [isOn, setIsOn] = useState(false);
    const [coords, setCoords] = useState({});

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const ref = useRef(null);

    useEffect(() => {
        if (props.inposition) {
            const rect = ref.current.getBoundingClientRect();
            // setTimeout(() => {
            //     setCoords({
            //         left: rect.x + rect.width / 2,
            //         top: rect.y + window.scrollY
            //     })
            // }, 3000);
            setCoords({
                left: rect.x + rect.width / 2,
                top: rect.y + window.scrollY,
            });
        }
    }, [props.inposition]);

    useEffect(() => {
        if (props.inposition && props.hoveredCategory === props.cat) {
            // updateTooltipCoords()
            setIsOn(true);
        } else {
            setIsOn(false);
        }
    }, [props.inposition, props.hoveredCategory, props.cat]);

    function updateTooltipCoords() {
        const rect = ref.current.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2,
            top: rect.y + window.scrollY,
        });
    }
    const anchorElement = React.cloneElement(props.children[0], { ref: ref });
    return (
        <StyledOverflowText
            className={props.className}
            style={{ ...props.style }}
            data-interactive={props.interactive}
            hueRotate={props.hueRotate}
        >
            {anchorElement}
            {isOn && props.interactive && !isMobile && (
                <Portal>
                    <TooltipPopover
                        coords={coords}
                        active={isOn}
                        updateTooltipCoords={() => updateTooltipCoords(ref.current)}
                    >
                        <p>{props.text || " "}</p>
                    </TooltipPopover>
                </Portal>
            )}
        </StyledOverflowText>
    );
}

const TooltipPopover = ({ children, coords, updateTooltipCoords, active }) => {
    const updateCoords = debounce(updateTooltipCoords, 100);
    useEffect(() => {
        // window.addEventListener("resize", updateCoords)
        window.addEventListener("resize", updateCoords);
        return () => window.removeEventListener("resize", updateCoords);
    }, []);

    return (
        <StyledTooltipPopover
            style={{
                ...coords,
                position: "absolute",
                color: "white",
                fontWeight: "bold",
                zIndex: 1000,
                transform: "translate(-50%, -50%)",
            }}
            active={active}
        >
            {children}
        </StyledTooltipPopover>
    );
};
