import { addPseudoElement } from "./pseudo.styled";
import styled from "styled-components";
import { helpers } from "../helpers/helpers";
import React from "react";

export const GlowTextShadow = styled.p`
    text-shadow: 0px 0px 3px currentColor;
`;
export const GlowText = styled.h1`
    background: linear-gradient(45deg, #ecff5a, #9a62c2);
    ${addPseudoElement("before")};
    &:before {
        content: ${(props) => `"${props.content}"`};
        background-clip: inherit;
        background-image: inherit;
        width: 100%;
        height: 100%;
        font-size: inherit;
        filter: ${(props) => helpers.cssFunctionWrap("blur", props.blurValue || 4, "px")};
    }
`;

GlowText.displayName = "GlowText";

export const DecorHeader = styled.div`
    display: grid;

    grid-template-rows: 4rem 1fr 4rem;
    /* grid-template-rows: 4rem 12rem 4rem; */
    width: 100%;
    /* overflow: hidden; */
    align-items: center;
    justify-items: center;
    justify-content: center;
    max-height: 30vh;
    img {
        mask-image: linear-gradient(
            to right,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.75) 15%,
            hsla(0, 0%, 0%, 1) 48%,
            hsla(0, 0%, 0%, 0.75) 85%,
            hsla(0, 0%, 0%, 0) 100%
        );
        width: 100%;
        height: 100%;
        opacity: 60%;
        object-fit: contain;
        object-position: center;
        @media (max-width: 320px) and (max-height: 500px) {
            display: none;
        }
    }
    img:first-child {
        /* object-position: top; */
        grid-row: 1/2;
        grid-column: 1/2;
        transform: translateY(25%);
    }
    h1 {
        grid-row: 2/3;
        grid-column: 1/2;
        max-height: 100%;
        margin: unset;
        padding: unset;
    }
    img:last-child {
        /* object-position: bottom; */
        grid-row: 3/3;
        grid-column: 1/2;
        transform: translateY(-25%);
    }
`;
export function DecorativeHeader(props) {
    const img = props.headerImg || "frames/headers/slice3";
    const ext = props.ext || ".png";
    return (
        <DecorHeader className={props.className}>
            <img src={img + "-top" + ext} alt="decorative header border" />
            {props.children}
            <img src={img + "-bottom" + ext} alt="decorative header border" />
        </DecorHeader>
    );
}
