import styled, { css } from "styled-components";

const backgroundColor = (props) => {
    return props.bgColor || "var(--clr-accent)";
};
const backgroundColorAlt = (props) => {
    return props.bgColorAlt || "var(--clr-accent-green)";
};
const color = (props) => {
    return props.color || "var(--clr-primary-text)";
};

const colorAlt = (props) => {
    return props.colorAlt || "white";
};
export const StyledButtonIconSpan = styled.span.attrs(({ className }) => ({
    className: className || "material-icons",
}))`
    font-weight: 200;
`;
export const StyledButton = styled.button`
    font-family: "Raleway", sans-serif;
    font-weight: 400;

    --clr-background-color: transparent;
    --clr-bg-hover-color: transparent;
    --clr-text-color: white;
    --clr-text-hover-color: ${colorAlt};
    color: var(--clr-text-color);
    display: flex;
    align-items: center;
    ${(props) => {
        if (props.btnStyle === "contained") {
            return `
    			transition: background 0.15s linear, color 0.15s linear;
				&:hover{
					background: var(--clr-bg-hover-color);
					color: var(--clr-text-hover-color);
				}
			`;
        } else if (props.btnStyle === "outlined") {
            //fill with background color?
            return `
				transition: background 0.15s linear, color 0.15s linear;
				&:hover{
					background: var(--clr-bg-hover-color);
					color: var(--clr-text-hover-color);
				}
			`;
        } else {
            return `
				transition: color 0.15s linear;
				&:hover{
					color: var(--clr-text-hover-color);
					> * {
						transition: color 0.15s linear;
						color: var(--clr-text-hover-color);
					}
				}
			`;
        }
    }}
    cursor: pointer;
`;
StyledButton.displayName = "StyledButton";

/** small buttons with no background or border, like close buttons on modals or chips, etc. */
export const CustomIconButton = styled(StyledButton).attrs(({ className }) => ({
    className: className || "material-icons",
}))`
    background: transparent;
    border: none;
    color: var(--clr-text-color);
`;
CustomIconButton.displayName = "IconButton";

export const CircleIconButton = styled(CustomIconButton)`
    background: var(--clr-background-color);
    border: none;
    color: var(--clr-text-color);
    border-radius: 50%;
    padding: 0.5em;
    /* width: 2rem; */
    /* height: 2rem; */
`;
CircleIconButton.displayName = "CircleIconButton";
export const ContainedButton = styled(StyledButton)`
    background: var(--clr-background-color);
    border: none;
    color: var(--clr-text-color);
    padding: 0.55em 1em;
    border-radius: 5px;
    font-weight: 400;
    > span {
        font-weight: 400;
    }
`;
ContainedButton.displayName = "ContainedButton";
export const BlurButton = styled(StyledButton)`
    font-family: "Raleway", sans-serif;
    border-radius: 999px;
    letter-spacing: 0.25ch;
    backdrop-filter: blur(12px);
    &,
    & span {
        font-weight: 400;
    }
    border: 1px double var(--color-primary-text);
    background-color: transparent;
    padding: 0.5em 1em;
    color: var(--color-secondary-text);
    transform: scale(1);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: color 200ms ease-out;
    &:hover {
        color: var(--color-primary-text);
        transition: color 200ms ease-out;
        ${"" /* transform: scale(1.25); */}
    }
`;
BlurButton.displayName = "BlurButton";

export const DotButton = styled.button`
    cursor: pointer;
    padding: unset;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    color: #ffffff84;
`;
export const LensButton = styled(BlurButton)`
    padding: unset;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    backdrop-filter: blur(12px);
    transform: scale(1);
    transition: color 200ms ease-in-out;
    display: grid;
    place-content: center;
    span {
        font-weight: 400;
    }
    &:hover {
        color: var(--color-primary-text);
        ${"" /* transform: scale(1.25); */}
    }
`;
LensButton.displayName = "LensButton";
export const MaskedButton = styled(ContainedButton)`
    background-color: transparent;
    background-image: linear-gradient(to right, var(--clr-gradient-cool));
    overflow: visible;
    border-radius: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    overflow: hidden;
    position: relative;
    color: #110c20;
    border: 10px double;
    transition: all 0.2s ease-in;
    z-index: 3;
    border-image-slice: 30%;
    border-width: 2px;
    /* border-image-width: 3px; */
    border-image-outset: 1px;
    border-image-repeat: stretch;

    border-image-source: -webkit-linear-gradient(45deg, var(--clr-gradient-cool));
    border-image-source: linear-gradient(45deg, var(--clr-gradient-cool));
    > span {
        z-index: 5;
    }
    &:before {
        z-index: 120;
        position: absolute;
        content: "";
        background: transparent;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px double;
        /* border-image-source: -webkit-linear-gradient( */
        /* to right, */
        /* var(--clr-gradient-cool) */
        /* ); */
        border-image-source: linear-gradient(to right, #a770ef, #cf8bf3, #fdb99b);
        border-image-slice: 30%;
        border-width: 2px;
        border-image-outset: 8px;
        border-image-repeat: stretch;
        /* clip-path: 0% 50% 50% 50% 50% 0% 0% 0%; */
        /* opacity: 0%; */
    }

    &:after {
        z-index: 1;
        position: absolute;
        content: "";
        background-color: rgba(6, 0, 70, 0.45);
        top: 0;
        left: 0;
        width: 400%;
        height: 400%;
        transform: translatex(-100%) rotatez(-45deg);
        transform-origin: left left;
        transition: transform 0.25s ease-in;
    }
    &:hover {
        cursor: pointer;
        color: white !important;
        background-position: right center;
        &:after {
            transform: translatex(-50%) rotatez(-45deg);
        }
    }
    /* mask-border-source: url("ButtonMask.png");
    /* mask-size: 100% 100%; */
    /* mask-border-slice: 1; */
    /* mask-repeat: no-repeat; */
    /* mask-origin: border-box; */
    /* mask-position: center center; */
`;
export const OutlinedButton = styled(ContainedButton)`
    background: transparent;
    border: 1px solid var(--clr-accent);
    color: var(--clr-accent);
`;

OutlinedButton.displayName = "OutlinedButton";

export const TextButton = styled(OutlinedButton)`
    border: none;
    display: flex;
    justify-content: space-evenly;
    border-radius: 0;

    color: var(--glassy-lilac-light);
    border-color: var(--glassy-lilac-bright);
    -webkit-transition: color 200ms ease-out, border-color 200ms ease-out;
    transition: color 200ms ease-out, border-color 200ms ease-out;
    &:hover {
        border-bottom: 1px solid var(--color-primary-text);
        color: var(--glassy-lilac-light);
        border-color: var(--glassy-lilac-bright);
        transition: color 200ms ease-out, border-color 200ms ease-out;
    }
`;

TextButton.displayName = "TextButton";
export const FloatingActionButton = styled(StyledButton)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`;
FloatingActionButton.displayName = "FloatingActionButton";
export const ToggleButton = styled(StyledButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    --clr-text-color: ${(props) => props.color};
`;

ToggleButton.displayName = "ToggleButton";
export const ButtonGroup = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
ButtonGroup.displayName = "ButtonGroup";

export const ToggleButtonGroup = styled(ButtonGroup)`
    gap: 0;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
ToggleButtonGroup.displayName = "ToggleButtonGroup";
export const StyledSplitButtonWrapper = styled.span`
    border-radius: 10px;
    display: inline-flex;
    gap: 0.1rem;
`;
StyledSplitButtonWrapper.displayName = "StyledSplitButtonWrapper";
export const StyledSplitButtonPrimary = styled(ContainedButton).attrs((props) => ({
    className: props.className || "material-icons",
}))`
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    flex: 2;
    padding: 0.35rem 0.65rem;
`;
StyledSplitButtonPrimary.displayName = "StyledSplitButtonPrimary";
export const StyledSplitButtonOverflow = styled(ContainedButton).attrs((props) => ({
    className: props.className || "material-icons",
}))`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    flex: 1;
    padding: 0;
    /* font-size: 1rem; */
`;
StyledSplitButtonOverflow.displayName = "StyledSplitButtonOverflow";
export const TabButton = styled(OutlinedButton)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: var(--clr-primary-base);
    border-bottom-color: transparent;
    ${({ active }) =>
        active
            ? css`
                  background-color: var(--clr-primary-base);
              `
            : css`
                  background-color: transparent;
              `};
`;
TabButton.displayName = "TabButton";
export const StyledSplitButtonDropdown = styled.div``;

StyledSplitButtonDropdown.displayName = "ButtonDropdown";

export const ImageButton = styled(LensButton)`
    position: relative;
    height: 4vmax;
    width: 4vmax;
    aspect-ratio: 1/1;

    border-radius: 50%;

    backdrop-filter: unset;
    overflow: hidden;

    pointer-events: auto;
    overflow: hidden;
    cursor: pointer;

    opacity: 80%;
    background-color: hsla(240, 100%, 72%, 0.206);
    border: 1px solid var(--color-primary-text);
    box-shadow: #130f1f8a 0px 3px 8px;

    transform: scale(0.75);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    transition: transform 400ms ease-in-out, opacity 200ms ease-in-out;
    > * {
        pointer-events: none;
    }

    &.active {
        opacity: 100%;
        transform: scale(1);
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    }
    &:hover {
        transform: scale(0.9);
        opacity: 80%;
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    }
    img,
    video {
        max-width: 200%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 6px;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
`;
ImageButton.displayName = "ImageButton";
export const Button = ({ secondary, big, outlined, children, icon, ...props }) => {
    return (
        <StyledButton secondary={secondary} big={big} outlined={outlined} {...props}>
            {icon && <StyledButtonIconSpan>{icon}</StyledButtonIconSpan>}
            {children}
        </StyledButton>
    );
};
