import React, { createRef, useEffect, useRef, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled, { css, keyframes } from "styled-components";
import { helpers } from "./helpers/helpers";

/* ----------------------------------------------
 * Generated by Animista on 2023-1-1 16:21:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
const panDuration = 15000;
const scaleValue = 1.15;
const startValue = "25%";

const kenburnsTop = keyframes`
  0% {
    transform: scale(${scaleValue}) translate(${startValue}, 0%);
  }
  100% {
    transform: scale(1) translate(-30%, 10%);
  }
`;

const StyledSlideshow = styled.div`
    grid-column: 1/2;
    grid-row: 1/2;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .my-slide-enter {
        opacity: 0;
        @media (prefers-reduced-motion: no-preference) {
            transform: scale(${scaleValue}) translate(${startValue}, 0);
        }
        &.my-slide-enter-active {
            opacity: 1;
            transition: opacity 900ms;
        }
    }
    .my-slide-enter-done {
        @media (prefers-reduced-motion: no-preference) {
            animation: ${kenburnsTop} ${panDuration}ms cubic-bezier(0.37, 0, 0.63, 1) both;
        }
    }
    .my-slide-exit {
        opacity: 1;
        &.my-slide-exit-active {
            opacity: 0;
            transition: opacity 900ms;
        }
    }
    .my-slide-exit-done {
        opacity: 0%;
    }
    > div {
        height: 100%;
        width: 100%;
    }
    img {
        transform-origin: top left;
        object-fit: cover;
        pointer-events: auto;
        height: 100%;
        width: 100%;
    }
`;

function Slideshow(props) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = props.images.map((img, index) => {
        return {
            id: index + "slide",
            index: index,
            img: img,
            alt: helpers.fileNameToAltText(img),
        };
    });
    const imageElements = images.map(({ id, img, index, alt }) => {
        return (
            <CSSTransition key={id} timeout={500} classNames="my-slide">
                <img data-index={index} src={`/project demo images/${img}`} alt={alt}></img>
            </CSSTransition>
        );
    });
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImageIndex((prev) => (prev + 1 >= props.images.length ? 0 : prev + 1));
        }, panDuration);
        return () => {
            clearInterval(timer);
        };
    }, [props.images.length]);

    return (
        <StyledSlideshow data-index={props.index} className="slideshow">
            <TransitionGroup>{imageElements[currentImageIndex]}</TransitionGroup>
        </StyledSlideshow>
    );
}

export default Slideshow;
