import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { device } from "./styled-components/devices";
import styled, { css } from "styled-components";
import Flex from "./styled-components/flex.styled";
import { StyledNavLink } from "./styled-components/nav.styled";
import { TextButton, ContainedButton } from "./styled-components/Buttons.Styled";
import FontIcon from "./FontIcon";
import Socials from "./Socials";

const StyledNav = styled.nav`
    --mobile-padding-top: 2vmax;
    --mobile-padding-left: 2vmax;
    --hamburger-size: min(6vmax, 60px);
    --hamburger-padding: 2.5vmax;
    --menu-padding-top: calc(var(--mobile-padding-top) + var(--hamburger-size) + var(--hamburger-padding));
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: var(--z-top);
    pointer-events: none;
    .nav-toggle {
        display: grid;
        place-content: center;
        pointer-events: auto;
        position: fixed !important;
        top: var(--mobile-padding-top);
        left: 2vmax;
        z-index: var(--z-highest);
        width: min(6vmax, 60px);
        height: min(6vmax, 60px);

        padding: 2.5vmax;
        /* width: 2rem; */
        /* height: 2rem; */
        color: var(--color-secondary-text);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        margin: unset;

        border-radius: 50%;
        background-color: ${(props) =>
        !props.navVisible ? "var(--color-primary-bg-500)" : "var(--color-primary-text)"};

        color: ${(props) => (!props.navVisible ? "var(--color-secondary-text)" : "var(--color-primary-bg)")};
        /* backdrop-filter: blur(12px); */
        @media ${device.laptop} {
            display: none;
        }
    }

    ul {
        pointer-events: auto;
        position: fixed;
        display: flex;
        padding: unset;
        margin: unset;
        transition: transform 200ms ease-in;

        li {
            list-style-type: none;
            margin: unset;
            padding: unset;
            button,
            a {
                font-size: 2vmax;
                @media ${device.tablet} {
                    font-size: 1.75vmax;
                }
                @media ${device.laptop}{
                    font-size: 1.45vmax;
                }
                @media ${device.laptopL}{
                    font-size: 1.25vmax;
                }
                font-weight: 400;
                letter-spacing: 0.25ch;
            }
        }
    }
    ul.mobile {
        right: 100%;
        top: 0;
        width: 75vw;

        @media ${device.tablet} {
            width: 45vw;
        }
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        padding: var(--menu-padding-top) var(--mobile-padding-left);
        /* padding-block: 3rem; */
        /* baddin */
        backdrop-filter: blur(10px);
        background-color: hsla(239, 100%, 25%, 0.048);
        ${TextButton}{
            backdrop-filter: unset;
        }
        ${(props) =>
        props.navVisible &&
        css`
                transform: translateX(100%);
            `};
        @media ${device.laptop} {
            display: none;
        }
    }
    ul.desktop {
        flex-wrap: nowrap;
        /* justify-content: space-evenly; */
        justify-content: flex-start;
        gap: 2vmax;
        flex-direction: row;
        width: 100%;
        max-width: unset;
        max-height: 10vh;
        background: none;
        backdrop-filter: unset;
        display: none;
        pointer-events: none;

        li {
            width: fit-content;
            pointer-events: auto;
            /* &:first-child {
                margin-left: auto;
            }

            &:last-child {
                margin-right: auto;
            } */
        }
        @media ${device.laptop} {
            display: flex;
        }
    }

    ${TextButton} {
        border: none;
        pointer-events: auto;
        backdrop-filter: blur(12px);
        color: var(--glassy-lilac-light);
        transition: color 200ms ease-out;
        &:hover {
            color: var(--color-primary-text);
            transition: color 200ms ease-out;
        }

        &.${(props) => props.viewMode} {
            border-radius: 0;
            color: var(--color-primary-text);
            border-bottom: 1px solid var(--color-primary-text);
            transition: color 200ms ease-out, border-color 200ms ease-out;
            &:hover {
                color: var(--glassy-lilac-light);
                border-color: var(--glassy-lilac-bright);
                transition: color 200ms ease-out, border-color 200ms ease-out;
            }
        }
    }

    @media ${device.laptop} {
        .nav-toggle {
            display: none;
        }
        align-items: center;
        background: none;
        backdrop-filter: unset;
        /* border-bottom: 1px dashed var(--color-primary-text); */
        flex-direction: row;
        height: 3rem;
        justify-content: center;
        min-height: 5vh;
        padding: 0.5rem clamp(1rem, 1rem + 2vw, 2rem);
        position: fixed;
        width: 100%;
        z-index: var(--z-highest);
    }
`;

function NavBar(props) {
    const [navVisible, setNavVisible] = useState(false);
    useEffect(() => {
        setNavVisible(false);
    }, [props.viewMode]);

    const navItems =
        props.children &&
        props.children.map((child, index) => <li key={index + "navListItem"}>{React.cloneElement(child)}</li>);
    return (
        <StyledNav viewMode={props.viewMode} navVisible={navVisible}>
            <ContainedButton className="nav-toggle" onClick={() => setNavVisible(!navVisible)}>
                {navVisible ? <CloseIcon fontSize="small"></CloseIcon> : <MenuIcon fontSize="medium"></MenuIcon>}
            </ContainedButton>
            <ul className="desktop">{navItems}</ul>
            <ul className="mobile">{navItems}
                <li>
                    <Socials></Socials>
                </li>
            </ul>
        </StyledNav>
    );
}

export default NavBar;
