import React, { useState, useCallback } from "react";
import Particles from "react-tsparticles";
import styled from "styled-components";
import { loadFull } from "tsparticles";
import { device } from "./styled-components/devices";
const StyledStarParticles = styled.section`
    pointer-events: none;
    z-index: var(--z-highest);
    #tsparticles {
        z-index: var(--z-highest);
        &,
        & > canvas {
            pointer-events: none !important;
        }
    }
`;

function StarParticles(props) {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);
    const particleOptions = {
        background: {
            color: "transparent",
        },
        detectRetina: true,
        fpsLimit: 30,
        // interactivity: {
        //     detectsOn: "canvas",
        //     events: {
        //         resize: true,
        //     },
        // },
        particles: {
            color: {
                value: ["#ffe4b3", "#ffe4b3", props.accentColor || "#7e70ffff"],
            },
            number: {
                density: {
                    enable: true,
                    area: 1080,
                },
                limit: 0,
                value: 400,
            },
            opacity: {
                animation: {
                    enable: true,
                    minimumValue: 0.05,
                    speed: 0.25,
                    sync: false,
                },
                random: {
                    enable: true,
                    minimumValue: 0.05,
                },
                value: 1,
            },
            shape: {
                type: "circle",
            },
            size: {
                random: {
                    enable: true,
                    minimumValue: 0.5,
                },
                value: 1,
            },
            move: {
                enable: true,
                gravity: {
                    enable: false,
                },
                speed: 0.05,
                direction: "none",
                random: false,
                straight: false,
                // outMode: "destroy",
            },
        },
    };

    const particlesLoaded = useCallback(async (container) => {}, []);
    return (
        <StyledStarParticles>
            <Particles
                id="tsparticles"
                url=""
                init={particlesInit}
                loaded={particlesLoaded}
                options={particleOptions}
            />
        </StyledStarParticles>
    );
}

export default StarParticles;
