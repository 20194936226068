import React, { useState, useCallback } from "react";
import HelpButton from "./HelpButton";
import StarParticles from "./StarParticles";
import GalaxySection from "./GalaxySection";
// import { twinkleAnimation, StyledTextPopout } from "./HeroSection";

import styled, { css, keyframes } from "styled-components";

import { device } from "./styled-components/devices";

let offsets = fillAndShuffleOffsets();

const ripple = keyframes`
from{
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
}
to{
    opacity: 0;
    transform: translate(-50%, -50%) scale(3);
}

`;

const StyledSkillsSection = styled.section`
    display: grid;
    /* grid-template-columns: 100%; */
    /* grid-template-rows: 20vh 80vh; */
    overflow: hidden;

    display: flex;
    align-items: center;
    @media ${device.tablet} {
        display: flex;
        align-items: center;
    }

    margin: auto;
    z-index: var(--z-low);
    #tsparticles {
        z-index: var(--z-highest);
        &,
        & > canvas {
            pointer-events: none !important;
        }
    }

    .waves-div {
        opacity: 70%;
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        /* background-image: url(/WavesVertical.webp); */
        background-image: url(/FishCutout-waves-vertical.webp);
        background-position: center;
        @media ${device.tablet}, (orientation: landscape) {
            background-position: top 25% right;
            background-image: url(/FishCutout-waves.webp);
        }
        /* linear-gradient(to left, var(--color-glassy-teal), var(--color-glassy-teal)); */
        background-size: cover;
        background-repeat: no-repeat;
        filter: hue-rotate(30deg);
    }
    .reflection-div {
        position: absolute;
        top: 2rem;
        width: 100%;
        height: 100%;
        background-image: url(/PapercutFish-vertical.webp);
        background-position: center;
        @media ${device.tablet}, (orientation: landscape) {
            background-image: url(/PapercutFish.webp);
            background-position: top 25% right;
            left: -0.5rem;
        }
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
        opacity: 30%;
        filter: blur(1px) grayscale(20%);
        pointer-events: none;
    }
    .water-div,
    .stars-div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-size: 100% auto; */
        opacity: 20%;
        background-size: 30% 35%;
        background-repeat: repeat;
    }
    .water-div {
        background-image: url(/background-textures/water-texture.webp);
        z-index: -1;
        filter: blur(1px);
        mask-size: cover;
        mask-image: linear-gradient(to top, transparent 40%, black);
        @media ${device.tablet}, (orientation: landscape) {
            mask-image: linear-gradient(48deg, transparent 40%, black);
        }
    }
    .stars-div {
        opacity: 20%;
        background-image: url(/background-textures/purplenebula.webp);
        z-index: -1;
        filter: blur(1px);
        mask-size: cover;
        mask-image: linear-gradient(to bottom, transparent 40%, black);
        @media ${device.tablet} {
            mask-image: linear-gradient(232deg, transparent 60%, black);
            -webkit-mask-image: linear-gradient(232deg, transparent 60%, black);
        }
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
    }
    position: relative;
    height: 100vh;
    &:after {
        pointer-events: none;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        background-image: radial-gradient(
            circle at 50%,
            var(--color-primary-bg-600) 10%,
            var(--color-glassy-purple) 15%,
            var(--color-primary-bg-700) 31%,
            var(--color-primary-bg) 81%
        );
        /* background-image: url(/FishVertical.webp); */
        /* background-image: url(/PapercutFish-vertical.webp); */
        background-position: center;
        background-size: 100%;
        @media ${device.tablet}, (orientation: landscape) {
            /* background-image: url(/PapercutFish.webp); */
            /* background-position: top 25% right; */
        }
        /* background-image: url(/PapercutFish.webp); */
        background-size: cover;
        background-repeat: no-repeat;
        @media ${device.desktopL} {
            background-position: -100% right;
        }
    }

    &::before {
        display: none;
        pointer-events: none;
        content: "";
        position: absolute;
        /* width: 500%; */
        width: 100%;
        height: 100%;
        /* background-repeat: repeat; */
        opacity: 90%;
        /* background-size: cover; */
        /* background-size: 150% auto; */
        background-size: cover;
        /* background-size: cover; */
        background-position: center bottom;
        @media ${device.tablet}, (orientation: landscape) {
            background-position: center;
        }
        background-repeat: repeat;
        /* background-image: url(/background-textures/galaxy3.webp); */
        /* background-image: url(/background-textures/104-skystars.webp); */
        --color-1: hsla(206, 100%, 50%, 0.5);
        --color-2: hsla(263, 100%, 11%, 0.44);
        background: var(--color-2); /* fallback for old browsers */
        --gradient-direction: to bottom;
        --percentage: 95%;
        @media ${device.tablet} {
            --gradient-direction: -95deg;
            --percentage: 60%;
        }
        background: -webkit-linear-gradient(
            var(--gradient-direction),
            var(--color-1),
            var(--color-2) var(--percentage)
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
            var(--gradient-direction),
            var(--color-1),
            var(--color-2) var(--percentage)
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        top: 0;
        left: 0;
        z-index: -1;
    }
`;
function fillAndShuffleOffsets(additionalOffset = 0) {
    return {
        top: shuffleArray([40, 50, 60, 70, 80, 90]).map((num) => num + additionalOffset),
        left: shuffleArray([10, 20, 30, 40, 50, 60]).map((num) => num + additionalOffset),
    };
}
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function SkillsSection() {
    const [hoveredCategory, setHoveredCategory] = useState("all");
    const skills = {
        "Backend, DBMs, & CMSs": {
            names: ["Joomla!", "Drupal", "Wordpress", "MongoDB", "ExpressJS"],
            childRotationValues: {
                Wordpress: 200,
                ExpressJS: 45,
            },
            rotationValues: [0, 0, 380, 200, 300],
            text: (
                <>
                    I've worked on full-stack applications employing the use of <em>database management systems</em>{" "}
                    such as <em>MongoDB</em> and back-end web application frameworks such as <em>ExpressJS</em>, as well
                    as creating sites using content management systems such as <em>Wordpress</em>, <em>Joomla</em> and{" "}
                    <em>Drupal</em> in building projects.
                </>
            ),
            img: "/silhouettes/moon3.webp",
            rotationValue: 350,
            // rotationValue: 22,

            // rotationValue: 20,
        },
        "Libraries & Frameworks": {
            names: ["React", "JQuery", "Bootstrap", "SASS/SCSS"],
            childRotationValues: {
                "SASS/SCSS": 245,
            },
            text: (
                <>
                    I've built applications and plugins using JavaScript libraries and frameworks such as <em>React</em>{" "}
                    and <em>JQuery</em>, CSS frameworks and preprocessors such as <em>Bootstrap</em> and <em>SASS</em>.
                </>
            ),
            img: "/silhouettes/moon6.webp",
            rotationValue: 20,
            // rotationValuePortrait: 0,
        },
        "Languages & Templating Engines": {
            names: ["JavaScript", "CSS", "HTML", "SQL", "LiquidJS", "Handlebars"],
            childRotationValues: {
                SQL: 25,
                LiquidJS: 220,
                Handlebars: 80,
            },
            text: (
                <>
                    I have a strong foundational knowledge of integral web-development languages such as{" "}
                    <em>JavaScript</em>, <em>CSS</em>, <em>HTML</em>, and <em>SQL</em>, as well as familiarity with{" "}
                    <u>templating engines</u> such as <em>Handlebars</em> and <em>LiquidJS</em>.
                </>
            ),
            img: "/silhouettes/moon4.webp",
            rotationValue: 345,
            // rotationValuePortrait: 40,
        },
    };

    return (
        <StyledSkillsSection hoveredCategory={hoveredCategory}>
            <StarParticles accentColor="#0077ff"></StarParticles>

            <GalaxySection categories={skills}></GalaxySection>
            {/* <p className="skillText">{hoveredCategory !== "all" ? skills[hoveredCategory].text : ""}</p> */}
            {/* <div className="waves-div"></div> */}
            {/* <div className="water-div"></div> */}
            {/* <div className="stars-div"></div> */}
            {/* <div className="reflection-div"></div> */}
        </StyledSkillsSection>
    );
}

export default SkillsSection;
