import { Call } from "@mui/icons-material";
import Callout from "./Callout";


export const projectData = [
    {
        id: "ptApp",
        name: "PT Routine App",
        contentId: "ptApp",
        techStack: ["React", "Express", "MongoDB"],
        repo: "https://github.com/Evannessa/pt-routine-app",
        url: "",
        hasLiveSite: false,
        thumbnailImage: "PTAppThumbnail.webp",
        displayImage: "PT Routine App Videos3.webm",
        bubbleImages: ["pt-app/DisplayModeScreenshot-Break.webp", "pt-app/EditModeScreenshot.webp"],
        media: {
            problemSolved: [
                {
                    source: "pt-app/DisplayMode.mp4",
                    caption: "The app's 'Display Mode' where the routines configured in Edit Mode are played out in real time to be followed along with",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/EditModeTimeline2-NewTimer.mp4",
                    caption: "The app's 'Edit Mode', where the routines and exercises within can be modified.",
                    objectFit: "contain"
                },
               
                {
                    source: "pt-app/CreateNewTimer.mp4",
                    caption: "Demo of a new routine being created",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/NewRoutine-Break.mp4",
                    caption: "Demo of  a newly created routine being modified",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/EditMode-UploadModal.mp4",
                    caption: "Demonstration of the image selection and upload feature for each exercise in a routine",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/EditChangeTimeImg.webp",
                    caption: "Screenshot of the popup UI to change the values of minutes, seconds, and hours within 'Edit Mode'",
                    objectFit: "contain"
                },
            ],
            techStack: [
                {
                    source: "pt-app/MongoDBScreenshot.png",
                    caption: "Screenshot displaying Mongoose schema for a Routine (called 'Timer Sets' in the code) alongside an actual Routine object within the database in MongoDB",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/router_screenshot.webp",
                    caption: "Screenshot displaying the use of Routing via Express to forward the HTTP requests and data to associated controller functions",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/controller_screenshot2.webp",
                    caption: "Screenshot displaying some of the Routine 'controller' functions which handle updating, creating, or retrieving the data from MongoDB when the correct route is accessed",
                    objectFit: "contain"

                },
            ],
            functionality: [
                {
                    source: "pt-app/UseDashboardAndNav.mp4",
                    caption: "Demo of the app's dashboard which converts to a navigation drawer when in Edit Mode or Active Mode",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/DisplayMode.mp4",
                    caption: "The app's 'Active Display Mode' where the routines configured in Edit Mode are played out in real time to be followed along with",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/EditModeTimeline2-NewTimer.mp4",
                    caption: "The app's 'Edit Mode', where the routines and exercises within can be modified.",
                    objectFit: "contain"
                },
                {
                    source: "pt-app/EditMode-BreakRepToggle.mp4",
                    caption: "Demonstration of an exercise being toggled between 'Rep' and 'Break' mode",
                    objectFit: "contain"
                }

            ],
            experienceGained: [
                {
                    source: "PT-App.png",
                    objectFit: "contain"
                }
            ]
        }
    },
    {
        id: "artGallery",
        name: "JTCS Art Gallery",
        contentId: "artGallery",
        techStack: ["HTML (Handlebars)", "CSS (SCSS)", "JavaScript"],
        platforms: { FoundryVTT: "https://foundryvtt.com/" },
        repo: "https://github.com/Evannessa/Journal-To-Canvas-Slideshow",
        url: "https://foundryvtt.com/packages/journal-to-canvas-slideshow",
        hasLiveSite: false,
        thumbnailImage: "JTCSArtGallery.webp",
        displayImage: "JTCS-ArtGalleryVideos.mp4",
        bubbleImages: ["JTCSArtGallery.webp", "JTCSArtGallery.webp"],
        translationValues: ["right", "top"],
        // previewPath: "JTCS-Feature-Demo.mp4",
        // isVideo: true,
        media: {
            problemSolved: [
                {
                    source: "JTCS-Feature-Demo.mp4",
                    isVideo: true,
                },
            ],
            overhaul: [
                {
                    //put before/after picture here maybe
                    source: "JTCSArtGallery.webp",
                },
            ],
            newFeatures: [
                {
                    source: "jtcs-art-gallery-videos/ConfigDemo.mp4",
                    caption:
                        "Configuration App allowing users to easily differentiate and configure the tiles on the canvas to create their 'Art Gallery'",
                },
                {
                    source: "jtcs-art-gallery-videos/AccentColorDemo.mp4",
                    caption:
                        "Settings App offering further customizing options for the user, including changing colors of all of the module's applications",
                },
                {
                    source: "jtcs-art-gallery-videos/DarkThemeLightTheme.mp4",
                    caption: "Default Dark theme and Light Theme built in",
                },
            ],
            thingsLearned: [
                {
                    //put before/after picture here maybe
                    source: "JTCSArtGallery.webp",
                },
            ],
        },
    },
    {
        id: "wikiSite",
        name: "Campaign Wiki Site",
        contentId: "wikiSite",
        techStack: ["Handlebars", "CSS", "JavaScript"],
        url: "https://fastidious-smakager-702620.netlify.app/",
        hasLiveSite: true,
        repo: "https://github.com/Evannessa/Wiki-Public",
        thumbnailImage: "LocationMap - Thumbnail.webp",
        displayImage: "WikiVideos.mp4",
        bubbleImages: ["LocationMap.webp", "ProblemSolved.webp", "FamilyTree2.webp", "CharSearch.webp"],
        translationValues: ["right", "top", "left", "right"],
        media: {
            problemSolved: [
                {
                    source: "ProblemSolved.webp",
                },
            ],
            locationMap: [
                {
                    source: "wiki videos/LocationMapDisplay.mp4",
                    url: "",
                    isVideo: true,
                },
            ],

            characterSearch: [
                {
                    source: "wiki videos/CharacterSearchDemo.mp4",
                    url: "https://fastidious-smakager-702620.netlify.app/character-search",

                    isVideo: true,
                },
            ],
            familyTree: [
                {
                    source: "wiki videos/FamilyTreeDisplay.mp4",

                    isVideo: true,
                },
            ],
        },
    },

];

const removed = {
    id: "hudWiki",
    name: "Foundry Wiki Integration",
    contentId: "hudWiki",
    description: "(Name problem solved)",
    techStack: ["Handlebars", "CSS", "JavaScript"],
    url: "",
    repo: "",
    hasLiveSite: false,
    thumbnailImage: "WikiIntegration.webp",
    displayImage: "WikiIntegrationVideos.mp4",
    bubbleImages: ["WikiIntegration.webp", "WikiIntegration2.webp"],
    translationValues: ["right", "top"],
    media: {
        problemSolved: [
            {
                source: "WikiIntegration.webp",
            },
            {
                source: "WikiIntegration2.webp",
            },
        ],
        wikiIntegrationSetup: [
            {
                source: "WikiIntegration.webp",
            },
            {
                source: "wiki integration videos/ImageHoverDemo.mp4",

                isVideo: true,
                caption: "",
            },
        ],
        foundryFeatures: [
            {
                source: "wiki integration videos/TokenCreationDemo.mp4",

                isVideo: true,
                caption: "Creating tokens on the FoundryVTT canvas by clicking upon characters in the Display",
            },
            {
                source: "wiki integration videos/LocationMapInFoundry1.mp4",

                isVideo: true,
                caption: "Opening the Wiki Site's Location Map in an iFrame window within Foundry",
            },
            {
                source: "wiki integration videos/MapImageToCanvas2.mp4",

                isVideo: true,
                caption:
                    "Integration with JTCS Art Gallery to copy image paths from the Wiki Site's Location Map and display them on tiles on FoundryVTT's canvas",
            },
        ],
    },
}

export const sectionData = [
    {
        name: "About Me",
        description: (
            <>
                <p>
                    I am a prospective Front End Web Developer who strives to develop innovative software solutions by
                    engaging problems through a lens of both creative and technical expertise.
                </p>
            </>
        ),
        frame: "/frames/MoonSymbol6-Frame.png",
    },
    {
        name: " ",
        description: (
            <p>
                As a writer, I believe telling stories and <em>solving problems</em> exist as two sides of the same
                coin, and <em>innovation</em> comes from <em>inventive implementation</em> of long-established{" "}
                <em>patterns</em> and from stunning turns of phrase as blocks of language are rearranged in clever ways.
            </p>
        ),
        reverse: true,
    },
    {
        name: " ",
        description: (
            <>
                <p>
                    It’s with this mindset that I delve into front-end development; not just to solve problems, but to
                    develop stand-out solutions, while prioritizing beauty, accessibility and a seamless and satisfying
                    user experience.
                </p>

                {/* <p>
                    I also code for fun, developing small indie games as well as web applications and modules for
                    virtual tabletops to bolster my weekly online games of tabletop RPGs.
                </p> */}
            </>
        ),
        frame: "/frames/MoonSymbol7-Frame.png",
    },
    // {
    //     name: "Relevant Skills",
    //     description: (
    //         <>
    //             <p>
    //
    //             </p>
    //         </>
    //     ),
    //     frame: "/frames/MoonSymbol1.webp",
    //     frameWidth: "176px",
    //     frameHeight: "152px",
    //     frameTop: "53%",
    // },
    // {
    //     name: " ",
    //     description: (
    //         <>
    //             <p>
    //                 {" "}
    //                 In addition, I have experience in database management system software such as MySQL, and experience
    //                 with content management systems such as Joomla!, Drupal, and Wordpress, and experience building a
    //                 RESTful API utilizing NoSQL Database program MongoDB and backend web application framework
    //                 ExpressJS.
    //             </p>
    //         </>
    //     ),
    //     reverse: true,
    // },
    // {
    //     name: "Education",
    //     description: (
    //         <>
    //             <p>
    //                 Pursuing my lifelong passion in writing, I graduated Summa Cum Laude in Creative Writing from Emory
    //                 University, before a desire to expand my creative skillset beyond the written word pushed me toward
    //                 the world of software development.
    //             </p>
    //             <p>
    //                 After a few years of self-study and developing personal projects, I decided to continue my education
    //                 at Midlands Technical College to build a stronger technical foundation, where I am currently working
    //                 on an Associates in Computer Technology with a specialization in Web Development, to be completed in
    //                 May of 2023.
    //             </p>
    //         </>
    //     ),
    //     reverse: true,
    // },
    // {
    //     name: "Curated Projects",
    //     description: (
    //         <>
    //             <p>Here's a selection of personal and published projects I've worked on.</p>
    //         </>
    //     ),
    //     frame: "/frames/MoonSymbol2.webp",
    //     frameWidth: "78px",
    //     frameHeight: "108px",
    //     frameTop: "73%",
    // },
    //     {
    //         name: "Social Media",
    //         description: "",
    //         techStack: ["Handlebars", "CSS", "JavaScript"],
    //         url: "",
    //         reverse: true,
    //     },

    //  {
    //         name: "Personal Projects",
    //         description: "",
    //     },
    //   {
    //         name: "Resume",
    //         description: "",
    //     },
    //     // {
    //     name: "Contact Me",
    //     description: "",
    //     techStack: ["Handlebars", "CSS", "JavaScript"],
    //     url: "",
    //     reverse: true,
    // },
];

export const bodyCopy = {
    ptApp: [
        {
            id: "problemSolved",
            name: "Problem Solved",
            content: (
                <>
                    <p>In 2022, my productivity was hamstrung by repetitive strain injury, pushing me to seek physical therapy. After attending several weeks of sessions, my pain improved dramatically, largely thanks to a complex and personalized routine of stretches and exercises compiled by my physical therapist. Yet in order to maintain that pain relief, I had to continue to perform the routine daily, and augment the exercises as my body grew stronger or my environment changed.</p>
                    <p>I searched for software applications to help manage this complex task, but found nothing that completely satisfied my requirements. Of the many workout apps I tried, most were limited to pre-made routines with fixed exercise orders and durations. Those few apps that did allow such customizability still lacked other vital features, such as the ability to add images, videos, and descriptive text to help demonstrate the exercises for proper form and execution.</p>
                    <p>In the absence of an app that met my needs, I sat down to develop my own. The result was my Home Exercise Routine App, a full-stack application built using Express, React, and MongoDB. </p>
                </>
            )
        },
        {
            id: "techStack",
            name: "Tech Stack",
            content: (
                <>
                    <p>I chose React for the application’s front-end, as the modular and
                        flexible nature of the app’s routines and exercises that I required
                        would work best with a component-based structure.</p>
                    <p>To easily store, access, and modify these routines and exercises, I
                        decided a backend including database integration was required. After
                        much research and study into full-stack NodeJS applications, Express and
                        MongoDB were chosen for the backend application framework and database
                        application.</p>
                    <p>The Node.js library <a href="https://mongoosejs.com/">Mongoose</a> is
                        used to model and access the application’s data within MongoDB, with
                        schema for both the routines and the individual exercises that compose
                        them.&nbsp;</p>
                    <p>As the each route is accessed, an HTTP request is made from the
                        frontend to the backend via <a href="https://www.npmjs.com/package/axios">Axios</a>, sending a GET
                        request for the collection of routines or a specific routine.</p>
                    <p>On the backend, the <strong>Express app</strong> executes the
                        appropriate handler function matching the “dashboard” route, inside
                        which <strong>Mongoose</strong> methods are used to query the
                        <strong>MongoDB</strong> database and fetch the collection of routines
                        to be returned to the front end.</p>
                </>
            ),
        },
        {
            id: "functionality",
            name: "Functionality",
            content: (
                <>
                    <p>When the user first arrives on the app’s dashboard, their custom
                        routines are displayed as a grid of cards.</p>
                    <Callout text={<p> The collection of routines is fetched from the backend via an
                        <b>Axios</b> request in a useEffect hook; when the data is retrieved, the
                        response data is stored as an array within the component’s state. Each
                        routine is mapped to a card component, the array of which is rendered
                        within the dashboard</p>}
                        title={"Fetching Data"}
                        >
                    </Callout>
                    <p>Here, the user can access global operations, such as creating a new
                        routine, and routine- specific operations via interaction with each
                        routine card, such as running, updating, and deleting the specific
                        routine.</p>
                    <p>Pressing the “Edit” button on a card will take the user to Routine
                        Edit Mode. The application will navigate to the “Factory” route, which
                        fetches the data of the single routine in question and allows the user
                        to update its properties or add new exercise child-objects.</p>
                    <p>Clicking the button to add a new routine will also navigate to
                        <strong>Routine Edit Mode</strong>, creating a new routine and allowing
                        you to modify it immediately.</p>
                    <p>Pressing directly on the card navigates to <strong>Routine Play
                        Mode</strong>, where the routine is actively run, and the user can
                        follow along and perform the exercises one by one in real time.</p>
                    <h3>Routine Attributes</h3>
                    <p>In <strong>Routine Edit Mode</strong>, the user can also configure
                        global attributes for each routine, such as if the routine should run
                        automatic timed breaks between each exercise, and how long the duration
                        of the automatic breaks should be.</p>
                    <p>Another routine-specific setting allows the user to configure the
                        routine’s “media link”, by providing a link to a YouTube video or
                        playlist.</p>
                    <p>Then, when the routine plays, a floating window will appear with the
                        linked video or playlist embedded within. The user can move and resize
                        this window as desired.</p>
                    <p>This user could use this flexible feature for anything from
                        entertainment (watching videos or listening to podcasts while
                        exercising), motivation and meditation (playing invigorating music or
                        calming/meditative visuals) or instruction (watching a video of the
                        exercise being demonstrated to ensure proper form or technique).</p>
                    <br/>
                    <h3>Exercise Attributes</h3>
                    <p>Each routine is composed of a modular collection of child objects
                        called “exercises”.</p>
                    <p>When editing a routine, each of these exercises are shown on a
                        timeline, allowing the user to preview, navigate between, and rearrange
                        them as needed, allowing for a high level of flexibility and
                        customizability when designing a routine.</p>
                        <p> When the routine runs, each of these exercises will be
                            played in sequence one after the other, remaining on screen for the
                            configured duration, repeated by the number of repetitions.</p>
                    <p>The time and reps for each exercise can be configured in Routine Edit
                        Mode, along with other properties such as the exercise’s title,
                        description, and image, and whether the exercise plays automatically
                        when reached in the routine.</p>

                    <Callout text={
                        <p>Modifying these exercises updates the Routine’s state.
                            A useEffect hook with this state as a dependency triggers upon this
                            modification, and sends PATCH request via Axios to the backend with the
                            updated data, where the routine with the id matching the id sent via the
                            request parameters is queried for and found, then updated. This process
                            triggers a re-render in the front-end React app, causing the routine’s
                            modified data to be displayed.</p>
                    }
                        title={"Sending Updates to the Database"}
                    >
                    </Callout>
                    <p>The user can additionally drag and drop images onto the slide to
                        apply an image to the exercise. This image will additionally appear on
                        the thumbnail in the timeline, making it easier to tell specific
                        exercises apart.</p>
                    <Callout text={<p> While the image’s path is stored in the exercise object’s
                        data within the routine in the database, the image itself is uploaded to
                        the “Uploads” folder on the Express server via a “Post” request. This
                        directory is also accessed when the Image Gallery is, displaying all the
                        images within and allowing the user to select or delete them, depending
                        on the context.</p>}
                        title={"Uploading Images"}
                    >
                    </Callout>
                    <p>New exercises can also be added to the routine by clicking on the Add
                        button at the end of the timeline, and the order of the exercises can be
                        changed when “Sort Mode” is activated by dragging their thumbnails to
                        rearrange them.</p>
                </>
            ),

        },
        {
            id: "experienceGained",
            name: "Experience Gained",
            content: (
                <>
                    <p>(<em>Database integration, ExpressJS, integration of front and back
                        end, utilization of node modules, client server systems</em>)</p>
                    <p>Creating a full-stack application as an independent project was no
                        small undertaking. Learning how to take each of the moving parts that
                        compose a complex application, from client, to server, to database, and
                        integrate their various systems was invaluable experience that helped
                        hone many of my skills.&nbsp;</p>
                    <p>Though this release is completed, I consider this project one I can
                        continually add to and improve upon. The next big release will include
                        the creation of a public version of this project and the implementation
                        of a login system so that this web app is accessible and usable by
                        others.&nbsp;</p>
                    <p>Physical therapy is expensive and not accessible to everyone; so when
                        people suffer pain and injury that could be aided or alleviated by
                        exercise, it is important to provide an application that takes the pain
                        out of creating and managing such exercises so that they can be
                        performed consistently and effectively.</p>
                </>

            )

        }
    ],
    artGallery: [
        {
            id: "problemSolved",
            name: "Problem Solved",
            content: (
                <>
                    <p>
                        I'm an avid player of{" "}
                        <a href="https://en.wikipedia.org/wiki/Tabletop_role-playing_game">
                            tabletop roleplaying games
                        </a>
                        , like Dungeons and Dragons, playing weekly with colleagues across the country, and utilizing a
                        class of web applications called &quot;Virtual Tabletops&quot;. After trying out several
                        different VTTs, my friend group and I eventually settled on one called{" "}
                        <a href="https://foundryvtt.com/">Foundry Virtual Tabletop</a>.
                    </p>

                    <p>
                        {" "}
                        What attracted me to FoundryVTT was its inherent customizability and extensibility, allowing
                        users to develop add-ons called <a href="https://foundryvtt.wiki/en/basics/Modules">
                            modules
                        </a>{" "}
                        while utilizing languages such as
                        <em>JavaScript</em>, <em>HTML</em> and <em>CSS.</em>
                    </p>
                    <p>
                        Nothing magnified the importance of FoundryVTT's customizability more than the moment I realized
                        an important storytelling feature that I'd relied upon within a previous VTT didn&#39;t yet
                        exist within FoundryVTT. And nothing exemplifies my drive to solve problems more than when I
                        subsequently decided that in the absence of a satisfying solution, I would re-create this
                        feature myself.
                    </p>
                    <p>
                        So I set about developing this feature, delving deep into FoundryVTT&#39;s developer docs and
                        API documentation to figure out how the application worked under the hood and engaging in
                        discussion with more experienced developers. My module,{" "}
                        <a href="https://foundryvtt.com/packages/journal-to-canvas-slideshow">JTCS Art Gallery</a> was
                        the product, and soon I got it officially published at the recommendation of individuals in the
                        FoundryVTT community.
                    </p>
                    <p>This was the first project I&#39;d ever published for public use.</p>
                </>
            ),
        },
        {
            id: "overhaul",
            name: "The Overhaul",
            content: (
                <>
                    <p>
                        While the initial version of <em>JTCS Art Gallery</em> was functional enough, it was made in
                        2020 when I first started learning JavaScript and web development in general. As I honed my
                        skills and knowledge through school courses and personal projects, I realized the module was
                        showing its cracks and in need of an overhaul.
                    </p>
                    <p>
                        In August of 2022, with much more experience, I overhauled the module. The original version was
                        structured precariously upon a single bloated messy file, which I refactored, modularized to
                        allow for flexibility and reusability, and refined with additional functionality and several
                        quality-of-life improvements for both myself and the end-users along the way.
                    </p>
                </>
            ),
        },
        {
            id: "newFeatures",
            name: "New Features",
            content: (
                <>
                    <h3>From "Journal to Canvas Slideshow" to "JTCS Art Gallery".</h3>
                    <p>
                        During the module's lifetime, I noticed more experienced developers create cleaner, more
                        user-friendly modules with feature-sets that overlapped my module's original functionality. I
                        was at first intimidated, but turned that intimidation into resolve. Not wanting my work to
                        become redundant, I determined to not only improve my module, but to expand upon it, . Instead
                        of just a method to share a single image in a popup window or on the canvas, swapped one after
                        the other like a "Slideshow", I decided to re-imagine the module's core feature-set to be more
                        of an "Art Gallery", allowing multiple images to be added to the canvas, changed out as needed,
                        and bound by specific "frames".
                    </p>
                    <p>
                        But this increased flexibility and complexity required an improved UI and user experience to
                        present and manage the module's features in a clean and convenient fashion.
                    </p>
                    <p>
                        I implemented many of the new features in the Overhaul with the purpose of achieving this
                        improved <em>user experience</em>. For example, in the original version of the module, the user
                        would've had to click through several nested menus simply to change between the various methods
                        you could use to display the art, which increased frustration. In addition, in order to
                        differentiate between different tiles, their only option was to utilize a default FoundryVTT's
                        feature, which displayed a thin, hard-to-see border around the tiles. Once multiple tiles were
                        stacked on top of each other, utilizing this method became woefully insufficient.
                    </p>
                    <img src="" alt="" />

                    <p>
                        In order to ease and alleviate this process, I implemented functionality to make managing and
                        distinguishing between these tiles easier, allowing the tiles to be individually named,
                        highlighting the different tile "types" in distinct colors when they were hovered in the
                        application's UI, and allowing the users to customize those colors as they saw fit.
                    </p>
                </>
            ),
        },
        {
            id: "thingsLearned",
            name: "Things Learned",
            content: (
                <>
                    <p>
                        Throughout this project, I learned invaluable things about collaboration and the importance of
                        user experience.
                    </p>
                    <p>
                        Initially, I was unfamiliar with Git and GitHub, having using both tools solely as a backup
                        method for personal projects. But working on <strong>JTCS Art Gallery</strong> with GitHub as a
                        publishing platform both helped me better understand and internalize the fundamental aspects of
                        the <em>version control</em> and collaboration process, such as pull requests, issues, branching
                        and merging.
                    </p>
                    <p>
                        Through managing and accepting other developers' pull requests, I gained experience with reading
                        and reviewing others' code, handling merge conflicts, and writing and refactoring my own code to
                        better facilitate collaboration.
                    </p>
                    <p>
                        As I handled issues and fulfilled feature requests via the &quot;Issues&quot; feature on GitHub,
                        I became more comfortable developing with and for my end-users, utilizing their feedback to
                        construct a better model of my module's weaknesses and strengths, and what features I needed to
                        prioritize, rework, add, or remove to provide them with a satisfying user experience.
                    </p>
                </>
            ),
        },
    ],

    wikiSite: [
        {
            id: "problemSolved",
            name: "Problem Solved",
            content: (
                <>
                    <p>
                        While working on a collaborative fiction project with friends, I realized there wasn&#39;t an
                        easy way for us to keep track of all of the characters and settings we had built.
                    </p>
                    <p>
                        Using static site generator <a href="https://jekyllrb.com/">Jekyll</a>, I designed and developed
                        a personal &quot;wiki&quot; website, which I later deployed using{" "}
                        <a href="https://www.netlify.com/">Netlify</a>. This "wiki" was developed initially with the
                        intention for it simply to be a mostly static collection of articles to be referenced and
                        occasionally added to by myself and my group, with most of the pages initially written in
                        Markdown with additional structure and formatting added using templating engine{" "}
                        <a href="https://liquidjs.com/index.html">LiquidJS</a>
                    </p>
                    <p>
                        However, as our project expanded and grew in complexity, I realized there was a need to develop
                        additional functionality to help ease the process of adding and editing new settings and
                        characters and how these settings and characters were interrelated.
                    </p>
                    <p>Therefore the wiki site contains three main &#39;sub-projects&#39;. </p>
                    <ol>
                        <li>Character Search</li>
                        <li>Location Map</li>
                        <li>Family Tree</li>
                    </ol>
                </>
            ),
        },

        {
            id: "locationMap",
            name: "Location Map",
            pageUrl: "https://fastidious-smakager-702620.netlify.app/starshead-map",
            content: (
                <>
                    <p>
                        The setting of the story we were co-authoring expanded among multiple connected and nested
                        locations.
                    </p>
                    <p>
                        While this could&#39;ve perhaps been solved by an illustration of a map, the dynamic nature of
                        our collaborative process and the geographical complexities of the landscape required a level of
                        flexibility that a flat pre-drawn map wouldn&#39;t satisfy.
                    </p>
                    <p>
                        In order to solve this, I developed an interactive hex-map that would keep track of each
                        location and stylistically fit the themes and moods of the story.
                    </p>
                    <h2>Description of Functionality</h2>
                    <p>Each &quot;hex&quot; represents an individual location. </p>
                    <p>
                        You can hover over it to get a summary view of the location, or click upon the location to
                        &#39;zoom in&#39; to the sub-location and get a more detailed view.
                    </p>
                    <p>
                        The detailed view displaying lore descriptions, lists of inner locations, connected locations
                        that could be traveled to or from, and characters or factions present in the location.{" "}
                    </p>
                </>
            ),
        },
        {
            id: "characterSearch",
            name: "Character Search",
            pageUrl: "https://fastidious-smakager-702620.netlify.app/character-search",
            content: (
                <>
                    <p>
                        In order to keep track of an ever-expanding cast of characters, via JavaScript I developed that
                        collects every &#39;character&#39; page on the site by parsing their distinct metadata, then
                        formats their image and title into a clickable card, and allows you to search for and filter
                        these individuals by various characteristics, such as their age, gender, and location.
                    </p>
                </>
            ),
        },
        {
            id: "familyTree",
            name: "Family Tree",
            pageUrl: "https://fastidious-smakager-702620.netlify.app/trees-ruto",
            content: (
                <>
                    <p>
                        Keeping track of familial relations among our cast of characters also proved difficult. Knowing
                        new characters could be added at any time, constantly recreating and rearranging the cast's
                        family tree in flow-chart software like I&#39;d been doing before proved too much of a hassle.
                    </p>
                    <p>
                        To solve this, I developed a &#39;dynamic&#39; family tree that draws from YAML front matter
                        metadata written in each individual&#39;s character&#39;s article, and leverages that data to
                        connect each character with their immediate family members and distribute them evenly across an
                        SVG viewport.{" "}
                    </p>
                    <h2 id="functionality-">Description of Functionality</h2>
                    <p>
                        Hovering over a portrait magnifies the individual in question and highlights their connections
                        with their immediate family members, fading out any non-immediately-related individuals.
                    </p>
                    <p>
                        Clicking upon the individual&#39;s portrait will take you to their individual article page so
                        you can read about them in-depth.
                    </p>
                </>
            ),
        },
    ],
    hudWiki: [
        {
            id: "problemSolved",
            name: "Problem Solved",
            content: (
                <>
                    <p>
                        As my group and I continued to use FoundryVTT as the virtual tabletop for our weekly games, we
                        ran into small accessability and ease-of-use issues. So I developed a collection of private
                        utilities meant to provide quality-of-life and accessability improvements in our weekly games.
                        By far the most challenging of these utilities to develop was one that integrated the
                        previously-mentioned Wiki Site into FoundryVTT.
                    </p>
                </>
            ),
        },
        {
            id: "wikiIntegrationSetup",
            name: "Wiki Integration Setup",
            content: (
                <>
                    <p>
                        Integrating the Campaign Wiki Site within FoundryVTT required that I return to my codebase for
                        the aforementioned Wiki Ste project and research how to configure the &quot;netlify.toml&quot;
                        to allow the Wiki Site's resources (html pages and images alike) could be shared "cross-origin".
                    </p>
                    <p>
                        Once this challenge was overcome, I created an &quot;application&quot; within{" "}
                        <a href="https://foundryvtt.com/">Foundry Virtual Tabletop</a>, that utilized JavaScript to
                        fetch the articles' html, parse, process, convert and condense that data into bite-sized
                        elements meant for quick referencing during our games.
                    </p>
                    <p>
                        This split article headers into their own &quot;sub-pages&quot;, which could be navigated
                        between for brief snippets of information as reminders of character traits, setting description,
                        and story information.
                    </p>
                    <p>
                        In order to not clutter the Foundry UI, I designed this application to be quickly hidden in a
                        way reminiscent "drawer" or "side sheet" ui component, taking cues from mobile-first design
                        despite FoundryVTT being a mostly desktop-exclusive application.
                    </p>
                </>
            ),
        },
        {
            id: "foundryFeatures",
            name: "FoundryVTT Feature Integration",
            content: (
                <>
                    <p>
                        In addition, I added in functionality to integrate with Foundry VTT&#39;s core features, such as
                        being able to draw character images from the Wiki website to create &#39;tokens&#39; on Foundry
                        VTT&#39;s canvas, or to connect to &quot;macros&quot; and &quot;roll-tables&quot;, allowing and
                        integrating with my aforementioned JTCS Art Gallery module, allowing me to display images
                        directly from the wiki to my players during our gameplay sessions.
                    </p>
                </>
            ),
        },
    ],

};
