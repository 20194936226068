import React from 'react';
import styled from 'styled-components';

const StyledCallout = styled.details`
	color: white;
	display: grid;
	grid-template-rows: 1fr 90%;
	grid-template-columns: 1fr 90%;
    width: 100%;
    background-color:hsla(239, 54%, 22.2%, 0.35);
    box-shadow:hsla(233.6, 58.3%, 18.8%, 0.54) 0px 3px 8px;
	border-radius: 8px;
	border-bottom-left-radius: 0px;
    /* border-top-left-radius: 0px; */
    margin-bottom: 8px;
   
	border-left: 5px solid var(--color-primary-text-dark);
    cursor: pointer;
    &[open] > .callout__header{
        &::after{
            margin-left: auto;
            content: "▲";
        }
    }
	.callout__header {
		grid-row: 1/2;
		grid-column: 1/3;
		display: flex;
		gap: 0.5rem;
		align-items: center;
        color: var(--color-primary-text);
        padding-block: 0.5rem;
	    padding-right: 1rem;
	    padding-left: 2rem;
        &:hover{
            color: var(--color-primary-text-light);
            .callout__title{
                color: var(--color-primary-text-light);
            }
        }
        &::after{
            margin-left: auto;
            content: "▼";
        }
		.callout__title {
            white-space: nowrap;
			padding: unset;
			margin: unset;
            font-weight: bold;
            font-size: 1rem;

		}
    
      
	}
	p {
		grid-row: 2/3;
		grid-column: 1/3;
	    padding-right: 1rem;
	    padding-left: 2rem;
	}
`

const Callout = ({ icon, title, text, children }) => {
    return (
            <StyledCallout className="callout">
                <summary className='callout__header'>
                    <span className="material-symbols-outlined callout__icon">
                        {icon ? icon : "code"}
                    </span>
                    <h3 className="callout__title">{title ? title : "Technical Details"}</h3>
                </summary>
            {text}
        </StyledCallout>
    );
}

export default Callout;
