import React from "react";

function SVGPattern({ patternData }) {
    const { name, id, media, displayImage, thumbnailImage, width, height, pu, par, classes = [] } = patternData;
    //par = preserveAspectRatio
    //pu = pattern units
    let { prefix } = patternData;
    if (!prefix) prefix = `/project demo images/`;
    const img = media[0];
    const source = `${prefix}${thumbnailImage ? thumbnailImage : "default-image.jpg"}`;
    const classNameString = classes && classes.length > 0 ? classes.join(" ") : " ";
    return (
        <pattern
            id={`fill${id}`}
            width={width || "1"}
            height={height || "1"}
            viewBox="0 0 100 100"
            x="0"
            y="0"
            patternUnits={pu || "objectBoundingBox"}
        >
            <image
                className={`pattern-image ${classNameString}`}
                width={img?.width || "100"}
                height={img?.height || "100"}
                x={img?.x || "0"}
                y={img?.y || "0"}
                href={`${source}`}
                preserveAspectRatio={par || "xMinYMin slice"}
            />
        </pattern>
    );
}
export default SVGPattern;
