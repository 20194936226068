import styled, { css } from "styled-components";
import { helpers } from "../helpers/helpers";

/**
 * Returns default pseudo element values, or values for absolute positioned child
 * @param {String} wrapper - should it be a before or an after or none
 * @param {Object} options  - object of extra options
 * @param {Boolean} options.centered - whether the object should be centered within the parent (will top left offset by 50%, and transform: translate by -50%, -50%)
 * @param {*} overrides  - any extra properties to override defaults
 * @returns a psuedo element string to be placed in a Styled Component
 */
export function addPseudoElement(wrapper = "before", options = { centered: false, circle: false }, overrides = {}) {
    const { centered, circle } = options;
    const values = {
        ...(wrapper !== "none" && { content: "' '" }),
        position: "absolute",
        width: "100%",
        height: "100%",
        top: centered ? "50%" : 0,
        left: centered ? "50%" : 0,
        "pointer-events": "none",
        "z-index": "var(--z-highest)",
        ...(centered && { transform: `translate(-50%, -50%)` }),
        ...(circle && { "border-radius": "50%" }),
        ...overrides,
    };
    const stringPseudoElement = helpers.objectToString(values);
    const fullString =
        wrapper !== "none"
            ? `&:${wrapper}{
            ${stringPseudoElement}
    }`
            : `${stringPseudoElement}`;

    return fullString;
}
export const WithPseudoElement = styled.div`
    ${({ before }) =>
        before &&
        css`
            &:before {
                content: "";
                position: absolute;
            }
        `}
    ${({ after }) =>
        after &&
        css`
            &:after {
                content: "";
                position: absolute;
            }
        `}
`;
