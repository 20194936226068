import React, { useState } from "react";
import OrbitRing from "./OrbitRing";
import { device } from "./styled-components/devices";
import { StyledTextPopout } from "./HeroSection";
import styled, { keyframes, css } from "styled-components";
import { helpers } from "./helpers/helpers";

//Borrowed and adjusted from here https://css-tricks.com/snippets/sass/placing-items-circle/
function placeOnCircle(itemCount, circleSize, itemSize) {
    function nthOnCircle() {
        let string = "";
        let angle = 360 / itemCount;
        let rot = 0;
        for (let i = 1; i <= itemCount; i++) {
            string += `
                &:nth-of-type(${i}){
                    transform:
                        rotate(${rot * 1}deg)
                        translate(${circleSize / 2}rem)
                        rotate(${rot * -1}deg);

                }
            `;
            rot = rot + angle;
        }
        return string;
    }
    return `
    width: ${circleSize}rem;
    height: ${circleSize}rem;
    padding: 0;
    border-radius: 50%;
    > * {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: ${itemSize}rem;
        height: ${itemSize}rem;
        margin: -(${itemSize / 2}rem);
        ${nthOnCircle()};
    }
`;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-19 16:6:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-bck
 * ----------------------------------------
 */
const twinkleAnimation = keyframes`

  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }

`;

const float = keyframes`
	from, to {
		transform: translate(0,3%);
	}
	25% {
		transform: translate(-3%,0);
	}
	50% {
		transform: translate(0,-3%);
	}
	75% {
		transform: translate(3%,0);
	}

`;
const bobAnimation = keyframes`
0%, 100%{
/* transform: translateY(-20px); */
transform: translate(0, -20px) scale(1);
}
50%{
transform: translate(0, 20px) scale(1);
}
`;
const StyledSkillPool = styled.ul`
    margin: unset;
    padding: unset;
    opacity: 100%;
    /* opacity: ${(props) => (props.hoveredCategory !== "all" ? "0%" : "100%")}; */
    opacity: ${(props) => (props.hovered ? "100%" : "0%")};

    transition: opacity 200ms ease-in-out;
`;

const StyledSkillArea = styled.section`
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    /* transform: translateX(-50%); */
    .text-ring {
    }
    .text-bubble {
        pointer-events: auto;
        position: absolute;
        bottom: 0;
        right: 0;
        transform-origin: center;
        /* transform: scale(0.9); */
        width: 40vh;
        height: 40vh;
        @media ${device.tablet}, (orientation: landscape) {
            right: unset;
            left: 0;
            width: 35vw;
            height: 35vw;
        }
        @media ${device.desktop} {
            width: 20vw;
            height: 20vw;
            left: unset;
            right: 0;
        }
        background-color: transparent;
        border: 1px solid var(--color-primary-text);
        border-color: var(--color-primary-text);
        &:after,
        &:before {
            pointer-events: none;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: inherit;
            transform-origin: center;
            transform: scale(1.5);
            border-radius: inherit;
        }
        &:before {
            transform: scale(2);
        }
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        color: white;
        font-weight: bold;
        /* backdrop-filter: blur(12px); */
        border-radius: 50%;
        display: grid;
        place-content: center;
        z-index: 1000;
        > p {
            opacity: ${(props) => (props.hovered ? "100%" : "0%")};
            transition: opacity 200ms ease-in-out;
        }
        padding: 4rem;
    }
    ul {
    }
`;
StyledSkillArea.displayName = "StyledSkillArea";

const StyledSkillText = styled.li`
    color: ${(props) => props.randomColor};
    /* color: var(--color-primary-text-light);
    &:nth-child(odd) {
        color: var(--glassy-lilac-bright);
    } */
    display: grid;
    place-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    z-index: var(--z-high);
    /* box-shadow: */
    span {
        /* text-shadow: 1px 1px 6px hsla(267, 66%, 10%, 0.52); */
    }
    span:last-child {
        grid-row: 1/2;
        grid-column: 1/2;
        /* opacity: ${(props) => (props.hovered ? "100%" : "0%")}; */
        transition: opacity 200ms ease-in-out;
    }
    span:first-child {
        --random-opacity: ${(props) => props.randomOpacity};
        grid-row: 1/2;
        grid-column: 1/2;
        /* animation-name: ${twinkleAnimation};
        animation-delay: ${(props) => props.animationDelay};
        animation-duration: ${(props) => props.animationDuration};
        animation-iteration-count: infinite;
        animation-direction: alternate; */
        /* opacity: ${(props) => (props.hovered ? "0% !important" : "var(--random-opacity)")}; */
        transition: opacity 200ms ease-in-out;
    }
`;
const StyledBubble = styled.div`
    transform-origin: center;
    transform: translateX(-100%);
    /* right: calc(${(props) => props.styleTop} - 10%); */
    /* top: calc(${(props) => props.styleLeft} - 20%); */
    /* bottom: ${(props) => props.styleLeft}; */
    top: ${(props) => props.styleTop};
    left: ${(props) => props.styleLeft};
    @media ${device.tablet}, (orientation: landscape) {
        bottom: unset;
        right: unset;
        top: ${(props) => props.styleTop};
        left: ${(props) => props.styleLeft};
    }
    z-index: 900;
    position: absolute;
    /* width: 5rem; */
    /* height: 5rem; */
    min-width: 4rem;
    min-height: 4rem;
    max-width: 10rem;
    max-height: 10rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
    transform-style: preserve-3d;
    will-change: transform;
    --border-color-1: hsl(233, 38%, 45%);
    --border-color-2: hsl(233, 88%, 74%);
    --border-color-3: hsl(233, 98%, 74%);
    /* background-image: radial-gradient(8% 8% at 22% 28%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%),
        radial-gradient(8% 8% at 23% 27%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%),
        radial-gradient(8% 8% at 24% 26%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%),
        radial-gradient(8% 8% at 25% 25%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%),
        radial-gradient(8% 8% at 26% 24%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%),
        radial-gradient(8% 8% at 27% 23%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%),
        radial-gradient(8% 8% at 28% 22%, hsl(0, 0%, 100%) 45%, hsla(0, 0%, 100%, 0) 50%); */
    box-shadow: 0 -0.06em 0.1em var(--color-primary-text) inset, 0 -0.15em 0.4em var(--border-color-1) inset,
        0 0.05em 0.05em var(--border-color-2) inset, 0.05em 0 0.1em var(--color-primary-text) inset,
        -0.05em 0 0.1em var(--color-primary-text) inset, 0 0.1em 0.4em var(--border-color-3) inset;
    /* border: 1px solid var(--color-glassy-teal-border); */
    backdrop-filter: blur(3px);
    z-index: var(--z-highest);

    border: 1px solid white;
    border-color: var(--color-primary-text);

    display: grid;
    place-content: center;
    /* animation-name: ${float};
    animation-duration: ${(props) => props.animationDuration};
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; */
    cursor: pointer;
    /* transform: ${(props) => (props.hovered ? "scale(1.2) !important" : "scale(1.0)")}; */
    transition: transform 200ms ease-in-out;
    pointer-events: auto;

    &:hover,
    &:focus {
        transition: transform 200ms ease-in-out;
        transform: translate(inherit) scale(1.25) !important;
    }
    > * {
        pointer-events: none;
    }

    p {
        pointer-events: auto;
        color: var(--color-secondary-text);
        font-weight: bold;
        padding: 1rem;
        z-index: 2;
    }
    .project-image {
    }
    .charm-overlay {
        background-image: linear-gradient(45deg, var(--color-primary-text), var(--color-primary-text));
        position: absolute;
        z-index: 0;
        aspect-ratio: 1/1;
        width: 50%;
        height: 50%;
        /* height: auto; */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        overflow: visible;
    }
    .charm {
        z-index: -2;
        /* filter: blur(1px); */
        /* mix-blend-mode: overlay; */
        filter: blur(1px) drop-shadow(-2.6em 2.6em 4px hsla(0, 0%, 0%, 0.2));
    }
    &:before,
    &:after {
        transition: opacity 200ms ease-in-out;
        pointer-events: none;
        content: "";
        display: block;
        position: absolute;
    }
    &:before {
        display: none;
        border-radius: 0.75em;
        /* box-shadow: 0 0 0 0.5em hsl(0, 0%, 100%) inset; */
        /* filter: drop-shadow(0.6em 0.6em 4px hsla(0, 0%, 0%, 0.2)); */
        top: 50%;
        left: 50%;
        width: 1.5em;
        height: 1.5em;
        transform: translate3d(-50%, -50%, -1px);
        z-index: -1;
    }
    &:after {
        display: none;
        filter: blur(4px);
        background: radial-gradient(
            100% 100% at center,
            hsla(0, 0%, 0%, 0) 35%,
            hsla(0, 0%, 0%, 0.3) 48%,
            hsla(0, 0%, 0%, 0) 50%
        );
        /* filter: blur(1px); */
        top: 2.6em;
        left: -2.6em;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, -1px);
        z-index: 2;
    }
    .star-span {
        position: absolute;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/background-textures/galaxy3.webp);
        background-size: cover;
        opacity: 70%;
        z-index: 0;
        mask-image: radial-gradient(100% 100% at center, transparent 10%, #00000046 30%, black);
        -webkit-mask-image: radial-gradient(100% 100% at center, transparent 10%, #00000048 30%, black);
    }
    .decor-span {
        display: none;
        position: absolute;
        border-radius: 50%;
        /* --cyan: #0fb4ff; */
        /* --pink: #ff4484; */
        /* --yellow: #ffeb3b; */
        --cyan: #0fb4ff;
        --pink: #ff4484;
        --yellow: #ffce79;
        --blur-small: 8px;
        --blur-large: 12px;
        --deg: 20deg;

        &:nth-child(1) {
            inset: 10px;
            border-left: 15px solid var(--cyan);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(2) {
            inset: 10px;
            border-right: 15px solid var(--pink);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(3) {
            inset: 20px;
            border-top: 15px solid var(--yellow);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(4) {
            inset: 30px;
            border-left: 15px solid var(--pink);
            filter: blur(var(--blur-large));
        }
        &:nth-child(5) {
            inset: 10px;
            border-left: 15px solid var(--cyan);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(6) {
            inset: 10px;
            border-bottom: 10px solid #fff;
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
            transform: rotate(330deg);
        }
    }
`;

StyledBubble.displayName = "StyledBubble";

export const SkillArea = (props) => {
    const [hovered, setHovered] = useState(false);
    //skills will be pased, we just need the category and its
    const { skillCategoryKey, skillCategoryValues, childPositions } = props;

    function setHoveredFromChild(isHovered = false) {
        setHovered(isHovered);
        // if (isHovered) {
        //     props.setHovered(skillCategoryKey);
        // } else {
        //     props.unsetHovered();
        // }
    }

    return (
        <StyledSkillArea hovered={hovered}>
            <div className="text-ring">
                <Bubble
                    img={skillCategoryValues.img}
                    // hoveredCategory={props.hoveredCategory}
                    hovered={hovered}
                    setParentHovered={setHoveredFromChild}
                    animationDelay={props.animationDelay}
                    animationDuration={props.animationDuration}
                    styleLeft={props.style.left}
                    styleTop={props.style.top}
                    // style={{ ...props.style }}
                    category={skillCategoryKey}
                    skillList={skillCategoryValues}
                ></Bubble>
            </div>
            <div className="text-bubble">
                <p>{skillCategoryValues.text}</p>
                <SkillPool
                    // hoveredCategory={props.hoveredCategory}
                    hovered={hovered}
                    catKey={skillCategoryKey}
                    catValues={skillCategoryValues}
                    positions={childPositions}
                ></SkillPool>
            </div>
        </StyledSkillArea>
    );
};
export const SkillPool = (props) => {
    const { catKey, catValues, positions, hovered, hoveredCategory } = props;
    const colorOptions = ["var(--color-primary-text-light)", "var(--glassy-lilac-bright)", "hsl(289, 32%, 46%)"];
    function getRandomColor() {
        const randomIndex = Math.floor(Math.random() * colorOptions.length);
        return colorOptions[randomIndex];
    }
    function getRandomOpacity() {
        const randomIndex = Math.floor(Math.random() * 70 - 20 + 1) + 20;
        return randomIndex;
    }
    return (
        <StyledSkillPool
            key={encodeURI(catKey)}
            hovered={hovered}
            // hoveredCategory={hoveredCategory}
        >
            {catValues.names.map((skill, index) => {
                {
                    /* const position = ; */
                }
                return (
                    <StyledSkillText
                        as="li"
                        key={encodeURI(skill) + index}
                        data-category={catKey}
                        style={{ top: positions[index][1] + "%", left: positions[index][0] + "%" }}
                        animationDuration={4 + Math.random() + "s"}
                        hovered={hovered}
                        animationDelay={1 + Math.random() + "s"}
                        randomColor={getRandomColor()}
                        randomOpacity={getRandomOpacity() + "%"}
                        // hoveredCategory={}
                    >
                        <span>{skill}</span>
                        <span>{skill}</span>
                    </StyledSkillText>
                );
            })}
        </StyledSkillPool>
    );
};
export const Bubble = (props) => {
    const [hovered, setHovered] = useState(false);

    function _setHovered() {
        setHovered(true);
        props.setParentHovered(true);
    }
    function unsetHovered() {
        setHovered(false);
        props.setParentHovered(false);
    }
    return (
        <StyledBubble
            className="container"
            styleLeft={props.styleLeft}
            styleTop={props.styleTop}
            // style={{ ...props.style }}
            data-category={props.category}
            animationDuration={props.animationDuration}
            animationDelay={props.animationDelay}
            onMouseEnter={_setHovered}
            onMouseLeave={unsetHovered}
            hovered={hovered}
        >
            <p>{props.category}</p>
            <span className="decor-span"></span>
            <span className="decor-span"></span>
            <span className="decor-span"></span>
            <span className="decor-span"></span>
            <span className="decor-span"></span>
            <>
                <span
                    className="charm-overlay"
                    style={{ maskImage: `url(${props.img})`, WebkitMaskImage: `url(${props.img})` }}
                ></span>
                <img className="charm" src={props.img} alt={props.img} />
            </>
            <span className="decor-span"></span>
            <span className="star-span"></span>
            <span className="shine-span"></span>
        </StyledBubble>
    );
};
