import styled, { css, keyframes } from "styled-components";
import { addPseudoElement } from "./pseudo.styled";

export const StyledLens = styled.section`
    /* position: absolute; */
    pointer-events: auto;
    background-size: cover;
    border-radius: 50%;
    place-content: center;
    display: grid;
    width: 100%;
    aspect-ratio: 1/1;
    z-index: var(--z-highest);
    transition: transform 200ms ease-out;
    clip-path: circle(50% at 50% 50%);

    &[data-interactive="true"] {
        border: 1px solid var(--color-primary-text);

        backdrop-filter: blur(2px);
        background-color: var(--glassy-lilac-alpha-2);
        cursor: pointer;
    }
    &[data-interactive="false"] {
        border: 1px solid var(--glassy-lilac-alpha-2);
    }
    > * {
        pointer-events: none;
    }
    .foreground {
        /* transform: translateZ(50px) scale(0.5); */
        &p {
            color: var(--color-secondary-text);
            font-weight: bold;
            padding: 1rem;
            z-index: var(--z-highest);
        }
    }
    .charm {
        /* transform: translateZ(30px) scale(0.3); */
        z-index: var(--z-high);
    }

    ${addPseudoElement(
        "after",
        { circle: true },
        {
            "box-shadow": "#1711315c 5px -6px 5px -3px inset, #0d0a1868 -3px 5px 4px 0px inset",
            "z-index": 1000,
        }
    )};
    ${addPseudoElement(
        "before",
        { circle: true },
        {
            background: "rgba(white, 0.5)",
            width: "60%",
            "z-index": 1000,
            transform: "skew(45deg)",
            transition: "left 400ms ease-in-out",
            left: "-125%",
        }
    )};
    &:hover {
        &:before {
            left: 150%;
        }
    }
    /* &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 3rem;
        overflow: hidden;
        border-radius: 50%;
        z-index: var(--z-highest);
    } */
    .background {
        opacity: 60%;
        box-shadow: hsla(251, 49%, 13%, 0.651) 0px -10px 5px -2px inset, rgba(0, 0, 0, 0.53) 0px 10px 10px -1px inset;
        overflow: hidden;
        border-radius: 50%;
        content: "";
        /* position: absolute; */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--z-low);
        background-image: url(/background-textures/galaxy4.jpg);
        background-size: 200%;
        /* transform: translateZ(-20px); */
    }
`;

export const StyledBubble = styled(StyledLens)`
    .charm-overlay {
        background-image: linear-gradient(45deg, var(--color-primary-text), var(--color-primary-text));
        position: absolute;
        z-index: 0;
        aspect-ratio: 1/1;
        width: 50%;
        height: 50%;
        /* height: auto; */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        overflow: visible;
    }
    .charm {
        z-index: -2;
        filter: blur(1px) drop-shadow(-2.6em 2.6em 4px hsla(0, 0%, 0%, 0.2));
    }

    .star-span {
        position: absolute;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/background-textures/galaxy3.webp);
        background-size: cover;
        opacity: 70%;
        z-index: 0;
        mask-image: radial-gradient(100% 100% at center, transparent 10%, #00000046 30%, black);
        -webkit-mask-image: radial-gradient(100% 100% at center, transparent 10%, #00000048 30%, black);
    }
    .decor-span {
        display: none;
        position: absolute;
        border-radius: 50%;
        /* --cyan: #0fb4ff; */
        /* --pink: #ff4484; */
        /* --yellow: #ffeb3b; */
        --cyan: #0fb4ff;
        --pink: #ff4484;
        --yellow: #ffce79;
        --blur-small: 8px;
        --blur-large: 12px;
        --deg: 20deg;

        &:nth-child(1) {
            inset: 10px;
            border-left: 15px solid var(--cyan);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(2) {
            inset: 10px;
            border-right: 15px solid var(--pink);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(3) {
            inset: 20px;
            border-top: 15px solid var(--yellow);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(4) {
            inset: 30px;
            border-left: 15px solid var(--pink);
            filter: blur(var(--blur-large));
        }
        &:nth-child(5) {
            inset: 10px;
            border-left: 15px solid var(--cyan);
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
        }
        &:nth-child(6) {
            inset: 10px;
            border-bottom: 10px solid #fff;
            filter: blur(var(--blur-small)) hue-rotate(var(--deg));
            transform: rotate(330deg);
        }
    }
`;
