import "./App.scss";
import { Article } from "@mui/icons-material";
import { ArrowDownwardRounded } from "@mui/icons-material";
import StarParticles from "./StarParticles";
import { helpers } from "./helpers/helpers";
import Loader from "./Loader";
import SkillsSection from "./SkillsSection";
import { device } from "./styled-components/devices";
import Modal from "./Modal";
import Divider from "./Divider";
import ProjectsSection from "./ProjectsSection";
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalStyle } from "./styled-components/global.styled";
import LandingSection from "./LandingSection.jsx";
import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import { CircleIconButton, TextButton } from "./styled-components/Buttons.Styled";
import { sectionData, projectData } from "./ProjectData";
import PrevNextButtons from "./PrevNextButtons";
import Socials from "./Socials";
import { useMediaQuery } from "react-responsive";
import BottomSection from "./BottomSection";
import { ParallaxGroup, ParallaxLayer, ParallaxSection } from "./ParallaxGroup";


const StarDiv = styled(ParallaxLayer)`
    display: ${(props) => (props.isVisible ? "flex" : "none")};
    background-image: url("background-textures/cosmos-star-in-the-night.png");
    filter: ${helpers.cssFunctionWrap("blur", 1, "px")};
    background-size: contain;
    background-repeat: repeat;
`;
const StyledApp = styled.section`
    .next-button {
        display: ${(props) => (props.isVisible ? "none" : "flex")};
    }
`;
const StyledParallaxContainer = styled(ParallaxSection)`
    perspective-origin-x: 100%;
    *,
    *::before,
    *:after {
        ${(props) => props.boxDebugOn && `outline: 2px solid pink`}
    }

    --perspective: 300px;
    perspective: var(--perspective);
    /* height: 100vh; */
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    p {
        font-weight: 300;
        color: var(--color-secondary-text);
    }
    .landing-header {
        height: fit-content;
        background: var(--color-primary-bg);
        display: grid;
        place-content: center;
        padding-inline: clamp(1rem, 1rem + 2vw, 2rem);
        padding-block: 4vh;
        justify-items: center;
        position: relative;
        > h3 {
            font-size: 1.5rem;
            align-self: ${(props) => (props.reverse && props.reverse === true ? "flex-end" : "auto")};
            text-align: ${(props) => (props.reverse && props.reverse === true ? "right" : "left")};
            /* align-self: flex-end; */
            letter-spacing: 0.35rem;
            text-transform: uppercase;
            color: var(--color-primary-text);
            @media ${device.tablet} {
                font-size: 2.5rem;
                align-self: auto;
                text-align: left;
            }
        }
    }
    .landing-section__alt {
        height: 90vh;
        background: var(--color-primary-bg);
        outline: 3px solid var(--color-primary-bg);
        display: grid;
        place-content: center;
        padding-inline: clamp(1rem, 1rem + 2vw, 2rem);
        padding-block: 4vh;
        justify-items: center;
        position: relative;
        img {
            position: absolute;
            /* bottom: 0; */
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            max-width: 50%;
            z-index: 200;
            filter: hue-rotate(20deg), lighten(0.5);
            /* max-height: 50%; */
        }

        > h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            align-self: ${(props) => (props.reverse && props.reverse === true ? "flex-end" : "auto")};
            text-align: ${(props) => (props.reverse && props.reverse === true ? "right" : "left")};
            /* align-self: flex-end; */
            letter-spacing: 0.35rem;
            text-transform: uppercase;
            color: var(--color-primary-text);
            @media ${device.tablet} {
                font-size: 2.5rem;
                align-self: auto;
                text-align: left;
            }
        }
        p {
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.6;
            @media ${device.tablet} {
                font-size: 1.25rem;
                min-width: 35ch;
                max-width: 65ch;
                text-align: left;
                line-height: 1.8;
                &:not(+ p) {
                    margin-top: 3rem;
                }
            }
            text-align: ${(props) => (props.reverse && props.reverse === true ? "right" : "left")};
        }
    }

    .parallax__group {
        overflow: visible;
        position: relative;
        min-height: 50vh;
        @media ${device.tablet}{
        height: 100vh;
        }
        transform-style: preserve-3d;
        ${(props) => props.parallaxDebugOn && `transform: translate3d(700px, 0, -800px) rotateY(30deg);`}
        &--full{
        height: 100vh;
        }
        &--small {
        height: 100vh;
        }
        &--large {
            height: 110vh;
        }
        &--double {
            height: 200vh;
        }
        &:nth-child(1) {
            z-index: 1;
        }
        &:nth-child(2) {
            z-index: 2;
        }
        &:nth-child(3) {
            /* z-index: 13; */
            z-index: -1;
        }
    }


`;
function App() {
    const containerRef = useRef(null);
    const educationSection = React.createRef()
    const aboutSection = React.createRef()
    const firstSection = useRef();
    const heroSection = useRef();
    const [isVisible, setIsVisible] = useState(false);
    const [viewingContent, setViewingContent] = useState(true);
    const [viewingEducationSection, setViewingEducationSection] = useState(false)

    const [loading, setLoading] = useState(false);
    const [isWebkitDevice, setIsWebkitDevice] = useState(false)

    const viewModes = ["info", "skills", "projects"];
    const [viewMode, setViewMode] = useState(viewModes[0]);
    //Set this back to zero

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const callbackFunction = (entries) => {
        entries.forEach((entry) => {
            if (entry.target.tagName.toLowerCase() === "h1") {
                setIsVisible(entry.isIntersecting);
            }
            if (entry.target.tagName.toLowerCase() === "section") {
                setViewingContent(entry.isIntersecting);
            }
            if (entry.target.dataset.marker === "education") {
                console.log("Viewing education", entry.target, entry.isIntersecting)
                setViewingEducationSection(entry.isIntersecting);
            }
        });
    };
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.9,
        };


        const observer = new IntersectionObserver(callbackFunction, options);
        if (containerRef.current) observer.observe(containerRef.current);
        if (heroSection.current) observer.observe(heroSection.current);
        if (educationSection.current) observer.observe(educationSection.current)
        const containerCurrent = containerRef.current;
        const heroSectionCurrent = heroSection.current;
        const educationSectionCurrent = educationSection.current

        return () => {
            if (containerCurrent) observer.unobserve(containerCurrent);
            if (heroSectionCurrent) observer.unobserve(heroSectionCurrent);
            if (educationSectionCurrent) observer.unobserve(educationSectionCurrent)
        };
    }, [containerRef, heroSection, educationSection]);

    // useEffect(() => {
    //     const UA = navigator.userAgent;
    //     const isWebkit =
    //         /\b(iPad|iPhone|iPod|Macintosh)\b/.test(UA) &&
    //         /WebKit/.test(UA) &&
    //         !/Edge/.test(UA) &&
    //         !window.MSStream;
    //     setIsWebkitDevice(isWebkit)

    // }, [])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [viewMode]);

    const [modalState, setModalState] = useState({
        visible: false,
        content: "",
    });
    const { generateFillerText } = helpers;
    function setView(newViewMode) {
        if (viewMode !== newViewMode) {
            setViewMode(newViewMode);
        }
    }

    //scroll when clicking on view ork button
    function handleScroll() {
        firstSection.current.scrollIntoView();
    }

    const sectionElements = sectionData.map((element, index) => {
        const reverse = element.reverse
        let includeDivider = index < sectionData.length - 1 && !element.reverse;
        let includeSlice = index === 0

        return (
            <ParallaxGroup reverse={reverse} z={element.reverse ? index : index + 2} data-key={`section-${index}`} key={`section_${index}`} ref={index === 0 ? firstSection : null}>
                <LandingSection
                    includeSlice={includeSlice}
                    sectionTitle={element.name}
                    sectionContent={element.description || generateFillerText()}
                    frame={!element.reverse ? element.frame : ""}
                    frameWidth={element.frameWidth}
                    frameHeight={element.frameHeight}
                    frameTop={element.frameTop}
                    buttonText={element.buttonText}
                    reverse={element.reverse}
                    className="landing-section"
                ></LandingSection>

                {includeDivider && <Divider></Divider>}
            </ParallaxGroup>
        );
    });







    const conditionalStyle = {
        overflowY: viewMode === "projects" ? "hidden" : "auto",
        transform: "translateZ(0px)"
    };


    const modalHandler = {
        setModalVisible,
        setModalContent,
    };
    const navButtonData = [
        {
            text: "About Me",
            viewMode: "info",
        },
        {
            text: "My Skills",
            viewMode: "skills",
        },
        {
            text: "My Work",
            viewMode: "projects",
        },
    ];

    const readingModeButton = <CircleIconButton title="display page in a more simplified view" key="reading-mode">
        <Article></Article>
    </CircleIconButton>

    const navButtons = navButtonData.map((data) => {
        return data.external ? (
            <TextButton as="a" key={data.text + "navLink"} href={data.link}>
                {data.text}
            </TextButton>
        ) : (
            <TextButton
                key={data.viewMode + "navButton"}
                className={data.viewMode}
                onClick={() => setView(data.viewMode)}
            >
                {data.text}
            </TextButton>
        );
    });
    //TODO: uncomment this in next release to add reading mode functionality
    // navButtons.push(readingModeButton)

    function setModalVisible(value, event) {
        event.preventDefault();
        setModalState((prevState) => {
            return {
                ...prevState, //the spread operator needs to come first, as the properties below it are overriding properties within the object that's been spread out.
                visible: value,
            };
        });
    }
    function setModalContent(content, makeVisible = false) {
        setModalState((prevState) => {
            return {
                ...prevState,
                content: content,
                ...(makeVisible && { visible: makeVisible }),
            };
        });
    }



    return (
        <StyledApp className="App" style={conditionalStyle} isVisible={viewingContent}>
            <GlobalStyle />
            <>
                {loading && <Loader></Loader>}
                {modalState.visible && <Modal modalHandler={modalHandler}>{modalState.content}</Modal>}
                {!isMobile && <Socials></Socials>}
                {/* <FillContainer /> */}
                <NavBar viewMode={viewMode}>{navButtons}</NavBar>
                <PrevNextButtons pages={viewModes} currentPage={viewMode} setViewMode={setViewMode}></PrevNextButtons>
                {viewMode === "info" && (
                    <>
                        <StarParticles></StarParticles>
                        <StyledParallaxContainer isWebkit={isWebkitDevice}>
                            {/** Hero section */}
                            <HeroSection isVisible={isVisible} ref={containerRef} handleScroll={handleScroll} isWebkit={isWebkitDevice}>
                                <StarDiv layer="deepest" isVisible={isVisible} isWebkit={isWebkitDevice}></StarDiv>
                            </HeroSection>

                            {sectionElements}

                            <BottomSection isWebkit={isWebkitDevice}></BottomSection>

                        </StyledParallaxContainer>
                    </>
                )}
                {viewMode === "skills" && (
                    <>
                        <div className="ocean-section">
                            <SkillsSection></SkillsSection>
                        </div>
                    </>
                )}

                {viewMode === "projects" && (
                    <>
                        <ProjectsSection projects={projectData} modalHandler={modalHandler}></ProjectsSection>
                    </>
                )}
                <div className="underlay-div"></div>
            </>
        </StyledApp>
    );
}

export default App;
