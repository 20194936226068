import React from "react";
import styled from "styled-components";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BlurButton } from "./styled-components/Buttons.Styled";
import { device } from "./styled-components/devices";

const NextButtonHolder = styled.section`
    pointer-events: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: var(--z-highest);
    button {
        pointer-events: auto;
        position: absolute;
        bottom: 4vmax;
        right: 2vmax;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: medium;
        padding: unset;
        span {
            width: 0;
            overflow: hidden;
        }
        &:hover {
            span {
                width: fit-content;
            }
        }
        @media ${device.tablet} {
            span {
                width: fit-content;
            }
        }
    }
`;

function PrevNextButtons(props) {
    const buttonText = {
        skills: "View My Skills",
        info: "Read About Me",
        projects: "See My Work",
    };
    function getNextPage() {
        const index = props.pages.indexOf(props.currentPage);
        const next = props.pages[index + 1] || "";
        return next;
    }
    return (
        <NextButtonHolder>
            {getNextPage() && (
                <BlurButton className="next-button" onClick={() => props.setViewMode(getNextPage())}>
                    <span>{buttonText[getNextPage()]}</span>
                    <NavigateNextIcon></NavigateNextIcon>
                </BlurButton>
            )}
        </NextButtonHolder>
    );
}

export default PrevNextButtons;
