import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { CustomTooltip } from "./Tooltip";
import styled, { css } from "styled-components";
import { CustomIconButton } from "./styled-components/Buttons.Styled";
import { useMediaQuery } from "react-responsive";
import { device } from "./styled-components/devices";

const StyledSocialsList = styled.div`
    z-index: var(--z-top);
    font-size: 1vmax;
    position: fixed;
    left: 2vmax;
    bottom: 4vmax;
    height: fit-content;

    .wrapper {
        display: grid;
        gap: 0.75rem;

        /* grid-auto-flow: column; */
        grid-auto-columns: 2.5rem;
        grid-auto-flow: row;
        grid-auto-rows: 2.5rem;

        align-items: center;
        justify-content: center;
        transform: scaleY(1);
        transform-origin: bottom center;
        transition: transform 200ms ease-in;
    }
    button {
        aspect-ratio: 1/1;
        display: grid;
        place-content: center;
        border-radius: 50%;
        background-color: var(--color-primary-bg-400);
        width: min(4vmax, 40px);
        height: min(4vmax, 40px);
        padding: 1vmax;
        transform: rotateZ(0deg) scale(1);
        transition: transform 200ms ease-in, background-color 200ms ease-in;
        color: var(--color-secondary-text);
        &:hover {
            transform: rotateZ(0deg) scale(1.15);
        }
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        ${(props) =>
            props.active &&
            css`
                color: var(--color-primary-bg);
                background-color: var(--color-primary-text);
                transform: rotateZ(45deg) scale(1);
                transition: transform 200ms ease-out, background-color 200ms ease-out;
                &:hover {
                    transform: rotateZ(45deg) scale(1.15);
                }
            `};
    }
    a {
        width: 2.5rem;
        height: 2.5rem;
        display: grid;
        place-content: center;
        /* color: var(--glassy-lilac-light); */
        color: var(--color-primary-bg-600);
        &:visited {
            color: var(--color-primary-bg-500);
        }
        border-radius: 50%;
        aspect-ratio: 1/1;
        backdrop-filter: blur(12px);
        background-image: linear-gradient(45deg, #6660ff, hsl(276deg 67% 28%));
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        &:hover,
        &:focus,
        &:focus-within {
            outline: unset;
            border: unset;
            color: var(--color-primary-text);
        }
    }
`;

function Socials() {
    // const [active, setActive] = useState(false);
    const socials = [
        {
            tooltip: "View my Resume",
            link: "/documents/Resume-MBradford-Spring2024.pdf",
            icon: <DescriptionIcon></DescriptionIcon>,
        },
        {
            tooltip: "Email me at: Maya.Bradford94@gmail.com",
            link: "mailto:Maya.Bradford94@gmail.com",
            icon: <EmailIcon></EmailIcon>,
        },
        {
            tooltip: "View my LinkedIn Profile",
            link: "http://www.linkedin.com/in/maya-bradford-995475159",
            icon: <LinkedInIcon></LinkedInIcon>,
        },
    ];
    const socialsElements = socials.map((data, index) => (
        <CustomTooltip
            className="tooltip"
            placement="top"
            title={data.tooltip}
            key={index + "customTooltipSocialButton"}
        >
            <a href={data.link} target="_blank" rel="noopener noreferrer">
                {data.icon}
            </a>
        </CustomTooltip>
    ));
    return (
        <StyledSocialsList justifyAround={true} alignStretch={true}>
            <div className="wrapper">{socialsElements}</div>
        </StyledSocialsList>
    );
}

export default Socials;
