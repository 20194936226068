import { createGlobalStyle } from "styled-components";
import { device } from "./devices";
import GitHubIcon from "@mui/icons-material/GitHub";
import LaunchIcon from "@mui/icons-material/Launch";
import { CustomTooltip } from "../Tooltip";
import { addPseudoElement } from "./pseudo.styled";

export const GlobalStyle = createGlobalStyle`
	:root{
    --color-primary-text-dark: hsl(30, 25%, 43%);
    --color-primary-text: hsl(30, 35%, 63%);
    --color-primary-text-alpha-1: hsla(30, 35%, 63%, 0.1);
    --color-primary-text-alpha-2: hsla(30, 35%, 63%, 0.2);
    --color-primary-text-alpha-3: hsla(30, 35%, 63%, 0.3);
    --color-primary-text-alpha-5: hsla(30, 35%, 63%, 0.5);
    --color-primary-text-alpha-7: hsla(30, 35%, 63%, 0.7);
    --color-primary-text-light: hsl(30deg 68% 88%);
    --color-secondary-text: hsl(235, 49%, 77%);
    --color-secondary-text-light: hsl(233, 100%, 90%);
    ${"" /* --color-primary-bg-700: #010614; */}
    --color-primary-bg: hsl(224, 90%, 4%);
    --color-primary-bg-700: hsl(251, 90%, 8%);
    --color-primary-bg-700-alpha-7: hsla(251, 90%, 8%, 0.7);
    --color-primary-bg-700-alpha: hsla(251, 90%, 8%, 0.5);
    --color-primary-bg-600: hsl(254, 90%, 14%);
    --color-primary-bg-500: hsl(253, 90%, 24%);
    --color-primary-bg-400: hsl(254, 60%, 34%);
    --color-primary-bg-300: hsl(254, 50%, 44%);
    --color-secondary-bg: hsl(224, 90%, 4%);
    --color-glassy-purple-opaque: #4e2b5a;
    --color-glassy-purple: hsla(275, 100%, 25%, 0.518);
--color-glassy-purple-border: #6200a339;
--color-space-gradient: #4e2b5a, #2D2B5A;
    --color-glassy-teal: hsla(202, 100%, 55%, 0.29);
    --color-glassy-teal-opaque: hsla(202, 100%, 45%, 1);
--color-glassy-teal-border: hsla(202, 100%, 52%, 0.224);
--scrollbar-color: #0c0e1f;


		--clr-dark-bg: hsla(233, 30%, 12%, 100%);
		--clr-blue-violet: hsl(239, 84%, 67%);
		--clr-blue-violet-dark: hsl(239, 54%, 27%);
		--clr-lilac: hsl(266, 80%, 69%);
		--clr-lilac-lightest: hsl(266, 80%, 89%);
		--clr-light-lilac: #cf8bf3;
		--clr-pink: hsl(338, 98%, 59%);
		--clr-peach: #f7797d;
		--clr-accent-1: var(--clr-blue-violet);
  --clr-primary-deep-dark: hsl(220, 19%, 10%);
  --clr-primary-dark: hsl(222, 13%, 15%);
  --clr-primary-dark-hsl: 222deg, 13%, 15%;
  --clr-primary-dark-translucent: hsla(222, 13%, 15%, 0.5);
  --clr-primary-base: hsl(216, 14%, 21%);
  --clr-primary-base-hsl: 216deg, 14%, 21%;
  --clr-primary-base-translucent: hsla(216, 14%, 21%, 0.85);
  --clr-primary-light: hsl(216, 14%, 34%);
 --clr-primary-lighter: hsl(216, 22%, 60%);
 --clr-primary-lightest: hsl(216, 40%, 73%);
${"" /* --clr-accent: hsl(197, 84%, 57%); */}
--clr-accent-hsl: 201, 88%, 53%;
--clr-accent: hsl(201, 88%, 53%);
--clr-accent-lighter: hsl(197, 94%, 67%);
--clr-accent-violet: #5e52d7;
--clr-accent-indigo: #417AE3;

--clr-accent-dark-accent: #568fc3;
--clr-accent-dark-blue: #5488c7;
--clr-accent-dark-indigo: #2b3d9a;

--clr-accent-pink: #F24998;
--clr-accent-red: #F14B69;
--clr-accent-orange: #fc7442;
  --clr-accent-green:#4ab4c7;
  --clr-accent-magenta: #8E37D7;
--clr-accent-cornflower: #6B8DD6;
--clr-accent-turquoise: #2CD8D5;

  --clr-shadow: rgba(0, 0, 0, 0.25);

  --gradient-color: var(--clr-accent-turquoise), var(--clr-accent-cornflower), var(--clr-accent-magenta);
  ${
      "" /* --gradient-color: var(--clr-accent), var(--clr-accent-indigo), var(--clr-accent-violet), var(--clr-accent-pink), var(--clr-accent-red), var(--clr-accent-orange); */
  }
--clr-gradient-cool: var(--clr-accent-green), var(--clr-accent), var(--clr-accent-indigo), var(--clr-accent-violet);//, var(--clr-accent-pink);

--clr-gradient-warm: var(--clr-accent-pink), var(--clr-accent-red), var(--clr-accent-orange);

--clr-gradient-dark: var(--clr-accent-dark-accent), var(--clr-accent-dark-blue), var(--clr-accent-dark-indigo);

 --box-shadow-offset-x: 0px;
  --box-shadow-offset-y: 10px;
  --box-shadow-blur: 18px;
  --box-shadow-spread: 0;
  --box-shadow-color: #161522a1;
--default-shadow: var(--box-shadow-offset-x) var(--box-shadow-offset-y) var(--box-shadow-blur) var(--box-shadow-spread) var(--box-shadow-color);

--glassy-lilac-alpha-0: hsla(240, 45%, 13%, 0.106);
--glassy-magenta-alpha-2: hsla(240, 100%, 72%, 0.206);
--glassy-magenta-alpha-5: hsla(240, 100%, 72%, 0.506);
--glassy-lilac-alpha-2: hsla(240, 100%, 72%, 0.206);
--glassy-lilac-alpha-5: hsla(240, 100%, 72%, 0.506);
--glassy-lilac-bright: hsla(240, 100%, 72%, 1);
--glassy-lilac-vivid: hsla(240, 100%, 68%);
--glassy-lilac-light: hsla(240, 100%, 78%);
--glassy-lilac-lighter: hsla(240, 100%, 88%);

--z-lowest: 0;
--z-lower: 50;
--z-low: 100;
--z-mid: 300;
--z-high: 600;
--z-higher: 800;
--z-highest: 1000;
--z-top: 1200;



	}

    html{
        scroll-behavior: smooth;
    }

    body{
      h1, h2, h3, h4{
	        margin: unset;
			padding: unset;
        }
    }
    #root{
        overflow-y: hidden;
    }
    em{
                    color: #d4a8dc;
                    font-weight: 500;
                    background: linear-gradient(45deg, #d4a8dc, #b29dff);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
    }
	html, body, #root, .App{

		height: 100%;
        ${"" /* overflow-y: auto; */}
		overflow-x: hidden;
		*, *::before, *::after{
			box-sizing: border-box;
		}
	}
    html, body{
        height: 100%;
        overflow: hidden;
    }

	html, body, *{
		scrollbar-width: thin !important;
        scrollbar-color: var(--scrollbar-color);
		 &::-webkit-scrollbar {
            background-color: linear-gradient(45deg, var(--scrollbar-color), var(--color-primary-bg));
            width: 0.25rem;
		    border: none;
        }
    &::-webkit-scrollbar-thumb {
        background: linear-gradient(45deg, cornflowerblue, cornflowerblue);
        border-radius: 999px;
        width: 0.25rem;
        ${"" /* border: 5px solid var(--color-primary-bg); */}
        background-clip: padding-box;
        cursor: pointer;
    }
    &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece, &::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-color);
    }
	&::-webkit-scrollbar-button{
            background-color: var(--scrollbar-color);
	}
	&::-webkit-scrollbar-corner{
            background-color: var(--scrollbar-color);
	}

	}
	body{
		${"" /* overflow: auto; */}
		width: 100%;
		height: 100%;
		background-color: var(--color-primary-bg);
		background-repeat: repeat-y;




    .underlay-div{
      position: fixed;
        pointer-events: none;
        content: "";
        width: 100vw;
        height: 100vh;
        z-index: -10;
        background-image: url("background-textures/wavy-dust-and-star-galaxy.webp");
            background-size: cover;
        @media ${device.laptop}{
            background-size: 100%;
        }
        background-repeat: repeat;
        background-position: top;
        background-attachment: fixed;
        opacity: 50%;
        top: 0;
        left: 0;

    }

	}

	#root{
		height: 100%;


	}
	.App{
    --landing-section-height: max(90vh, 650px);
    --landing-section-height-alt: max(110vh, 850px);
    @media (max-height: 500px) and (max-width: 600px){
        --landing-section-height-alt: max(250vh, 1050px);
    }

    @media ${device.tablet}{
    --landing-section-height: 100vh;
    }

    main{
        position: relative;
      ${
          "" /* &:before{
      top: 0;
      left: 0;
    position: absolute;
    content: "";
    width: 100%;
    height: 100vh;
       background-color: hsla(275, 100%, 25%, 0.518);
        mask-image: url("/Masks/Mask.svg");
        mask-size: 100%;
        mask-repeat: no-repeat;
    } */
      }
    .content-section{
    @media ${device.laptop}{
        padding-inline: 10%;
        ${"" /* max-width: 80%; */}
        ${"" /* margin: auto; */}
    }
    @media ${device.laptopL}{
        padding-inline: 20%;
    }
    }

    &:after{
    position: absolute;
    content: "";
        /* top: 50%; */
        /* left: 50%; */
        background-image: url("/illustrations/clound sky night bg-01-ai.svg");
        ${"" /* background-image: url("/illustrations/CloudsEdited-v2.png"); */}
        /* transform: translate(-50%, -50%) rotateZ(45deg); */
        /* background-color: hsla(238, 100%, 25%, 0.095); */
        background-color: transparent;
        /* backdrop-filter: blur(2px); */
        width: 100%;
        height: auto;
        bottom: 100%;
        left: 0;
        background-size: 100%;
        ${"" /* background-size: contain; */}
        @media ${device.desktop}{

        background-size: cover;
        }
        background-position: center bottom;
        background-repeat: no-repeat;
        /* width: 50vw; */
        /* height: 50vw; */
        z-index: 10;
        /* border: 2px dotted var(--color-primary-text); */
    }

        }
        .overlay-div{
            ${"" /* position: absolute; */}
            height: 100vh;
            width: 100vw;
            z-index: 200;
            position:fixed;
            pointer-events: none;
            top: 0;
            .circle{
                position: absolute;
                &-L{
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                    background-color: hsla(240, 41%, 24%, 0.388);
                    backdrop-filter: blur(6px);
}

            }
        }
		position: relative;
		height: 100%;
		margin: 0;
		padding: 0;

        h1, h2{
            color: var(--color-primary-text);
            font-family: "Cinzel Decorative", cursive;
        }
		h1, h2, h3, h4{
			font-weight: 500;

		}
        h2, h3, h4{
            font-family: "Montserrat", sans-serif;
        }


        .gradient-text{
            --gradient: #25d7ff, #517fff, #4f62f6, #9a71ed; //, #9a71ed, #8f2dff, #4f62f6;
            --gradient: #25d7ff, #517fff, #4f62f6, #af92e8; //, #9a71ed, #8f2dff, #4f62f6;
            background-size: contain !important;
            background-image:
                url("/background-textures/sparkling-swirl-blur.webp"),
                linear-gradient(-45deg, var(--gradient));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .img-text{
            --gradient: #25d7ff, #4b90ff, #4f62f6, #9a71ed, #8f2dff, #4f62f6;
            background-image:
                url("/background-textures/Starfield1.webp"),url("/background-textures/StarTexturewebp.webp"), linear-gradient(-45deg, var(--gradient));
            background-position: center, center, center;
            background-size:
                cover, 100% auto, 100% 50%;
            background-repeat: repeat, repeat, repeat;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

        }
        .title{
            color: var(--color-secondary-text);
            font-family: "Cinzel Decorative", cursive;
            &.heavy{
                font-weight: 900;
            }
            &.gradient{
                background-size: contain !important;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background: linear-gradient(45deg,#5aeeff,#9a62c2);
            }

        }
        .subtitle{
            font-family: "Montserrat", sans-serif;
            font-weight: 100;
            text-shadow: 0px 0px 3px var(--color-primary-text);
        }
		h3, h4{
            font-family: "Montserrat", sans-serif;
            font-weight: 100;
            ${"" /* font-family: "PoiretOne-Regular", cursive; */}
			${"" /* font-family: "Megrim", cursive; */}
		}

		p, button > span, li, li>span{

            font-family: "Raleway", sans-serif;
			font-weight: 400;
		}
        p{
            font-size: 1.15rem;
            line-height: 1.8;
        }
		li{
            font-family: "Raleway", sans-serif;

			font-weight: 200;
			> span{
				font-weight: 200;
			}
		}




	}
	a{
		display: inline-flex;
		position: relative;
		text-decoration: none;
		transition: color .25s ease-in;
		&::before{
			position: absolute;
			width: 100%;
			height: 100%;
			border-bottom: 2px solid inherit;
		}
		&:hover, &:focus{
			border-bottom: 2px solid currentColor;
			color: #A770EF;
		}
		&:active, &:visited{
			color: #A770EF;
		}
	}


`;
