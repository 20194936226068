import React from "react";
import PreviewStrip from "./PreviewStrip";
import styled, { css, keyframes } from "styled-components";
import { device } from "./styled-components/devices";
import { addPseudoElement } from "./styled-components/pseudo.styled";
import ProjectInfoDisplay from "./ProjectInfoDisplay";
const StyledOverlay = styled.section`
    display: grid;
    align-items: end;
    justify-items: center;
    grid-row: 1/3;
    grid-column: 1;
    grid-template-rows: 10% 70% 10% 10%;
    grid-template-columns: 100%;
    pointer-events: none;
    position: relative;
    background-color: transparent;
    background-size: 100%;
    width: 100%;
    height: inherit;
    position: relative;

    /* z-index: 900; */
    /* h1 {
        ${addPseudoElement("none", { centered: true }, { top: "70%", width: "fit-content", height: "fit-content" })};
        z-index: var(--z-high);
        font-size: 2rem;
        backdrop-filter: blur(4px);
        padding: 1rem 3rem;
        background-image: linear-gradient(to right, transparent, var(--color-primary-bg-700-alpha), transparent);
        width: 100%;
    } */
    &:before {
        position: absolute;
        left: 0%;
        top: 5%;
        width: 100%;
        height: 50%;
        z-index: var(--z-lower);
        content: "";
        background-image: url(/frames/FrameDeco-Top.webp);
        opacity: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
        background-position: center;
    }
    &:after {
        position: absolute;
        left: 0%;
        top: 45%;
        /* transform: translate(-50%, -50%); */
        /* top: 0; */
        /* left: 0; */
        width: 100%;
        height: 50%;
        z-index: var(--z-low);
        content: "";
        background-image: url(/frames/FrameDeco-Bottom.webp);
        opacity: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
        background-position: center;
        /* @media ${device.mobileL} {
            background-size: auto 100%;
        } */
    }
    @media ${device.tablet} {
        grid-column: 1;
    }
    /* z-index: 500; */
    /* background-image: url(/MyMind.webp); */

    > img {
        @media ${device.tablet} {
            mask-image: linear-gradient(
                to right,
                transparent 5%,
                #0000006e 6%,
                black 10%,
                black 90%,
                #00000074 95%,
                transparent 96%
            );
        }
        pointer-events: none;
        grid-column: 1/2;
        grid-row: 2/4;
        align-self: center;
        /* justify-self: start; */

        background-color: transparent;
        max-width: 100%;
        /* @media (orientation: landscape) and (${device.mobileL}) {
        }
        @media (orientation: landscape) and (${device.laptop}) {
        } */
        @media ${device.tablet} {
            grid-column: 1/2;
            grid-row: 2/4;
        }
        max-height: 100%;
        object-fit: contain;
        z-index: 2;
        &:nth-of-type(2) {
            z-index: 999;
            opacity: 0%;
            transition: opacity 200ms ease-in-out;
            &.visible {
                transition: opacity 200ms ease-in-out;
                opacity: 100%;
            }
        }
    }
`;

function PapercraftOverlay(props) {
    return (
        <StyledOverlay>
            <ProjectInfoDisplay
                project={props.currentProject}
                active={props.active}
                setActive={props.setActive}
                galleryData={props.galleryData}
                setGalleryData={props.setGalleryData}
            ></ProjectInfoDisplay>
            <img className="dreams" src="/MyMind.webp" alt="illustration of woman paper cutout with clouds thoughts" />
            <PreviewStrip
                active={props.active}
                currentProject={props.currentProject}
                filters={props.filters}
            ></PreviewStrip>
        </StyledOverlay>
    );
}

export default PapercraftOverlay;
