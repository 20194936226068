function randomBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
export const helpers = {
    fileNameToAltText: (string, delimiter = " ", replaceWith = "") => {
        let sentenceArray;
        let capitalizedString;

        if (!delimiter) {
            // if the delimiter is an empty string, split it by capital letters, as if camelCase
            sentenceArray = string.split(/(?=[A-Z])/).map((s) => s.toLowerCase());
            capitalizedString = sentenceArray.map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
        } else {
            sentenceArray = string.split(delimiter);

            capitalizedString = sentenceArray
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(replaceWith ? replaceWith : delimiter);
        }
        capitalizedString = capitalizedString.replace(/\.[0-9a-z]+$/i, "");
        return capitalizedString;
    },
    halfArray: (list) => {
        const half = Math.ceil(list.length / 2);
        const firstHalf = list.slice(0, half)
        const secondHalf = list.slice(half)
        return { firstHalf, secondHalf }
    },

    generateFillerText: () => {
        return `Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, dolorum sequi magni eligendi reiciendis hic alias illo ipsam recusandae ex commodi porro provident ipsum incidunt facere excepturi voluptatum, rem aliquid.,`;
    },
    randomBetween: (min, max) => randomBetween(min, max),
    randomFromArray: (array, shouldPop = false) => {
        let random;
        if (!shouldPop) {
            random = array[randomBetween(0, array.length - 1)];
        } else {
            random = array.splice(random, 1);
        }
        return random;
    },
    shuffleArray: (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    objectToString: (object) => {
        let string = "";
        for (let key in object) {
            const value = object[key];
            string += `${key.toString()}: ${value};\n`;
        }
        return string;
    },
    cssFunctionWrap: (functionName, value, unit = " ") => {
        return `${functionName}(${value}${unit})`;
    },
    addSuffixToFile: (suffix, fileName) => {
        const splitNameArray = fileName.split(".").splice(1, suffix)
        const newFileName = splitNameArray.join("")
        return newFileName



    }
};
