import React, { useState } from "react";
import { CustomPopover } from "./Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpIcon from "@mui/icons-material/Help";
import styled from "styled-components";
import { device } from "./styled-components/devices";

const StyledHelpButton = styled.div`
    position: fixed;
    pointer-events: auto;
    z-index: var(--z-higher);
    top: 2vmax;
    right: 2vmax;
    .help-button {
        cursor: pointer;
        grid-row: 1/2;
        grid-column: 3/4;
        align-self: start;
        justify-self: start;
        z-index: var(--z-high);
        background-color: var(--color-primary-bg-700-alpha);
        color: var(--glassy-lilac-light);
        &:hover {
            color: var(--color-primary-text);
        }

        border: 1px solid;
        border-radius: 50%;
        border-color: var(--color-primary-text);
        aspect-ratio: 1/1;
        display: grid;
        place-content: center;
    }
`;
function HelpButton(props) {
    const [helpElement, setHelpElement] = useState(false);
    function showHelp(event) {
        setHelpElement(event.currentTarget);
        props.setShowHelp(true);
    }

    function hideHelp() {
        setHelpElement(false);
        props.setShowHelp(false);
    }
    const open = helpElement;
    return (
        <StyledHelpButton>
            {!props.active && (
                <>
                    <button onClick={(event) => showHelp(event)} aria-describedby="help-button" className="help-button">
                        <HelpIcon></HelpIcon>
                    </button>
                    <CustomPopover
                        className="help-popover"
                        id="help-button"
                        open={open}
                        anchorEl={helpElement}
                        onClose={hideHelp}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <span>{props.text}</span>
                    </CustomPopover>
                </>
            )}
        </StyledHelpButton>
    );
}

export default HelpButton;
